import './style/StyledDropzone.css'
import { TextH3 } from '../Text'
import { BoxFlex } from '../boxes'
import { useDropzone } from 'react-dropzone'
import React, { useMemo, useState, useEffect, useRef } from 'react'
import FlexContainer from './style/FlexContainer'
import { GlobalButton } from '../buttons'
import { ErrorMessage } from 'formik'
import TextError from './TextError'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#0526918f',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function DropzoneInput(props) {
  const {
    uploadFiles,
    name,
    label,
    small,
    type,
    initial = null,
    validate,
  } = props

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: type ? type : '' })
  useEffect(() => {
    function clearAll() {
      setfiles([])
      uploadFiles((a) => ({
        ...a,
        [name]: null,
      }))
    }
    setfiles(
      acceptedFiles.map((file, i) => {
        const f = file.path.split('.')
        return (
          <div key={i} className="StyledDropzone__file">
            <div className="">{f[f.length - 1]}</div>
            <h4>{file.path}</h4>
            <i className="fas fa-times" onClick={() => clearAll()}></i>
            {/* <h5>{Math.round(file.size / 1024)}kb</h5> */}
          </div>
        )
      }),
    )
    if (acceptedFiles.length > 0) {
      uploadFiles((a) => ({
        ...a,
        [name]: acceptedFiles[0],
      }))
    }
  }, [acceptedFiles, uploadFiles, name])
  const [files, setfiles] = useState([])

  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  useEffect(() => {
    if (initial !== null) {
      setfiles(initial.length > 0 ? [initial] : [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [showDragDropMenu, setShowDragDropMenu] = useState(
    validate === null ? false : true,
  )

  const prevState = usePrevious(files)

  // const files = acceptedFiles.map((file, i) => {
  //   const f = file.path.split(".");
  //   return (
  //     <div key={i} className="StyledDropzone__file">
  //       <div className="">{f[f.length - 1]}</div>
  //       <h4>{file.path}</h4>
  //       <i className="fas fa-times" onClick={() => clearAll()}></i>
  //       {/* <h5>{Math.round(file.size / 1024)}kb</h5> */}
  //     </div>
  //   );
  // });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )
  //arrowfunction
  const openDragAndDropArea = () => {
    /* setfiles([]); */
    setShowDragDropMenu(true)
  }
  const restorePreviusState = () => {
    setfiles(prevState)
    setShowDragDropMenu(false)
  }
  return (
    <>
      <FlexContainer small={small} className="StyledDropzone">
        <BoxFlex justifyContent="space-between">
          <label className="StyledDropzone__label">{label}</label>
          {initial && (
            <>
              <TextH3
                fontSize="1.6rem"
                fontWeight="400"
                color="var(--blue)"
                margin="0"
              >
                <a href={initial} target="_blank" rel="noopener noreferrer">
                  Descargar archivo
                </a>
              </TextH3>
            </>
          )}
        </BoxFlex>

        {showDragDropMenu ? (
          <>
            <div
              className="StyledDropzone__square"
              {...getRootProps({ style })}
            >
              <input {...getInputProps()} />
              <i className="fas fa-cloud-upload-alt"></i>
              <h4 className="StyledDropzone__h3--gray">
                Arrastra y suelta tus archivos aquí
              </h4>
              <h4 className="StyledDropzone__h3--orange">
                o presiona para buscar
              </h4>
            </div>
            <center>
              <GlobalButton
                width="min-content"
                background="rgb(226, 228, 243)"
                border="none"
                onClick={restorePreviusState}
              >
                Ocultar
              </GlobalButton>
            </center>
            <div className="StyledDropzone__fields">
              {files.length !== 0 ? (
                <div className="StyledDropzone__container">
                  <h3 className="StyledDropzone__Fh3">Listos para enviar</h3>
                  {files}
                </div>
              ) : (
                <h4 className="StyledDropzone__Fh4">No hay documentos</h4>
              )}
            </div>
          </>
        ) : (
          <center>
            <GlobalButton
              width="fit-content"
              background="rgb(226, 228, 243)"
              border="none"
              onClick={openDragAndDropArea}
            >
              Subir archivo
            </GlobalButton>
          </center>
        )}
        <ErrorMessage component={TextError} name={name} />
      </FlexContainer>
    </>
  )
}

export default DropzoneInput

import { TextH3 } from '../Text'
import { BoxFlex } from '../boxes'
import { validateArray } from '../../utils/Validate'
import './GraphicsContainer.css'
import Doughnut from './Doughnut'
import Bar from './Bar'
import BarColumn from './BarColumn'
import BarList from './BarList'

import { ToolTip } from '../boxes'
const GraphicsContainer = (props) => {
  const { type, title, data, size = '', onClick = () => {}, params } = props
  const chartContainer = {
    pie: Doughnut,
    doughnut: Doughnut,
    bar: Bar,
    barColumn: BarColumn,
    barList: BarList,
  }
  const RenderChart = chartContainer[type] || Doughnut
  return (
    <div className="GraphicsContainer">
      {title && (
        <TextH3 color="var(--gray)" textAlign="center" margin="10px 0 0">
          {title}
        </TextH3>
      )}
      {type === 'doughnut' || type === 'barList' ? (
        <BoxFlex flexWrap="wrap" gap="10px">
          {validateArray(data) &&
            data.map((item, index) => {
              if (size === 'small' && index < 3) {
                return (
                  <ToolTip
                    key={index}
                    content={
                      <>
                        <TextH3
                          margin="0"
                          minWidth="fit-content"
                          color="var(--gray)"
                        >
                          {item.label}
                        </TextH3>
                        <TextH3
                          margin="0"
                          minWidth="fit-content"
                          color="var(--gray)"
                        >
                          Cantidad: <strong>{item.data}</strong>
                        </TextH3>
                        <TextH3
                          margin="0"
                          minWidth="fit-content"
                          color="var(--gray)"
                        >
                          Porcentaje: <strong>{`${item.percentage}%`}</strong>
                        </TextH3>
                      </>
                    }
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => onClick(item, params)}
                    >
                      <BoxFlex>
                        <div
                          style={{
                            background: item.color,
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                          }}
                        ></div>
                        <TextH3
                          color="var(--gray)"
                          margin="0"
                          fontSize="1.4rem"
                        >
                          {item.label}
                        </TextH3>
                      </BoxFlex>
                      {size !== 'small' && (
                        <>
                          <TextH3
                            margin="0 0 0 20px"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Cantidad: <strong>{item.data}</strong>
                          </TextH3>
                          <TextH3
                            margin="0 0 0 20px"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Porcentaje: <strong>{`${item.percentage}%`}</strong>
                          </TextH3>
                        </>
                      )}
                    </div>
                  </ToolTip>
                )
              } else {
                return null
              }
            })}
        </BoxFlex>
      ) : null}
      <br />
      <RenderChart {...props} />
      <br />
    </div>
  )
}

export default GraphicsContainer

import ListUser from '../../containers/notifications/ListUser'
import { connect } from 'react-redux'
import { changeModal } from '../../store/actions'
import { useAuthAxios } from '../../hook'
import { TextH2, TextH3 } from '../../components/Text'
import { validateArray } from '../../utils/Validate'
import { convertDate } from '../../utils/ConvertDate'
import { Loading } from '../../components/animation'

const NotificationsSent = (props) => {
  const { changeModal } = props
  function handleClick(user) {
    changeModal(
      true,
      <NotificationUser {...props} user={user} />,
      true,
      '500px',
    )
  }
  return (
    <div>
      <ListUser {...props} handleClick={handleClick} rolStatus />
    </div>
  )
}
const NotificationUser = ({ user }) => {
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: `/notification/type?type=Notification&userId=${user.id}`,
  })
  return (
    <div className="">
      <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
        Notificaciones enviadas a {user.name}
      </TextH2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((item) => (
          <div
            key={item.id}
            style={{
              border: '1px solid gray',
              padding: '5px 10px 10px',
              marginBottom: '10px',
              borderRadius: '10px',
            }}
          >
            <TextH3 textAlign="end" color="var(--gray)" margin="0">
              Enviado el {convertDate(item.creationDate, 'lll')}
            </TextH3>
            <TextH3
              color={item.isView ? 'var(--green)' : 'var(--red)'}
              margin="0"
            >
              {item.isView
                ? 'Visto el ' + convertDate(item.updateDate, 'lll')
                : 'No visto'}
            </TextH3>
            <TextH3 margin="5px 0 0" color="var(--gray)">
              {item.tittle}
            </TextH3>
            <TextH3 margin="0" color="var(--gray)">
              {item.content}
            </TextH3>
          </div>
        ))
      ) : (
        <TextH3 color="var(--gray)">
          Aun no se enviaron notificaciones a {user.name}
        </TextH3>
      )}
    </div>
  )
}
export default connect(null, { changeModal })(NotificationsSent)

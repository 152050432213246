import { useState } from 'react'
import { validateStatus } from '../../utils/Validate'
import { postAction } from '../../services/actions/AuthAction'
import { useAuthAxios } from '../../hook'
import { TableDefault, TableFilters } from '../../components/table'
import { headerAddClient } from '../../utils/TableFields'
import { RenderModal } from '../../components/modal'
import { TextH2 } from '../../components/Text'
import { Loading } from '../../components/animation'
import { ActionButton } from '../../components/buttons'

const AddUserCompany = (props) => {
  const [load, setload] = useState(false)
  const { handleModal, reload, company, openSnackbar } = props
  const { response } = useAuthAxios({
    method: 'GET',
    url: '/rol/client',
  })

  async function onSubmit(values) {
    setload(true)
    try {
      await postAction(
        `/company/${company.id}/userId/${values.client}`,
        {},
      ).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          reload()
          handleModal()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  const onClick = (user) => {
    setAddUserState({
      ...addUserState,
      openModal: true,
      currentUser: user,
    })
  }
  const [addUserState, setAddUserState] = useState({
    openModal: false,
    component: (
      <TableDefault
        header={headerAddClient}
        main={response && response}
        functionClick={onClick}
      />
    ),
  })

  const RenderModalAddUser = (user) => {
    return (
      <RenderModal width="400px">
        <TextH2
          fontSize="2rem"
          fontWeight="bold"
          textAlign="center"
          color="var(--blue)"
        >
          ¿Adicionar usuario?
        </TextH2>
        <TextH2>
          {addUserState.currentUser.name}{' '}
          {addUserState.currentUser.fatherLastName}
        </TextH2>
        {load ? (
          <Loading />
        ) : (
          <ActionButton
            textBtn="Adicionar"
            onClick={() =>
              onSubmit({
                ...addUserState.currentUser,
                client: addUserState.currentUser.id,
              })
            }
            onCancel={() =>
              setAddUserState({ ...addUserState, openModal: false })
            }
          />
        )}
      </RenderModal>
    )
  }
  return (
    <>
      <TableFilters
        params="/rol/Users"
        addUrl="RolName=client"
        functionClick={onClick}
        header={headerAddClient}
      />
      {addUserState.openModal && RenderModalAddUser(addUserState.component)}
    </>
  )
}

export default AddUserCompany

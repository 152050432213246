import { TextH2, SubTitle } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { TableFilters } from '../../components/table'
import { RenderModal } from '../../components/modal'
import Loading from '../../components/animation/Loading'
import { useState } from 'react'
import { ActionButton, GlobalButton } from '../../components/buttons'
import { validateStatus } from '../../utils/Validate'
import { postAction } from '../../services/actions/AuthAction'
import { headerField } from '../../utils/TableFields'
const FieldAddForm = (props) => {
  const [field, setfield] = useState(null)
  const { onClose, openSnackbar, step } = props

  function handleUser(values, reload) {
    setfield({ values, reload })
  }
  return (
    <>
      <BoxFlex justifyContent="space-between">
        <TextH2 fontSize="2.4rem" textAlign="center" color="var(--blue)">
          Adicionar un campo existente
        </TextH2>
        <GlobalButton
          width="auto"
          background="#e2e4f37f"
          onClick={onClose}
          type="button"
        >
          Cancelar
        </GlobalButton>
      </BoxFlex>
      <TableFilters
        params="/field"
        functionClick={handleUser}
        header={headerField}
      />
      {field && (
        <RenderField
          step={step}
          field={field}
          onClose={onClose}
          setfield={setfield}
          openSnackbar={openSnackbar}
        />
      )}
    </>
  )
}
const RenderField = (props) => {
  const { field, setfield, step, onClose, openSnackbar } = props
  const [load, setload] = useState(false)
  async function onSubmit() {
    setload(true)
    try {
      await postAction(
        `/field/fieldId/${field.values.id}/processStepId/${step.id}`,
        {},
      ).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          onClose()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }
  return (
    <RenderModal width="400px" onClose={() => setfield(null)} closeOutside>
      <TextH2 fontSize="2rem" textAlign="center" color="var(--blue)">
        Adicionar {field.values.label} a {step.name}
      </TextH2>
      <SubTitle subTitle="Etiqueta">{field.values.label}</SubTitle>
      <SubTitle subTitle="Nombre">{field.values.name}</SubTitle>
      <SubTitle subTitle="Tipo">{field.values.type}</SubTitle>
      {load ? (
        <Loading />
      ) : (
        <center>
          <ActionButton
            type="button"
            onClick={onSubmit}
            textBtn="Adicionar"
            onCancel={() => setfield(null)}
          />
        </center>
      )}
    </RenderModal>
  )
}
export default FieldAddForm

import './assets/style/globalStyle.css'
import { Theme } from './components/theme'
import { Template } from './containers/layout'
import { useAuthAxios } from './hook'
import { validateStatus } from './utils/Validate'
import { getAction } from './services/actions/Action'
import { BrowserRouter } from 'react-router-dom'
import { startSession, closeSession } from './store/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { tokenIpinfo } from './config.json'

const App = ({ startSession, closeSession }) => {
  const uid = window.localStorage.getItem('id')
  const { response, loading, error } = useAuthAxios({
    method: 'GET',
    url: uid ? `/users/User` : '',
  })

  useEffect(() => {
    if (validateStatus(error)) {
      const { rolsUserToReturn, userToReturn, rating, companys } = response
      getAction('https://api.ipify.org')
        .then((response) => {
          startSession(
            userToReturn,
            rolsUserToReturn,
            [],
            true,
            rating,
            companys,
            validateStatus(response.status) && response.data
              ? response.data
              : null,
          )
        })
        .catch(
          startSession(
            userToReturn,
            rolsUserToReturn,
            [],
            true,
            rating,
            companys,
            null,
          ),
        )
    }
  }, [response, error, startSession])

  useEffect(() => {
    if (!validateStatus(error) && !loading) {
      closeSession()
      localStorage.removeItem('token_seguridad')
      localStorage.removeItem('id')
    }
  }, [error, closeSession, loading])

  if (loading) {
    return null
  } else {
    return (
      <Theme>
        <BrowserRouter>
          <Template />
        </BrowserRouter>
      </Theme>
    )
  }
}

export default connect(null, { startSession, closeSession })(App)

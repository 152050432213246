export const TypeInput = {
  checkbox: 'Casilla de verificación',
  file: 'Campo de archivo',
  textarea: 'Caja de texto multi-línea',
  getButton: 'Botón con lista desplegable',
  selectGet: 'Lista desplegable',
  text: 'Campo de texto',
  date: 'Campo de fecha',
  number: 'Casilla de numero',
  switch: 'Botón de encendido/apagado',
  // checkboxDescription: 'Casilla de verificación con descripción',
  descriptionCheck: 'Casilla de verificación con descripción',
}

export const rolConstant = {
  accounting: 'Contador',
  admin: 'Administrador',
  billing: 'Facturación',
  client: 'Cliente',
  completeDocuments: 'Completar documentos',
  contravention_stopped: 'Detenidos y contravención',
  customs_manager: 'Gestor de aduana',
  'download-history': 'Historial de descarga',
  files: 'Archivo',
  folder_loan: 'Préstamo de carpetas',
  liquidator: 'Liquidador',
  management: 'Gerencia',
  notifications: 'Notificaciones',
  quality: 'RRHH',
  reception: 'Recepción',
  revision_procedure: 'Revisión',
  senasag_procedure: 'Senasag',
  Systems: 'Sistemas',
}
export const urlAvatar =
  'https://res.cloudinary.com/sistemavilaseca/image/upload/q_auto:eco/v1647284147/Assets/avatar_coipgt.png'

export const procedureReadDex = [
  {
    ProcedureId: 356,
  },
  {
    ProcedureId: 385,
  },
  {
    ProcedureId: 386,
  },
  {
    ProcedureId: 548,
  },
  {
    ProcedureId: 611,
  },
  {
    ProcedureId: 679,
  },
  {
    ProcedureId: 747,
  },
  {
    ProcedureId: 749,
  },
  {
    ProcedureId: 750,
  },
  {
    ProcedureId: 770,
  },
  {
    ProcedureId: 776,
  },
  {
    ProcedureId: 789,
  },
  {
    ProcedureId: 798,
  },
  {
    ProcedureId: 799,
  },
  {
    ProcedureId: 850,
  },
  {
    ProcedureId: 859,
  },
  {
    ProcedureId: 860,
  },
  {
    ProcedureId: 935,
  },
  {
    ProcedureId: 936,
  },
  {
    ProcedureId: 953,
  },
  {
    ProcedureId: 1043,
  },
  {
    ProcedureId: 1065,
  },
  {
    ProcedureId: 1129,
  },
  {
    ProcedureId: 1157,
  },
  {
    ProcedureId: 1265,
  },
  {
    ProcedureId: 1293,
  },
  {
    ProcedureId: 1304,
  },
  {
    ProcedureId: 1305,
  },
  {
    ProcedureId: 2472,
  },
  {
    ProcedureId: 2474,
  },
  {
    ProcedureId: 2475,
  },
  {
    ProcedureId: 2478,
  },
  {
    ProcedureId: 2481,
  },
  {
    ProcedureId: 2496,
  },
  {
    ProcedureId: 2537,
  },
  {
    ProcedureId: 2545,
  },
  {
    ProcedureId: 2585,
  },
  {
    ProcedureId: 2615,
  },
  {
    ProcedureId: 2781,
  },
  {
    ProcedureId: 2825,
  },
  {
    ProcedureId: 2833,
  },
  {
    ProcedureId: 2855,
  },
  {
    ProcedureId: 2932,
  },
  {
    ProcedureId: 2945,
  },
  {
    ProcedureId: 2946,
  },
  {
    ProcedureId: 2947,
  },
  {
    ProcedureId: 3045,
  },
  {
    ProcedureId: 3090,
  },
  {
    ProcedureId: 3092,
  },
  {
    ProcedureId: 3119,
  },
  {
    ProcedureId: 3210,
  },
  {
    ProcedureId: 3248,
  },
  {
    ProcedureId: 3253,
  },
  {
    ProcedureId: 3400,
  },
  {
    ProcedureId: 3424,
  },
  {
    ProcedureId: 3425,
  },
  {
    ProcedureId: 3528,
  },
  {
    ProcedureId: 3549,
  },
  {
    ProcedureId: 3551,
  },
  {
    ProcedureId: 3553,
  },
  {
    ProcedureId: 3555,
  },
  {
    ProcedureId: 3556,
  },
  {
    ProcedureId: 3569,
  },
  {
    ProcedureId: 3577,
  },
  {
    ProcedureId: 3587,
  },
  {
    ProcedureId: 3609,
  },
  {
    ProcedureId: 3610,
  },
  {
    ProcedureId: 3611,
  },
  {
    ProcedureId: 3612,
  },
  {
    ProcedureId: 3661,
  },
  {
    ProcedureId: 3672,
  },
  {
    ProcedureId: 3683,
  },
  {
    ProcedureId: 3716,
  },
  {
    ProcedureId: 3777,
  },
  {
    ProcedureId: 3786,
  },
  {
    ProcedureId: 3828,
  },
  {
    ProcedureId: 3930,
  },
  {
    ProcedureId: 3932,
  },
  {
    ProcedureId: 3947,
  },
  {
    ProcedureId: 3980,
  },
  {
    ProcedureId: 4054,
  },
  {
    ProcedureId: 4057,
  },
  {
    ProcedureId: 4110,
  },
  {
    ProcedureId: 4111,
  },
  {
    ProcedureId: 4122,
  },
  {
    ProcedureId: 4129,
  },
  {
    ProcedureId: 4215,
  },
]

export const procedureReadParte = [
  {
    ProcedureId: 4013,
  },
  {
    ProcedureId: 4099,
  },
  {
    ProcedureId: 326,
  },
  {
    ProcedureId: 363,
  },
  {
    ProcedureId: 658,
  },
  {
    ProcedureId: 1359,
  },
  {
    ProcedureId: 2982,
  },
  {
    ProcedureId: 3326,
  },
  {
    ProcedureId: 3412,
  },
  {
    ProcedureId: 3621,
  },
  {
    ProcedureId: 3658,
  },
  {
    ProcedureId: 3953,
  },
  {
    ProcedureId: 217,
  },
  {
    ProcedureId: 223,
  },
  {
    ProcedureId: 810,
  },
  {
    ProcedureId: 1059,
  },
  {
    ProcedureId: 2387,
  },
  {
    ProcedureId: 2725,
  },
  {
    ProcedureId: 2879,
  },
  {
    ProcedureId: 3128,
  },
  {
    ProcedureId: 3466,
  },
  {
    ProcedureId: 3472,
  },
  {
    ProcedureId: 3715,
  },
  {
    ProcedureId: 864,
  },
  {
    ProcedureId: 1153,
  },
  {
    ProcedureId: 1159,
  },
  {
    ProcedureId: 2487,
  },
  {
    ProcedureId: 3028,
  },
  {
    ProcedureId: 3366,
  },
  {
    ProcedureId: 3815,
  },
  {
    ProcedureId: 269,
  },
  {
    ProcedureId: 518,
  },
  {
    ProcedureId: 764,
  },
  {
    ProcedureId: 1351,
  },
  {
    ProcedureId: 2433,
  },
  {
    ProcedureId: 2679,
  },
  {
    ProcedureId: 2925,
  },
  {
    ProcedureId: 3174,
  },
  {
    ProcedureId: 3420,
  },
  {
    ProcedureId: 3761,
  },
  {
    ProcedureId: 4007,
  },
  {
    ProcedureId: 123,
  },
  {
    ProcedureId: 664,
  },
  {
    ProcedureId: 910,
  },
  {
    ProcedureId: 1205,
  },
  {
    ProcedureId: 2533,
  },
  {
    ProcedureId: 2779,
  },
  {
    ProcedureId: 3074,
  },
  {
    ProcedureId: 3320,
  },
  {
    ProcedureId: 3566,
  },
  {
    ProcedureId: 3861,
  },
  {
    ProcedureId: 226,
  },
  {
    ProcedureId: 297,
  },
  {
    ProcedureId: 558,
  },
  {
    ProcedureId: 724,
  },
  {
    ProcedureId: 1056,
  },
  {
    ProcedureId: 1222,
  },
  {
    ProcedureId: 4139,
  },
  {
    ProcedureId: 2716,
  },
  {
    ProcedureId: 3214,
  },
  {
    ProcedureId: 3380,
  },
  {
    ProcedureId: 3641,
  },
  {
    ProcedureId: 3712,
  },
  {
    ProcedureId: 3878,
  },
  {
    ProcedureId: 1079,
  },
  {
    ProcedureId: 2573,
  },
  {
    ProcedureId: 2622,
  },
  {
    ProcedureId: 2739,
  },
  {
    ProcedureId: 2788,
  },
  {
    ProcedureId: 3071,
  },
  {
    ProcedureId: 3120,
  },
  {
    ProcedureId: 3237,
  },
  {
    ProcedureId: 3286,
  },
  {
    ProcedureId: 3735,
  },
  {
    ProcedureId: 3784,
  },
  {
    ProcedureId: 177,
  },
  {
    ProcedureId: 509,
  },
  {
    ProcedureId: 844,
  },
  {
    ProcedureId: 2430,
  },
  {
    ProcedureId: 2504,
  },
  {
    ProcedureId: 2931,
  },
  {
    ProcedureId: 3094,
  },
  {
    ProcedureId: 3263,
  },
  {
    ProcedureId: 154,
  },
  {
    ProcedureId: 203,
  },
  {
    ProcedureId: 867,
  },
  {
    ProcedureId: 1150,
  },
  {
    ProcedureId: 1199,
  },
  {
    ProcedureId: 1248,
  },
  {
    ProcedureId: 3904,
  },
  {
    ProcedureId: 4113,
  },
  {
    ProcedureId: 4162,
  },
  {
    ProcedureId: 2453,
  },
  {
    ProcedureId: 2859,
  },
  {
    ProcedureId: 2908,
  },
  {
    ProcedureId: 3449,
  },
  {
    ProcedureId: 3523,
  },
  {
    ProcedureId: 3572,
  },
  {
    ProcedureId: 134,
  },
  {
    ProcedureId: 632,
  },
  {
    ProcedureId: 893,
  },
  {
    ProcedureId: 2547,
  },
  {
    ProcedureId: 3306,
  },
  {
    ProcedureId: 3970,
  },
  {
    ProcedureId: 2524,
  },
  {
    ProcedureId: 2690,
  },
  {
    ProcedureId: 2696,
  },
  {
    ProcedureId: 3163,
  },
  {
    ProcedureId: 3329,
  },
  {
    ProcedureId: 3827,
  },
  {
    ProcedureId: 4190,
  },
  {
    ProcedureId: 183,
  },
  {
    ProcedureId: 675,
  },
  {
    ProcedureId: 1342,
  },
  {
    ProcedureId: 2596,
  },
  {
    ProcedureId: 2765,
  },
  {
    ProcedureId: 2839,
  },
  {
    ProcedureId: 3257,
  },
  {
    ProcedureId: 3429,
  },
  {
    ProcedureId: 3921,
  },
  {
    ProcedureId: 4096,
  },
  {
    ProcedureId: 111,
  },
  {
    ProcedureId: 160,
  },
  {
    ProcedureId: 652,
  },
  {
    ProcedureId: 701,
  },
  {
    ProcedureId: 824,
  },
  {
    ProcedureId: 1193,
  },
  {
    ProcedureId: 1242,
  },
  {
    ProcedureId: 2816,
  },
  {
    ProcedureId: 3357,
  },
  {
    ProcedureId: 3406,
  },
  {
    ProcedureId: 4070,
  },
  {
    ProcedureId: 4119,
  },
  {
    ProcedureId: 303,
  },
  {
    ProcedureId: 552,
  },
  {
    ProcedureId: 2461,
  },
  {
    ProcedureId: 3137,
  },
  {
    ProcedureId: 3386,
  },
  {
    ProcedureId: 3635,
  },
  {
    ProcedureId: 446,
  },
  {
    ProcedureId: 944,
  },
  {
    ProcedureId: 1073,
  },
  {
    ProcedureId: 1122,
  },
  {
    ProcedureId: 2567,
  },
  {
    ProcedureId: 2616,
  },
  {
    ProcedureId: 2745,
  },
  {
    ProcedureId: 3243,
  },
  {
    ProcedureId: 3741,
  },
  {
    ProcedureId: 103,
  },
  {
    ProcedureId: 340,
  },
  {
    ProcedureId: 352,
  },
  {
    ProcedureId: 681,
  },
  {
    ProcedureId: 930,
  },
  {
    ProcedureId: 2759,
  },
  {
    ProcedureId: 3008,
  },
  {
    ProcedureId: 3337,
  },
  {
    ProcedureId: 3349,
  },
  {
    ProcedureId: 3586,
  },
  {
    ProcedureId: 3598,
  },
  {
    ProcedureId: 695,
  },
  {
    ProcedureId: 744,
  },
  {
    ProcedureId: 1285,
  },
  {
    ProcedureId: 1322,
  },
  {
    ProcedureId: 2367,
  },
  {
    ProcedureId: 2404,
  },
  {
    ProcedureId: 2994,
  },
  {
    ProcedureId: 3941,
  },
  {
    ProcedureId: 3990,
  },
  {
    ProcedureId: 4027,
  },
  {
    ProcedureId: 389,
  },
  {
    ProcedureId: 395,
  },
  {
    ProcedureId: 638,
  },
  {
    ProcedureId: 644,
  },
  {
    ProcedureId: 881,
  },
  {
    ProcedureId: 1130,
  },
  {
    ProcedureId: 3300,
  },
  {
    ProcedureId: 1136,
  },
  {
    ProcedureId: 2553,
  },
  {
    ProcedureId: 2802,
  },
  {
    ProcedureId: 2808,
  },
  {
    ProcedureId: 3051,
  },
  {
    ProcedureId: 3294,
  },
  {
    ProcedureId: 489,
  },
  {
    ProcedureId: 495,
  },
  {
    ProcedureId: 538,
  },
  {
    ProcedureId: 987,
  },
  {
    ProcedureId: 1030,
  },
  {
    ProcedureId: 1036,
  },
  {
    ProcedureId: 2653,
  },
  {
    ProcedureId: 2659,
  },
  {
    ProcedureId: 2702,
  },
  {
    ProcedureId: 3151,
  },
  {
    ProcedureId: 3194,
  },
  {
    ProcedureId: 3692,
  },
  {
    ProcedureId: 97,
  },
  {
    ProcedureId: 346,
  },
  {
    ProcedureId: 589,
  },
  {
    ProcedureId: 838,
  },
  {
    ProcedureId: 1179,
  },
  {
    ProcedureId: 2510,
  },
  {
    ProcedureId: 3835,
  },
  {
    ProcedureId: 4182,
  },
  {
    ProcedureId: 2753,
  },
  {
    ProcedureId: 2851,
  },
  {
    ProcedureId: 3002,
  },
  {
    ProcedureId: 3100,
  },
  {
    ProcedureId: 3343,
  },
  {
    ProcedureId: 3592,
  },
  {
    ProcedureId: 197,
  },
  {
    ProcedureId: 246,
  },
  {
    ProcedureId: 738,
  },
  {
    ProcedureId: 787,
  },
  {
    ProcedureId: 1279,
  },
  {
    ProcedureId: 1328,
  },
  {
    ProcedureId: 3984,
  },
  {
    ProcedureId: 4033,
  },
  {
    ProcedureId: 2361,
  },
  {
    ProcedureId: 2410,
  },
  {
    ProcedureId: 2902,
  },
  {
    ProcedureId: 2951,
  },
  {
    ProcedureId: 3443,
  },
  {
    ProcedureId: 3492,
  },
  {
    ProcedureId: 143,
  },
  {
    ProcedureId: 214,
  },
  {
    ProcedureId: 475,
  },
  {
    ProcedureId: 807,
  },
  {
    ProcedureId: 1139,
  },
  {
    ProcedureId: 2467,
  },
  {
    ProcedureId: 2799,
  },
  {
    ProcedureId: 3131,
  },
  {
    ProcedureId: 3463,
  },
  {
    ProcedureId: 3795,
  },
  {
    ProcedureId: 120,
  },
  {
    ProcedureId: 237,
  },
  {
    ProcedureId: 452,
  },
  {
    ProcedureId: 569,
  },
  {
    ProcedureId: 784,
  },
  {
    ProcedureId: 901,
  },
  {
    ProcedureId: 1116,
  },
  {
    ProcedureId: 2444,
  },
  {
    ProcedureId: 2776,
  },
  {
    ProcedureId: 3440,
  },
  {
    ProcedureId: 3772,
  },
  {
    ProcedureId: 853,
  },
  {
    ProcedureId: 1185,
  },
  {
    ProcedureId: 2513,
  },
  {
    ProcedureId: 2845,
  },
  {
    ProcedureId: 3177,
  },
  {
    ProcedureId: 3509,
  },
  {
    ProcedureId: 3841,
  },
  {
    ProcedureId: 166,
  },
  {
    ProcedureId: 498,
  },
  {
    ProcedureId: 830,
  },
  {
    ProcedureId: 1162,
  },
  {
    ProcedureId: 2822,
  },
  {
    ProcedureId: 3154,
  },
  {
    ProcedureId: 3486,
  },
  {
    ProcedureId: 3818,
  },
  {
    ProcedureId: 306,
  },
  {
    ProcedureId: 312,
  },
  {
    ProcedureId: 383,
  },
  {
    ProcedureId: 970,
  },
  {
    ProcedureId: 976,
  },
  {
    ProcedureId: 1047,
  },
  {
    ProcedureId: 2891,
  },
  {
    ProcedureId: 2962,
  },
  {
    ProcedureId: 2968,
  },
  {
    ProcedureId: 3632,
  },
  {
    ProcedureId: 329,
  },
  {
    ProcedureId: 360,
  },
  {
    ProcedureId: 993,
  },
  {
    ProcedureId: 2868,
  },
  {
    ProcedureId: 2985,
  },
  {
    ProcedureId: 2991,
  },
  {
    ProcedureId: 3532,
  },
  {
    ProcedureId: 3655,
  },
  {
    ProcedureId: 3870,
  },
  {
    ProcedureId: 260,
  },
  {
    ProcedureId: 429,
  },
  {
    ProcedureId: 924,
  },
  {
    ProcedureId: 1093,
  },
  {
    ProcedureId: 2421,
  },
  {
    ProcedureId: 3014,
  },
  {
    ProcedureId: 3183,
  },
  {
    ProcedureId: 3678,
  },
  {
    ProcedureId: 283,
  },
  {
    ProcedureId: 406,
  },
  {
    ProcedureId: 947,
  },
  {
    ProcedureId: 1070,
  },
  {
    ProcedureId: 3037,
  },
  {
    ProcedureId: 3160,
  },
  {
    ProcedureId: 3578,
  },
  {
    ProcedureId: 3701,
  },
  {
    ProcedureId: 3824,
  },
  {
    ProcedureId: 220,
  },
  {
    ProcedureId: 1216,
  },
  {
    ProcedureId: 2390,
  },
  {
    ProcedureId: 2473,
  },
  {
    ProcedureId: 2722,
  },
  {
    ProcedureId: 3469,
  },
  {
    ProcedureId: 3718,
  },
  {
    ProcedureId: 3801,
  },
  {
    ProcedureId: 114,
  },
  {
    ProcedureId: 575,
  },
  {
    ProcedureId: 907,
  },
  {
    ProcedureId: 1110,
  },
  {
    ProcedureId: 2782,
  },
  {
    ProcedureId: 3077,
  },
  {
    ProcedureId: 3114,
  },
  {
    ProcedureId: 3231,
  },
  {
    ProcedureId: 3409,
  },
  {
    ProcedureId: 266,
  },
  {
    ProcedureId: 423,
  },
  {
    ProcedureId: 755,
  },
  {
    ProcedureId: 767,
  },
  {
    ProcedureId: 1016,
  },
  {
    ProcedureId: 1099,
  },
  {
    ProcedureId: 3423,
  },
  {
    ProcedureId: 3755,
  },
  {
    ProcedureId: 3918,
  },
  {
    ProcedureId: 4016,
  },
  {
    ProcedureId: 1262,
  },
  {
    ProcedureId: 1348,
  },
  {
    ProcedureId: 2427,
  },
  {
    ProcedureId: 2590,
  },
  {
    ProcedureId: 2676,
  },
  {
    ProcedureId: 2922,
  },
  {
    ProcedureId: 277,
  },
  {
    ProcedureId: 412,
  },
  {
    ProcedureId: 609,
  },
  {
    ProcedureId: 621,
  },
  {
    ProcedureId: 707,
  },
  {
    ProcedureId: 2736,
  },
  {
    ProcedureId: 3031,
  },
  {
    ProcedureId: 3068,
  },
  {
    ProcedureId: 3277,
  },
  {
    ProcedureId: 3363,
  },
  {
    ProcedureId: 4064,
  },
  {
    ProcedureId: 561,
  },
  {
    ProcedureId: 715,
  },
  {
    ProcedureId: 964,
  },
  {
    ProcedureId: 1053,
  },
  {
    ProcedureId: 1302,
  },
  {
    ProcedureId: 1308,
  },
  {
    ProcedureId: 3964,
  },
  {
    ProcedureId: 2381,
  },
  {
    ProcedureId: 2630,
  },
  {
    ProcedureId: 2636,
  },
  {
    ProcedureId: 2974,
  },
  {
    ProcedureId: 3217,
  },
  {
    ProcedureId: 3223,
  },
  {
    ProcedureId: 366,
  },
  {
    ProcedureId: 661,
  },
  {
    ProcedureId: 1202,
  },
  {
    ProcedureId: 2530,
  },
  {
    ProcedureId: 2536,
  },
  {
    ProcedureId: 3317,
  },
  {
    ProcedureId: 3323,
  },
  {
    ProcedureId: 3858,
  },
  {
    ProcedureId: 3864,
  },
  {
    ProcedureId: 4202,
  },
  {
    ProcedureId: 174,
  },
  {
    ProcedureId: 515,
  },
  {
    ProcedureId: 761,
  },
  {
    ProcedureId: 1007,
  },
  {
    ProcedureId: 1010,
  },
  {
    ProcedureId: 1256,
  },
  {
    ProcedureId: 2682,
  },
  {
    ProcedureId: 2928,
  },
  {
    ProcedureId: 3171,
  },
  {
    ProcedureId: 3515,
  },
  {
    ProcedureId: 3764,
  },
  {
    ProcedureId: 4010,
  },
  {
    ProcedureId: 369,
  },
  {
    ProcedureId: 615,
  },
  {
    ProcedureId: 861,
  },
  {
    ProcedureId: 1156,
  },
  {
    ProcedureId: 2484,
  },
  {
    ProcedureId: 2828,
  },
  {
    ProcedureId: 3025,
  },
  {
    ProcedureId: 3369,
  },
  {
    ProcedureId: 3615,
  },
  {
    ProcedureId: 131,
  },
  {
    ProcedureId: 392,
  },
  {
    ProcedureId: 890,
  },
  {
    ProcedureId: 2384,
  },
  {
    ProcedureId: 2550,
  },
  {
    ProcedureId: 3048,
  },
  {
    ProcedureId: 3475,
  },
  {
    ProcedureId: 3546,
  },
  {
    ProcedureId: 3973,
  },
  {
    ProcedureId: 249,
  },
  {
    ProcedureId: 913,
  },
  {
    ProcedureId: 1245,
  },
  {
    ProcedureId: 2407,
  },
  {
    ProcedureId: 2905,
  },
  {
    ProcedureId: 2954,
  },
  {
    ProcedureId: 3403,
  },
  {
    ProcedureId: 3452,
  },
  {
    ProcedureId: 3618,
  },
  {
    ProcedureId: 3901,
  },
  {
    ProcedureId: 3950,
  },
  {
    ProcedureId: 4067,
  },
  {
    ProcedureId: 180,
  },
  {
    ProcedureId: 512,
  },
  {
    ProcedureId: 841,
  },
  {
    ProcedureId: 1102,
  },
  {
    ProcedureId: 1176,
  },
  {
    ProcedureId: 2599,
  },
  {
    ProcedureId: 2836,
  },
  {
    ProcedureId: 3426,
  },
  {
    ProcedureId: 3595,
  },
  {
    ProcedureId: 3758,
  },
  {
    ProcedureId: 486,
  },
  {
    ProcedureId: 535,
  },
  {
    ProcedureId: 2527,
  },
  {
    ProcedureId: 2576,
  },
  {
    ProcedureId: 2785,
  },
  {
    ProcedureId: 3191,
  },
  {
    ProcedureId: 3240,
  },
  {
    ProcedureId: 3781,
  },
  {
    ProcedureId: 721,
  },
  {
    ProcedureId: 727,
  },
  {
    ProcedureId: 1219,
  },
  {
    ProcedureId: 1296,
  },
  {
    ProcedureId: 2476,
  },
  {
    ProcedureId: 2642,
  },
  {
    ProcedureId: 2719,
  },
  {
    ProcedureId: 2885,
  },
  {
    ProcedureId: 3140,
  },
  {
    ProcedureId: 3211,
  },
  {
    ProcedureId: 3377,
  },
  {
    ProcedureId: 3383,
  },
  {
    ProcedureId: 157,
  },
  {
    ProcedureId: 206,
  },
  {
    ProcedureId: 655,
  },
  {
    ProcedureId: 698,
  },
  {
    ProcedureId: 704,
  },
  {
    ProcedureId: 870,
  },
  {
    ProcedureId: 4024,
  },
  {
    ProcedureId: 1196,
  },
  {
    ProcedureId: 1319,
  },
  {
    ProcedureId: 2862,
  },
  {
    ProcedureId: 3360,
  },
  {
    ProcedureId: 3526,
  },
  {
    ProcedureId: 3993,
  },
  {
    ProcedureId: 506,
  },
  {
    ProcedureId: 678,
  },
  {
    ProcedureId: 847,
  },
  {
    ProcedureId: 1268,
  },
  {
    ProcedureId: 1339,
  },
  {
    ProcedureId: 2593,
  },
  {
    ProcedureId: 2670,
  },
  {
    ProcedureId: 3260,
  },
  {
    ProcedureId: 3432,
  },
  {
    ProcedureId: 4093,
  },
  {
    ProcedureId: 529,
  },
  {
    ProcedureId: 578,
  },
  {
    ProcedureId: 2570,
  },
  {
    ProcedureId: 2619,
  },
  {
    ProcedureId: 2693,
  },
  {
    ProcedureId: 2742,
  },
  {
    ProcedureId: 3234,
  },
  {
    ProcedureId: 3283,
  },
  {
    ProcedureId: 137,
  },
  {
    ProcedureId: 884,
  },
  {
    ProcedureId: 1133,
  },
  {
    ProcedureId: 2556,
  },
  {
    ProcedureId: 2805,
  },
  {
    ProcedureId: 3054,
  },
  {
    ProcedureId: 3303,
  },
  {
    ProcedureId: 3552,
  },
  {
    ProcedureId: 243,
  },
  {
    ProcedureId: 741,
  },
  {
    ProcedureId: 790,
  },
  {
    ProcedureId: 1239,
  },
  {
    ProcedureId: 1276,
  },
  {
    ProcedureId: 1288,
  },
  {
    ProcedureId: 3446,
  },
  {
    ProcedureId: 3944,
  },
  {
    ProcedureId: 2401,
  },
  {
    ProcedureId: 2413,
  },
  {
    ProcedureId: 2450,
  },
  {
    ProcedureId: 2899,
  },
  {
    ProcedureId: 2911,
  },
  {
    ProcedureId: 2948,
  },
  {
    ProcedureId: 435,
  },
  {
    ProcedureId: 684,
  },
  {
    ProcedureId: 3005,
  },
  {
    ProcedureId: 3091,
  },
  {
    ProcedureId: 3254,
  },
  {
    ProcedureId: 3340,
  },
  {
    ProcedureId: 3681,
  },
  {
    ProcedureId: 4087,
  },
  {
    ProcedureId: 990,
  },
  {
    ProcedureId: 1027,
  },
  {
    ProcedureId: 1039,
  },
  {
    ProcedureId: 1076,
  },
  {
    ProcedureId: 2613,
  },
  {
    ProcedureId: 2650,
  },
  {
    ProcedureId: 2662,
  },
  {
    ProcedureId: 2699,
  },
  {
    ProcedureId: 3695,
  },
  {
    ProcedureId: 3732,
  },
  {
    ProcedureId: 3744,
  },
  {
    ProcedureId: 300,
  },
  {
    ProcedureId: 2464,
  },
  {
    ProcedureId: 3389,
  },
  {
    ProcedureId: 3638,
  },
  {
    ProcedureId: 151,
  },
  {
    ProcedureId: 194,
  },
  {
    ProcedureId: 692,
  },
  {
    ProcedureId: 1325,
  },
  {
    ProcedureId: 1331,
  },
  {
    ProcedureId: 2358,
  },
  {
    ProcedureId: 2364,
  },
  {
    ProcedureId: 4036,
  },
  {
    ProcedureId: 100,
  },
  {
    ProcedureId: 592,
  },
  {
    ProcedureId: 933,
  },
  {
    ProcedureId: 1182,
  },
  {
    ProcedureId: 2507,
  },
  {
    ProcedureId: 2756,
  },
  {
    ProcedureId: 3097,
  },
  {
    ProcedureId: 3346,
  },
  {
    ProcedureId: 3589,
  },
  {
    ProcedureId: 3838,
  },
  {
    ProcedureId: 443,
  },
  {
    ProcedureId: 492,
  },
  {
    ProcedureId: 541,
  },
  {
    ProcedureId: 984,
  },
  {
    ProcedureId: 1033,
  },
  {
    ProcedureId: 1082,
  },
  {
    ProcedureId: 3738,
  },
  {
    ProcedureId: 3787,
  },
  {
    ProcedureId: 2607,
  },
  {
    ProcedureId: 2656,
  },
  {
    ProcedureId: 2705,
  },
  {
    ProcedureId: 3148,
  },
  {
    ProcedureId: 3197,
  },
  {
    ProcedureId: 3246,
  },
]

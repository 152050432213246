import React from 'react'
import { TextH2, SubTitle } from '../../components/Text'
import { TableFilters } from '../../components/table'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'

const Observed = (props) => {
  const {
    changeModal,
    match: { params },
  } = props

  function handleClick(procedure) {
    changeModal(
      true,
      <>
        <TextH2 fontSize="2rem" color="var(--blue)">
          Observaciones del trámite {procedure.interNumber}
        </TextH2>
        <SubTitle subTitle="Número de factura">{procedure.billNumber}</SubTitle>
        <SubTitle subTitle="Compañía">{procedure.companyName}</SubTitle>
        <SubTitle subTitle="Número de DIM o DEX">
          {procedure.dimNumber}
        </SubTitle>
        <SubTitle subTitle="Número de pedido o pedido">
          {procedure.orderNumber}
        </SubTitle>
        <SubTitle subTitle="Observaciones del gestor">
          {procedure.obsGestor}
        </SubTitle>
        <SubTitle subTitle="Observaciones del liquidador">
          {procedure.obsLiquidator}
        </SubTitle>
      </>,
      true,
      '600px',
    )
  }
  return (
    <div>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Lista de trámites observados
      </TextH2>
      <br />
      <TableFilters
        params="/procedureStats/observation"
        functionClick={handleClick}
        addUrl={params.cid ? `company=${params.cid}` : ''}
        header={[
          { key: 'orderNumber', name: 'Número de pedido' },
          { key: 'billNumber', name: 'Número de factura' },
          { key: 'interNumber', name: 'Número de interno' },
          { key: 'dimNumber', name: 'Número de DIM o DEX' },
          { key: 'companyName', name: 'Compañía', type: 'text' },
          {
            key: 'listActions',
            name: 'Acciones',
            textBtn: 'Acciones',
            type: 'listActions',
            listBtn: [
              {
                children: <i className="fas fa-exclamation-circle"></i>,
                action: handleClick,
                label: 'Ver las observaciones',
              },
            ],
          },
        ]}
        // filterBoxes={[
        //   {
        //     type: 'getSelect',
        //     label: 'Todas las compañías',
        //     name: 'company',
        //     url: '/company/getallcompanies',
        //     urlName: 'id',
        //     urlLabel: 'razonSocial',
        //   },
        //   {
        //     type: 'getSelect',
        //     label: 'Seleccione la aduana',
        //     name: 'customsClearance',
        //     url: '/dataSet/CustomsClearances',
        //     urlName: 'id',
        //     urlLabel: 'name',
        //   },
        // ]}
      />
    </div>
  )
}

export default connect(null, { changeModal })(Observed)

import { validateArray } from '../../utils/Validate'
import './LineCircle.css'
import { TextH3 } from '../Text'
import { ToolTip } from '../boxes'
const LineCircle = (props) => {
  const { progress, step, list, maxItem = list.length } = props
  return (
    <div className="LineCircle__process">
      <div
        className="LineCircle__linearBar"
        style={{
          background: `linear-gradient(90deg, var(--blue) ${progress}%, #c2c2c2 ${progress}%)`,
        }}
      ></div>
      <div className="LineCircle__circle">
        {validateArray(list) &&
          list
            .sort((a, b) => a.step - b.step)
            .map((item, index) =>
              index < maxItem ? (
                <ToolTip content={item.name} key={index}>
                  <div
                    style={{
                      background: step <= index ? '#c2c2c2' : 'var(--blue)',
                      color: step <= index ? 'var(--blue)' : '#c2c2c2',
                    }}
                  >
                    <TextH3 margin="0 0 5px">{index + 1}</TextH3>
                  </div>
                </ToolTip>
              ) : null,
            )}
      </div>
    </div>
  )
}

export default LineCircle

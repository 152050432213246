import { validateArray } from '../../utils/Validate'
import TextError from '../inputs/TextError'

const SimpleSelect = (props) => {
  const { label, name, options, onChange, err, className, ...rest } = props
  return (
    <div className={`form-control ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <select onChange={onChange} name={name} id={name} {...rest}>
        {validateArray(options)
          ? options.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.key}
                </option>
              )
            })
          : null}
      </select>
      <TextError>{err}</TextError>
    </div>
  )
}

export default SimpleSelect

import React from 'react'
import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
const Theme = (props) => {
  const { themeReducer } = props
  return (
    <>
      <ThemeStyle color={themeReducer} />
      {props.children}
    </>
  )
}

const ThemeStyle = createGlobalStyle`
    :root {
        --white: ${(props) => props.color.white};
        --black: ${(props) => props.color.black};
        --blue: ${(props) => props.color.blue};
        --gray: ${(props) => props.color.gray};
        --red: ${(props) => props.color.red};
        --green: ${(props) => props.color.green};
    }
`
const mapStateToProps = (state) => {
  return {
    themeReducer: state.themeReducer,
  }
}
export default connect(mapStateToProps, null)(Theme)

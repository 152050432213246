import { connect } from 'react-redux'
import { changeModal } from '../../store/actions'
import { RenderModal } from '../modal'
const GlobalModal = ({ modalReducer, changeModal }) => {
  const { closeOutside, width } = modalReducer
  function handelModal() {
    changeModal(!modalReducer.status, null)
  }
  function onKeyPressed(e) {
    if (e.key === 'Escape') {
      handelModal()
    }
  }
  if (modalReducer.status) {
    return (
      <div onKeyDown={onKeyPressed} tabIndex={0}>
        <RenderModal
          width={width}
          onClose={handelModal}
          closeOutside={closeOutside}
        >
          {modalReducer.children}
        </RenderModal>
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    modalReducer: state.modalReducer,
  }
}
export default connect(mapStateToProps, { changeModal })(GlobalModal)

import { useState } from 'react'
import { useAuthAxios } from '../../hook'
import { Loading } from '../../components/animation'
import { ActionButton } from '../../components/buttons'
import { TextH2, SubTitle } from '../../components/Text'
import { validateArray, validateStatus } from '../../utils/Validate'
import { putAction, postAction } from '../../services/actions/AuthAction'
import {
  readDim,
  readDam,
  adjuntarDex,
  readParte,
} from '../../services/actions/ReadFileAction'

const CompleteContainer = (props) => {
  const { procedure, step, reload, openSnackbar, changeModal, tid } = props
  const [load, setload] = useState(true)
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: `/processstep/${procedure.processStepId}/procedure/${procedure.id}`,
  })

  async function handleComplete(procedure) {
    setload(false)
    if (validateArray(response.fields)) {
      submitRead(response.fields, 0, procedure, [], submitRead)
    } else {
      setload(true)
      await submitNextStep(procedure)
    }
    if (step === 6 && tid === 1) {
      await postAction(`/notification/procedure/${procedure.id}`, {}).then(
        (res) => {
          return { readStatus: res.status, readData: res.data }
        },
      )
    }
  }

  function submitRead(fields, index, procedure, result, submit) {
    if (index < fields.length) {
      if (fields[index] && fields[index].type === 'file') {
        handleReadFile(fields, index, procedure, result, submitRead)
      } else {
        submit(
          fields,
          index + 1,
          procedure,
          [...result, { readStatus: 0, readData: '', validate: false }],
          submit,
        )
      }
    } else {
      const resultFilter = result
        .filter((x) => x.validate === true)
        .filter((y) => !validateStatus(y.readStatus))

      if (validateArray(resultFilter)) {
        setload(true)
        openSnackbar(
          resultFilter[0].readData
            ? `${resultFilter[0].readData}`
            : 'No se pudo guardar',
          true,
          false,
        )
      } else {
        setload(true)
        submitNextStep(procedure)
      }
    }
  }

  function handleReadFile(fields, index, procedure, result, submit) {
    switch (fields[index].name) {
      case 'adjuntarDim':
        readDim(fields, index, procedure, result, submit)
        break
      case 'dimRegularizada':
        readDim(fields, index, procedure, result, submit)
        break
      case 'adjuntarDex':
        adjuntarDex(fields, index, procedure, result, submit)
        break
      case 'documentoDam':
        readDam(fields, index, procedure, result, submit)
        break
      case 'parteDeRecepcion':
        readParte(fields, index, procedure, result, submit)
        break
      default:
        submit(
          fields,
          index + 1,
          procedure,
          [...result, { readStatus: 0, readData: '', validate: false }],
          submit,
        )
        break
    }
  }

  async function submitNextStep(procedure) {
    await putAction('/dataset/lockforms', handleSend(response.fields))
    await putAction(`/dataset/nextstep/${procedure.id}`, {}).then((res) => {
      setload(true)
      if (validateStatus(res.status)) {
        openSnackbar('Se guardo exitosamente', true, true)
        changeModal(false, null)
        reload()
      } else {
        openSnackbar(res.data ? res.data : 'No se pudo guardar', true, false)
      }
    })
  }

  function handleSend(list) {
    return list.map((item) => ({
      Value: '',
      ProcedureId: procedure.id,
      FieldId: item.id,
    }))
  }

  return (
    <>
      <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
        ¿Estas seguro que quieres completar el formulario?
      </TextH2>
      <SubTitle subTitle="Número de interno">{procedure.internCode}</SubTitle>
      {!load || loading ? (
        <Loading />
      ) : (
        <ActionButton
          onClick={() => handleComplete(procedure)}
          onCancel={() => changeModal(false, null)}
          textBtn="Completar"
        />
      )}
    </>
  )
}
export default CompleteContainer

import { useState } from "react";
import { FormDefault } from "../../components/forms";
import * as Yup from "yup";
import { postAction } from "../../services/actions/AuthAction";
import { validateStatus } from "../../utils/Validate";

const FormReportArchive = (props) => {
  const { changeModal, openSnackbar } = props;
  const [load, setload] = useState(false);

  const onSubmit = async (values) => {
    setload(true);
    try {
      await postAction(`/procedure/ReportEndStep?year=${values.Year}`).then(
        (res) => {
          setload(false);
          if (validateStatus(res.status)) {
            console.log(res.data);
            window.open(res.data.url);
            openSnackbar("Se genero el reporte", true, true);
            changeModal(false, null);
          } else {
            openSnackbar("No se puedo crear", true, false);
          }
        }
      );
    } catch (e) {
      setload(false);
      console.log(e);
    }
  };

  function createOption() {
    const fecha = new Date();
    const añoActual = fecha.getFullYear();
    let years = [];
    for (let index = 2021; index < añoActual + 1; index++) {
      years.push({ label: index, key: index, value: index });
    }
    return years;
  }
  return (
    <div>
      <FormDefault
        load={load}
        title="Generar Reporte"
        textBtn="Generar"
        InputsJson={[
          //   {
          //     name: "InitialRangeDate",
          //     control: "datePicker",
          //     label: "Rango de fecha de Inicio",
          //     dateFormat: "yyyy-MM-dd",
          //     initial: null,
          //   },
          //   {
          //     name: "FinalRangeDate",
          //     control: "datePicker",
          //     label: "Rango de fecha final",
          //     dateFormat: "yyyy-MM-dd ",
          //     initial: null,
          //   },
          {
            name: "Year",
            initial: "",
            validate: Yup.string().required("Campo requerido"),
            control: "select",
            label: "Gestión",
            options: createOption(),
          },
        ]}
        data={{}}
        onSubmit={onSubmit}
        onCancel={() => changeModal(false, null)}
      />
    </div>
  );
};

export default FormReportArchive;

import { connect } from 'react-redux'
import { useAuthAxios } from '../../hook'
import { TextH2, TextH3 } from '../../components/Text'
import { changeModal } from '../../store/actions'
import { validateArray } from '../../utils/Validate'
import { convertDate } from '../../utils/ConvertDate'
import { Loading } from '../../components/animation'
import { BoxFlex } from '../../components/boxes'
import ListUser from '../../containers/notifications/ListUser'

const SurveyResponse = (props) => {
  const { changeModal } = props
  function handleClick(user) {
    changeModal(true, <ResponseUser {...props} user={user} />, true, '500px')
  }
  return (
    <div>
      <ListUser
        {...props}
        handleClick={handleClick}
        addUrl={'&RolName=client'}
      />
    </div>
  )
}
const ResponseUser = ({ user }) => {
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: `/clientStats/listRaiting?userId=${user.id}`,
  })

  return (
    <div className="">
      <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
        Respuesta de {user.name}
      </TextH2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((item) => (
          <div
            style={{
              border: '1px solid gray',
              padding: '5px 10px 10px',
              marginBottom: '10px',
              borderRadius: '10px',
            }}
            key={item.id}
          >
            <BoxFlex justifyContent="space-between">
              <TextH3 color="var(--gray)" margin="0">
                Valoración: {item.value}/5{' '}
                <span style={{ fontSize: '2rem' }}>
                  {{ 1: '🙁', 2: '😕', 3: '😑', 4: '🙂', 5: '😄' }[item.value]}
                </span>
              </TextH3>
              <TextH3 color="var(--gray)" margin="0">
                {convertDate(item.registerDate, 'lll')}
              </TextH3>
            </BoxFlex>
            <TextH3 margin="0">{item.text}</TextH3>
          </div>
        ))
      ) : (
        <TextH3 color="var(--gray)">
          {user.name} no respondió a ninguna encuesta
        </TextH3>
      )}
    </div>
  )
}
export default connect(null, { changeModal })(SurveyResponse)

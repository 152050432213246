import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import { GlobalButton } from '../buttons'
import { TextH1 } from '../Text'
import { Link } from 'react-router-dom'
import './style/Forms.css'
import { useState } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { postAction, getAction } from '../../services/actions/Action'
import { startSession, openSnackbar } from '../../store/actions'
import { validateStatus } from '../../utils/Validate'
import Loading from '../animation/Loading'
import { tokenIpinfo } from '../../config.json'
const Login = (props) => {
  const { startSession, history, openSnackbar } = props
  const [load, setload] = useState(true)
  const initialValues = {
    UsernameOrEmail: '',
    Password: '',
  }
  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required('Campo Obligatorio'),
    Password: Yup.string()
      .required('Campo Obligatorio')
      .min(4, 'Mínimo 4 caracteres'),
  })
  const onSubmit = async (values) => {
    setload(false)
    try {
      await postAction('/auth/Login', values).then((res) => {
        if (validateStatus(res.status)) {
          getAction('https://api.ipify.org')
            .then((response) => {
              setload(true)
              openSnackbar('Inicio Exitoso', true, true)
              const { rols, user, token, rating, companys } = res.data
              window.localStorage.setItem('id', user.id)
              window.localStorage.setItem('token_seguridad', token)
              startSession(
                user,
                rols,
                [],
                true,
                rating,
                companys,
                validateStatus(response.status) && response.data
                  ? response.data
                  : null,
              )
              history.push('/')
            })
            .catch(() => {
              setload(true)
              openSnackbar('Inicio Exitoso', true, true)
              const { rols, user, token, rating, companys } = res.data
              window.localStorage.setItem('id', user.id)
              window.localStorage.setItem('token_seguridad', token)
              startSession(user, rols, [], true, rating, companys, null)
              history.push('/')
            })
        } else {
          setload(true)
          openSnackbar('El usuario o contraseña son incorrectos', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="log_in">
      <TextH1 fontSize="2.4rem" textAlign="center" color="var(--blue)">
        Iniciar sesión
      </TextH1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Usuario o correo"
                name="UsernameOrEmail"
                autoFocus={true}
              />
              <FormikControl
                control="input"
                type="password"
                label="Contraseña"
                name="Password"
              />
              {load ? (
                <center>
                  <GlobalButton type="submit">Iniciar</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <center>
        <Link className="link_style" to="/auth/recover">
          ¿Olvidaste tu contraseña?
        </Link>
      </center>
    </div>
  )
}

export default connect(null, { startSession, openSnackbar })(Login)

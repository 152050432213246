import { TextH2, TextH3 } from '../../components/Text'
import { TableFilters } from '../../components/table'
import { GlobalButton } from '../../components/buttons'
import { RenderModal, ModalConfirmation } from '../../components/modal'
import { FormDefault } from '../../components/forms'
import { BoxFlex } from '../../components/boxes'
import UserDetail from '../../containers/register/UserDetail'
import CompanyDetail from '../../containers/register/CompanyDetail'
import { validateStatus } from '../../utils/Validate'
import { headerUsers, headerClient } from '../../utils/TableFields'
import { inputsClient, inputsUser } from '../../utils/FormFields'
import { arrayToJson } from '../../utils/ProcessData'
import { changeModal, openSnackbar } from '../../store/actions'
import { useAuthAxios } from '../../hook'
import { connect } from 'react-redux'
import { postAction, putAction } from '../../services/actions/AuthAction'
import { useState } from 'react'
import * as Yup from 'yup'
import { Loading } from '../../components/animation'
import { getAction } from '../../services/actions/Action'

const Register = (props) => {
  const {
    changeModal,
    openSnackbar,
    match: { params },
  } = props
  const [modal, setmodal] = useState({
    status: false,
    idForm: null,
  })
  const [load, setload] = useState(false)
  const [reload, setreload] = useState(false)

  function handleForm(status, id) {
    setmodal({
      status: status,
      idForm: id,
    })
  }
  async function onSubmit(values) {
    setload(true)
    try {
      await postAction(
        modal.idForm === 1 ? '/auth/Register' : '/company/Register',
        modal.idForm === 1
          ? {
              ...values,
              Rol: arrayToJson(values.Rol),
              Status: 'Activo',
            }
          : values,
      ).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Registro exitoso', true, true)
          setreload(!reload)
          handleForm(false, null)
        } else {
          openSnackbar(res.data.title ? res.data.title : res.data, true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  function handleClick(item, reload) {
    changeModal(
      true,
      params.id === '1' ? (
        <UserDetail {...props} handleReload={reload} user={item} />
      ) : (
        <CompanyDetail
          {...props}
          company={item}
          handleReload={reload}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      ),
    )
  }

  function handleFormPoll() {
    changeModal(
      true,
      <FormPoll {...props} onCancel={() => changeModal(false, null)} />,
      true,
      '500px',
    )
  }


  async function GenerateReport()
  {
    setload(true)
    try {
      await postAction('/procedure/ReportClient')
      .then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Reporte generado', true, true)
          window.open(res.data.url)
          setreload(!reload)
          handleForm(false, null)
        } else {
          openSnackbar(res.data.title ? res.data.title : res.data, true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  return (
    <>
      <div className="Register">
        <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
          <TextH2 fontSize="2.2rem" color="var(--blue)" margin="0">
            {params.id === '1' ? 'Usuarios' : 'Compañías'}
          </TextH2>
          <BoxFlex flexWrap="wrap" justifyContent="flex-end">
            <GlobalButton
              background="transparent"
              onClick={handleFormPoll}
              width="fit-content"
            >
              Editar encuesta
            </GlobalButton>
            <GlobalButton
              onClick={() => handleForm(true, 1)}
              width="fit-content"
            >
              Registrar usuarios
            </GlobalButton>
            <GlobalButton
              onClick={() => handleForm(true, 2)}
              width="fit-content"
            >
              Registrar compañía
            </GlobalButton>
            <GlobalButton
              //onClick={() => handleForm(true, 2)}
              onClick={GenerateReport}
              width="fit-content"
              background = "rgb(5, 38, 145)"
              color ="rgb(226, 228, 243)"
            >
              Generar Reporte
            </GlobalButton>
          </BoxFlex>
        </BoxFlex>
        <br />
        {params.id === '1' ? (
          <TableFilters
            params="/users/ListUsers"
            addUrl={`userId=${window.localStorage.getItem('id')}`}
            functionClick={handleClick}
            header={headerUsers}
            handleReload={reload}
            filterBoxes={[
              {
                type: 'getSelect',
                label: 'Todos los roles',
                name: 'RolName',
                url: '/rol',
                urlName: 'name',
                urlLabel: 'label',
              },
              {
                type: 'select',
                label: 'Estado',
                name: 'status',
                options: [
                  { key: 'Activo', value: 'activo' },
                  { key: 'Inactivo', value: 'inactivo' },
                ],
              },
            ]}
          />
        ) : (
          <TableFilters
            params="/company"
            functionClick={handleClick}
            header={headerClient}
            handleReload={reload}
          />
        )}
      </div>
      {modal.status && (
        <RenderModal
          width="500px"
          onClose={() => handleForm(false, null)}
          closeOutside
        >
          <FormDefault
            load={load}
            title={
              modal.idForm === 1
                ? 'Registrar usuario nuevo'
                : 'Registrar cliente nuevo'
            }
            textBtn="Registrar"
            InputsJson={modal.idForm === 1 ? inputsUser : inputsClient}
            data={{}}
            onSubmit={onSubmit}
            onCancel={() => handleForm(false, null)}
          />
        </RenderModal>
      )}
    </>
  )
}

const FormPoll = (props) => {
  const { changeModal, openSnackbar, onCancel } = props
  const [load, setload] = useState(false)
  const { response, loading, reload } = useAuthAxios({
    method: 'GET',
    url: '/users/preferenceUrl',
  })

  function handlePoll() {
    changeModal(
      true,
      <ModalConfirmation
        textBtn="Habilitar"
        children={
          <>
            <TextH2
              textAlign="center"
              fontSize="2rem"
              color="var(--blue)"
              margin="0 0 5px"
            >
              Estas seguro que quieres habilitar la encuesta
            </TextH2>
            <TextH3>
              A todos los usuarios con el rol de cliente se les enviará el
              enlace de la encuesta
            </TextH3>
          </>
        }
        onCancel={() =>
          changeModal(true, <FormPoll {...props} />, true, '500px')
        }
        url="/users/preferencesForPoll/reset"
        action={putAction}
        textSuccess="Se guardo con éxito"
        textError="No se pudo habilitar"
        openSnackbar={openSnackbar}
      />,
    )
  }
  const onSubmit = async (values) => {
    setload(true)
    try {
      await putAction('/users/preferenceUrl', values).then((response) => {
        setload(false)
        if (validateStatus(response.status)) {
          props.openSnackbar('Se edito el enlace', true, true)
          reload()
        } else {
          props.openSnackbar('No se pudo editar', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <BoxFlex justifyContent="space-between">
          <TextH2 fontSize="2rem" color="var(--blue)" margin="0 0 5px">
            Editar enlace de encuesta
          </TextH2>
          <GlobalButton onClick={handlePoll} width="fit-content">
            Habilitar encuesta
          </GlobalButton>
        </BoxFlex>
        <FormDefault
          load={load}
          textBtn="Editar"
          InputsJson={[
            {
              name: 'Url',
              initial: '',
              validate: Yup.string().required('Campo requerido'),
              type: 'text',
              label: 'Enlace',
            },
          ]}
          data={response ? { Url: response.url } : {}}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal, openSnackbar })(Register)

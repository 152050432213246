import { useState } from "react";
import { validateStatus } from "../../utils/Validate";
import { capitalizeJson } from "../../utils/ProcessData";
import { FormDefault } from "../../components/forms";
import * as Yup from "yup";
import { putAction } from "../../services/actions/AuthAction";
const EditYearProcedure = (props) => {
  const { reload, onCancel, openSnackbar, procedure } = props;
  const [load, setload] = useState(false);
  async function onSubmit(value) {
    
    console.log("uauau",value.InternCode);
    await putAction(`/procedure/${procedure.id}/ChangeManagement?InternCode=${value.InternCode}`, {
      id: procedure.id,
      ...value,
    }).then((res) => {
      setload(false);
      if (validateStatus(res.status)) {
        openSnackbar("Se guardo exitosamente", true, true);
        onCancel();
        reload();
      } else {
        openSnackbar(res.data ? res.data : "No se pudo guardar", true, false);
      }
    });
  }
console.log(procedure);
  return (
    <FormDefault
      load={load}
      title="Editar Gestión"
      textBtn="Editar"
      InputsJson={[{
        name: "InternCode",
        initial: "",
        validate: Yup.string().required("Campo requerido"),
        type: "text",
        label: "Codigo de interno",
      },]}
      data={capitalizeJson(procedure)}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default EditYearProcedure
import { TextH3 } from '../../components/Text'
import GraphicsFilters from './GraphicsFilters'
import { validateArray } from '../../utils/Validate'
import { GraphicsContainer } from '../../components/graphics'

const ProcedureClosedStep = (props) => {
  const { filters, handleDate, changeModal } = props

  function handleClickProcess(process, params) {
    changeModal(
      true,
      <ProcedureClosedUser {...props} process={process} />,
      true,
      '900px',
    )
  }

  return (
    <GraphicsFilters
      type="bar"
      url="/procedureStats/closed-per-step?"
      filters={filters}
      getFilters={handleDate}
      typeData="list"
      title="Trámites completados por procesos"
      name="stepName"
      label="step"
      stringAdd="Proc. "
      onClick={handleClickProcess}
      params={[
        {
          type: 'getSelect',
          label: 'Todas las compañías',
          name: 'CompanyId',
          url: '/company/getallcompanies',
          urlName: 'id',
          urlLabel: 'razonSocial',
        },
        {
          type: 'getSelect',
          label: 'Tipo de procedimiento',
          name: 'ProcedureTypeId',
          url: '/proceduretype',
          urlName: 'id',
          urlLabel: 'name',
        },
        {
          type: 'select',
          label: 'Filtrar por departamento',
          name: 'Department',
          options: [
            { key: 'La Paz', value: 'La Paz' },
            { key: 'Santa Cruz', value: 'Santa Cruz' },
            { key: 'Chuquisaca', value: 'Chuquisaca' },
            { key: 'Cochabamba', value: 'Cochabamba' },
            { key: 'Oruro', value: 'Oruro' },
            { key: 'Potosí', value: 'Potosí' },
            { key: 'Tarija', value: 'Tarija' },
            { key: 'Beni', value: 'Beni' },
            { key: 'Pando', value: 'Pando' },
          ],
        },
        {
          type: 'select',
          label: 'Filtrar por regional de oficina',
          name: 'CityUser',
          options: [
            { key: 'La Paz', value: 'La Paz' },
            { key: 'Santa Cruz', value: 'Santa Cruz' },
            { key: 'Chuquisaca', value: 'Chuquisaca' },
            { key: 'Cochabamba', value: 'Cochabamba' },
            { key: 'Oruro', value: 'Oruro' },
            { key: 'Potosí', value: 'Potosí' },
            { key: 'Tarija', value: 'Tarija' },
            { key: 'Beni', value: 'Beni' },
            { key: 'Pando', value: 'Pando' },
          ],
        },
      ]}
    />
  )
}

const ProcedureClosedUser = ({ process }) => {
  function handleData(data) {
    let aux = []
    data.forEach((element) => {
      aux.push({
        ...element,
        label: element.name,
        name: element.name,
        color: `var(--blue)`,
        data: element.amount,
        percentageCalc: ((100 * element.amount) / process.amount).toFixed(2),
        percentage: element.amount,
      })
    })
    return aux
  }

  return (
    <>
      <div className="GraphicsFilters__header">
        <TextH3
          textAlign="center"
          margin="0"
          color="var(--blue)"
          fontSize="2rem"
        >
          {`Trámites completados de proceso ${process.name}`}
        </TextH3>
      </div>
      <div className="GraphicsFilters__main">
        {validateArray(process.userStat) ? (
          <GraphicsContainer
            size="small"
            type="barColumn"
            data={handleData(process.userStat)}
          />
        ) : (
          <>
            <TextH3
              textAlign="center"
              margin="10px 0 0"
              color="var(--blue)"
              fontSize="2rem"
            >
              No hay datos
            </TextH3>
            <br />
          </>
        )}
      </div>
    </>
  )
}
export default ProcedureClosedStep

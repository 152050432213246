import { TextH2, TextH3 } from './'
const SubTitle = ({ subTitle, children, type }) => {
  return (
    <>
      <TextH2 margin="0 0 5px" fontWeight="500">
        {subTitle}:
      </TextH2>
      <TextH3 color="var(--gray)" margin="0" textAlign="center">
        {type === 'list'
          ? children
            ? children.split(';').map((item, index) => (
                <span
                  style={{
                    margin: '0 5px',
                  }}
                  key={index}
                >
                  {item}
                </span>
              ))
            : '--'
          : children
          ? children
          : '--'}
      </TextH3>
    </>
  )
}

export default SubTitle

import { validateStatus } from '../../utils/Validate'
import { inputNewStep } from '../../utils/FormFields'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { postAction } from '../../services/actions/AuthAction'

const NewStep = (props) => {
  const {
    reload,
    changeModal,
    openSnackbar,
    match: { params },
  } = props
  const [load, setload] = useState(false)
  async function onSubmit(values) {
    try {
      await postAction('/processstep', {
        ...values,
        RolIdList: values.Rol,
        ProcedureTypeId: params.id,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se registro exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo crear', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  return (
    <FormDefault
      load={load}
      title="Crear nuevo paso"
      textBtn="Crear"
      InputsJson={inputNewStep}
      data={{}}
      onSubmit={onSubmit}
      onCancel={() => changeModal(false, null)}
    />
  )
}

export default NewStep

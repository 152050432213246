import { Search } from '../inputs'
import { validateArray, validateStatus } from '../../utils/Validate'
import { TextH3 } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { Loading } from '../../components/animation'
import { GlobalButton } from '../../components/buttons'
import { postAction } from '../../services/actions/AuthAction'
import { openSnackbar, changeModal } from '../../store/actions'
import { connect } from 'react-redux'
const TableHeader = (props) => {
  const {
    search,
    onChange,
    options,
    onSearch,
    order,
    seturl,
    setsearch,
    url,
    filter,
    filterName,
    urlParam,
    paramsDownload,
    openSnackbar,
    changeModal,
  } = props
  function handleOrder(e) {
    const { name, value } = e.target
    seturl((a) => ({ ...a, [name]: value }))
  }
  function handleClear() {
    setsearch((a) => ({ ...a, search: '' }))
    onSearch()
  }

  return (
    <BoxFlex flexWrap="wrap">
      {validateArray(options) && (
        <Search
          onClick={onSearch}
          search={search}
          onChange={onChange}
          options={options}
          handleClear={handleClear}
        />
      )}
      {paramsDownload && (
        <DownloadDocument
          urlParam={urlParam}
          paramsDownload={paramsDownload}
          openSnackbar={openSnackbar}
          changeModal={changeModal}
        />
      )}
      {order && (
        <>
          <div></div>
          <div></div>
          <BoxFlex>
            <TextH3 margin="0">Ordenar por:</TextH3>
            <select
              className="Search__select"
              onChange={handleOrder}
              name="OrderBy"
              value={url.OrderBy}
            >
              {validateArray(order)
                ? order.map((option) => {
                    return (
                      <option
                        key={option.key}
                        value={option.search || option.key}
                      >
                        {option.name}
                      </option>
                    )
                  })
                : null}
            </select>
          </BoxFlex>
        </>
      )}
      {validateArray(filter) ? (
        <BoxFlex>
          <TextH3 margin="0">Filtrar por:</TextH3>
          <select
            className="Search__select"
            onChange={handleOrder}
            name={filterName}
            value={url[filterName]}
          >
            {filter.map((option) => {
              return (
                <option key={option.id} value={option.key}>
                  {option.label || option.name}
                </option>
              )
            })}
          </select>
        </BoxFlex>
      ) : null}
    </BoxFlex>
  )
}

const DownloadDocument = ({
  urlParam,
  paramsDownload,
  openSnackbar,
  changeModal,
}) => {
  async function onSubmit() {
    changeModal(
      true,
      <>
        <TextH3>Descargando...</TextH3>
        <Loading />
      </>,
      true,
      '200px',
    )
    const params = urlParam.split('?')
    await postAction(`${paramsDownload}?${params[1]}`).then((res) => {
      if (validateStatus(res.status)) {
        changeModal(
          true,
          <>
            <TextH3 fontSize="1.8rem">Enlace del documento:</TextH3>
            <TextH3 textAlign="center">
              <a href={res.data.url} target="_blank" rel="noopener noreferrer">
                Descargar
              </a>
            </TextH3>
          </>,
          true,
          '200px',
          true,
        )
        window.open(res.data.url, '_blank')
        openSnackbar('Se guardo exitosamente', true, true)
      } else {
        changeModal(false, null)
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }
  return (
    <div className="">
      <GlobalButton onClick={onSubmit} background="var(--blue)" color="#e2e4f3">
        Descargar <i className="fas fa-download"></i>
      </GlobalButton>
    </div>
  )
}

export default connect(null, { openSnackbar, changeModal })(TableHeader)

import { TextH2 } from '../../components/Text'
import { TableFilters } from '../../components/table'

const loanTable = [
  { key: 'internCode', name: 'Número de interno' },
  { key: 'orderNumber', name: 'Número de pedido' },
  { key: 'billNumber', name: 'Número de factura' },
  { key: 'dimNumber', name: 'Número de DIM o DEX' },
  { key: 'nameCompany', name: 'Compañía', type: 'text' },
  { key: 'loanDate', name: 'Fecha de registro', type: 'dateShort' },
  {
    key: 'tentativeDate',
    name: 'Fecha tentativa de la devolución',
    type: 'dateShort',
  },
]
const ProceduresLoan = (props) => {
  const {
    match: { params },
  } = props
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Listado de préstamos
      </TextH2>
      <br />
      <TableFilters
        params="loans"
        // functionClick={handleLoansClick}
        addUrl={`type=${params.type}&userId=${window.localStorage.getItem(
          'id',
        )}`}
        header={
          params.type !== 'all'
            ? [
                ...loanTable,
                {
                  key: 'conformity',
                  name: 'Estado',
                  type: 'textBoolean',
                  translate: {
                    true: 'Entregado',
                    false: 'Prestado',
                  },
                  color: {
                    true: 'var(--green)',
                    false: 'var(--red)',
                  },
                },
              ]
            : [
                ...loanTable,
                {
                  key: 'fullNameGiverUser',
                  name: 'Personal de archivo',
                  type: 'text',
                },
                {
                  key: 'fullNameRequestingUser',
                  name: 'Solicitante',
                  type: 'text',
                },
                {
                  key: 'reason',
                  name: 'Razón',
                  type: 'text',
                },
                {
                  key: 'conformity',
                  name: 'Estado',
                  type: 'textBoolean',
                  translate: {
                    true: 'Entregado',
                    false: 'Prestado',
                  },
                  color: {
                    true: 'var(--green)',
                    false: 'var(--red)',
                  },
                },
              ]
        }
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </>
  )
}

export default ProceduresLoan

import styled from 'styled-components'

const BoxCardStyled = styled.div`
  background: var(--white);
  border: 1px solid var(--gray);
  padding: 8px;
  margin: 0 0 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: ${(props) => props.width};
  &:hover {
    box-shadow: 0px 1px 14px -2px var(--blue);
    border: 1px solid var(--blue);
  }
`
const BoxCard = (props) => {
  const { children, onClick, width = '100%' } = props
  return (
    <BoxCardStyled width={width} onClick={onClick}>
      {children}
    </BoxCardStyled>
  )
}

export default BoxCard

import { Switch, Route } from "react-router-dom";
import {
  Auth,
  Home,
  Client,
  Account,
  Register,
  Procedure,
  Documents,
  Management,
  EditDataset,
  ManageForms,
  ClientResume,
  ClosedProcedures,
  HistoryProcedures,
  Observed,
  Loan,
  ProceduresLoan,
  MyProcedure,
  DownloadHistory,
  ProcessesStopped,
  ProceduresContravention,
  NotificationsSent,
  SurveyHistory,
  SurveyResponse,
  ProceduresRegularize,
  EnabledUsers,
} from "../view";
import ProtectedRoute from "./ProtectedRoute";
const Main = (props) => {
  const { procedureSocket, setProcedureSocket } = props;
  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        component={Home}
        validate={["isAuthenticated"]}
      />
      <Route path="/auth" component={Auth} />
      <ProtectedRoute
        exact
        path="/client-resume/:id"
        component={ClientResume}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/account"
        component={Account}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/list-procedure/:id"
        component={Client}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/management"
        component={Management}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/register/:id?"
        component={Register}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/documents/:id"
        component={Documents}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/view-dataset/:id"
        component={EditDataset}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/edit-dataset/:id"
        component={EditDataset}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/closed-procedures/:id"
        component={ClosedProcedures}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/procedure-history/:id"
        component={HistoryProcedures}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/manage-forms/:id"
        component={ManageForms}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/type/:tid/process/:id/:name?"
        procedureSocket={procedureSocket}
        setProcedureSocket={setProcedureSocket}
        component={Procedure}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/my-process"
        component={MyProcedure}
        validate={["isAuthenticated"]}
      />

      <ProtectedRoute
        exact
        path="/enabled-users"
        component={EnabledUsers}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/procedures-observed/:cid?"
        component={Observed}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/processes-stopped/:type?"
        component={ProcessesStopped}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/procedures-contravention/:type?"
        component={ProceduresContravention}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/procedures-regularize/:type?"
        component={ProceduresRegularize}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/download-history"
        component={DownloadHistory}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/loan/:id?"
        component={Loan}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/procedures-loan/:type?"
        component={ProceduresLoan}
        validate={["isAuthenticated"]}
      />

      <ProtectedRoute
        exact
        path="/notifications-sent"
        component={NotificationsSent}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/survey-response"
        component={SurveyResponse}
        validate={["isAuthenticated"]}
      />
      <ProtectedRoute
        exact
        path="/survey-history"
        component={SurveyHistory}
        validate={["isAuthenticated"]}
      />
      <Route path="*" component={Home} />
    </Switch>
  );
};

export default Main;

import './Snackbar.css'
import { connect } from 'react-redux'
import { openSnackbar } from '../../store/actions'
function Snackbar(props) {
  const { open, message, kind } = props.snackbarReducer
  if (open) {
    setTimeout(function () {
      props.openSnackbar('', false, true)
    }, 3000)
    return <SnackbarSuccess message={message} kind={kind} />
  } else {
    return null
  }
}
const SnackbarSuccess = ({ message, kind }) => {
  return (
    <div
      className="snackText"
      style={{
        background: kind ? 'var(--green)' : 'var(--red)',
        color: 'var(--white)',
      }}
    >
      <i className={`fas fa-${kind ? 'check' : 'times'}-circle`}></i>
      {message && typeof message === 'string'
        ? message
        : kind
        ? 'Completado con éxito'
        : 'Error al completar'}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    snackbarReducer: state.snackbarReducer,
  }
}
const mapDispatchToProps = {
  openSnackbar,
}
export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'
const ToolTip = ({ children, content, interactive, trigger, placement }) => {
  return (
    <Tippy
      animation="scale"
      trigger={trigger ? trigger : 'mouseenter focus'}
      placement={placement ? placement : 'top'}
      interactive={interactive ? true : false}
      content={
        <div style={{ fontSize: '1.6rem', fontWeight: '500' }}>{content}</div>
      }
      theme="light"
    >
      {children}
    </Tippy>
  )
}

export default ToolTip

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')
export function convertDate(dateConv, formatDate) {
  if (dateConv) {
    if (formatDate === 'LLLL') {
      const date = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LL')
      const hour = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LT')
      return date + ' a horas ' + hour
    } else {
      return moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format(formatDate)
    }
  }
  return null
}
export function convertDate2(
  dateConv,
  format = 'YYYY-MM-DDTHH:mm:ss',
  addString,
) {
  if (moment(dateConv, format, true).isValid()) {
    return convertDate(dateConv, format) + addString
  } else {
    return dateConv
  }
}
export function dateCompare(compare, subtractDays = 0) {
  if (compare === '0001-01-01T00:00:00') {
    return true
  } else {
    const day = moment().clone().format('YYYY-MM-DDTHH:mm:ss')
    var date = moment(compare)
      .subtract(subtractDays, 'day')
      .format('YYYY-MM-DDTHH:mm')
    return day > date
  }
}
export function daysMonth(date) {
  return moment(date).daysInMonth()
}
export function diffDate(date) {
  return moment(date).diff(moment(), 'days')
}

const TextH3 = (props) => {
  const {
    children,
    color,
    fontSize = '1.6rem',
    margin = '0 0 10px',
    fontWeight = '500',
    textAlign,
    onClick,
    ...rest
  } = props
  return (
    <h3
      style={{
        color: color,
        textAlign: textAlign,
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
        ...rest,
      }}
      onClick={onClick}
      className="TextH3"
    >
      {children}
    </h3>
  )
}
export default TextH3

import { TextH3 } from '../Text'
import { GlobalButton } from '../buttons'
import { BoxFlex } from '../common'

function generatePage(header) {
  if (header && header.pagination) {
    return JSON.parse(header.pagination)
  }
  return null
}
const TableFooter = (props) => {
  const { amount, pageInfo, seturl, url } = props
  const header = generatePage(pageInfo)
  function handlePageNumber(number) {
    seturl((a) => ({ ...a, PageNumber: a.PageNumber + number }))
  }
  if (header) {
    return (
      <BoxFlex justify="space-between">
        <TextH3 margin="0" fontSize="1.4rem">
          Mostrando del {(url.PageNumber - 1) * header.itemsPerPage + 1} -{' '}
          {(url.PageNumber - 1) * header.itemsPerPage + amount} de{' '}
          {header.totalItems}
        </TextH3>
        <BoxFlex>
          <GlobalButton
            width="fit-content"
            onClick={() => handlePageNumber(-1)}
            disabled={url.PageNumber <= 1 ? true : false}
          >
            <i className="fas fa-chevron-left"></i>
          </GlobalButton>
          <GlobalButton
            width="fit-content"
            onClick={() => handlePageNumber(1)}
            disabled={header.totalPages <= url.PageNumber ? true : false}
          >
            <i className="fas fa-chevron-right"></i>
          </GlobalButton>
        </BoxFlex>
      </BoxFlex>
    )
  } else {
    return (
      <TextH3 margin="0" fontSize="1.4rem">
        Total: {amount}
      </TextH3>
    )
  }
}

export default TableFooter

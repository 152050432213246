import "./ClientResume.css";
import { useEffect, useState } from "react";
import { TextH3 } from "../../components/Text";
import { BoxFlex, CardBox } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { useAuthAxios } from "../../hook";
import { convertDate } from "../../utils/ConvertDate";
import { urlAvatar } from "../../utils/Constant";
import { validateArray, validateStatus } from "../../utils/Validate";
import { connect } from "react-redux";
import DateView from "react-datepicker";
import { Link } from "react-router-dom";
import {
  TotalProcedures,
  ChannelAssignment,
  ClearanceCustoms,
  ProceduresObserved,
} from "../../containers/clientResume";
const ClientResume = (props) => {
  const {
    sesionUser,
    match: { params },
  } = props;
  const today = new Date();
  const [filters, setfilters] = useState({
    year: today,
  });
  const { response, loading, error, reload } = useAuthAxios({
    method: "GET",
    url: `/company/${params.id}/GetCompanyByUser`,
  });

  useEffect(() => {
    if (!loading) {
      if (response && response.id !== params.id) {
        reload();
      }
    }
  }, [params]);

  function onChange(e, name) {
    setfilters((a) => ({
      ...a,
      [name]: e,
    }));
  }
  return (
    <div className="ClientResume">
      <br />
      <BoxFlex>
        <div
          className="ClientResume__img"
          style={{
            backgroundImage: `url("${
              sesionUser.user.photoUrl ? sesionUser.user.photoUrl : urlAvatar
            }")`,
          }}
        ></div>
        <TextH3 fontWeight="600" fontSize="3rem" color="#252E48">
          Hola {sesionUser.user.name},
          <br />
          Bienvenido
        </TextH3>
      </BoxFlex>
      {loading ? (
        <Loading />
      ) : !validateStatus(error) ? (
        <TextH3
          color="var(--white)"
          textAlign="center"
          background="var(--red)"
          width="fit-content"
          padding="10px"
          borderRadius="10px"
          margin="0 auto"
        >
          No estas asociado a un cliente
        </TextH3>
      ) : (
        <CardBox
          title="Información de la empresa"
          vertical
          component={
            <>
              <BoxFlex justifyContent="space-between" flexWrap="wrap">
                <div className="">
                  <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
                    Nombre de la empresa:{" "}
                    <span style={{ color: "#5F5961", width: "100%" }}>
                      {response.razonSocial}
                    </span>
                  </TextH3>
                  <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
                    Fecha de creación:{" "}
                    <span style={{ color: "#5F5961", width: "100%" }}>
                      {convertDate(response.creationDate, "LLLL")}
                    </span>
                  </TextH3>
                  <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
                    NIT:{" "}
                    <span style={{ color: "#5F5961", width: "100%" }}>
                      {response.nit}
                    </span>
                  </TextH3>
                </div>
                {response.logoUrl && (
                  <div>
                    <img
                      style={{
                        height: "100px",
                        border: "1px solid var(--gray)",
                        borderRadius: "10px",
                      }}
                      src={response.logoUrl}
                      alt="logotipo"
                    />
                  </div>
                )}
              </BoxFlex>
            </>
          }
        />
      )}
      <br />
      <div
        className="Management__filters"
        style={{ justifyContent: "start", gap: "10px" }}
      >
        <TextH3 fontWeight="500" color="#252E48" margin="0 0 5px">
          Trámites del año:
        </TextH3>
        <div className="DatePicker form-control Management__year">
          <DateView
            locale="es"
            id="year"
            name="year"
            selected={filters.year}
            showYearPicker
            maxDate={new Date()}
            dateFormat="yyyy"
            onChange={(date) => onChange(date, "year")}
          />
        </div>
      </div>
      <br />
      <AmountPaperwork
        cid={params.id}
        filter={filters.year.getFullYear() + ""}
      />
      <br />
      <br />
      <StatisticsContainer
        {...props}
        cid={params.id}
        filter={filters.year.getFullYear() + ""}
      />
      <br />
      <br />
      <UserPerCompany cid={params.id} />
    </div>
  );
};

const AmountPaperwork = ({ cid, filter }) => {
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: "/proceduretype",
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <BoxFlex justifyContent="space-around" gap="15px">
        {validateArray(response) &&
          response.map((item) => (
            <AmountProcedures
              key={item.id}
              item={item}
              filter={filter}
              cid={cid}
            />
          ))}
      </BoxFlex>
    );
  }
};

const AmountProcedures = (props) => {
  const { item, cid, filter } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/procedureStats/for-cards?procedureTypeId=${item.id}&internCode=${filter}&companyId=${cid}`,
  });

  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [filter, cid]);

  return (
    <CardBox
      vertical
      title={item.name}
      justifyContent="center"
      component={
        loading ? (
          <Loading />
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            to={`/list-procedure/${cid}?import=${item.id}`}
          >
            <TextH3
              color="#465ffd"
              fontSize="3.5rem"
              fontWeight="700"
              textAlign="center"
            >
              {response.amount}
            </TextH3>
            <TextH3 fontSize="2rem" color="var(--gray)" textAlign="center">
              Trámites
            </TextH3>
          </Link>
        )
      }
    />
  );
};

const UserPerCompany = ({ cid }) => {
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/company/${cid}/ClientsCompany`,
  });

  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [cid]);

  return (
    <CardBox
      title="Usuarios habilitados"
      vertical
      component={
        loading ? (
          <Loading />
        ) : (
          <BoxFlex flexWrap="wrap" gap="10px 20px" padding="10px 0">
            {validateArray(response) &&
              response.map((user) => (
                <BoxFlex key={user.id}>
                  <div
                    style={{
                      backgroundImage: `url("${
                        user.photoUrl ? user.photoUrl : urlAvatar
                      }")`,
                      backgroundSize: "contain",
                    }}
                    className="ClientResume__imgUsers"
                  ></div>
                  <div className="">
                    <TextH3
                      fontWeight="500"
                      fontSize="1.8rem"
                      color="#252E48"
                      margin="0"
                    >
                      {user.name} {user.fatherLastName} {user.motherLastName}
                    </TextH3>
                    <TextH3 margin="0" fontWeight="500" color="#5F5961">
                      {user.email}
                    </TextH3>
                    <TextH3 margin="0" fontWeight="500" color="var(--gray)">
                      Ultima conexión: {convertDate(user.lastActive, "lll")}
                    </TextH3>
                  </div>
                </BoxFlex>
              ))}
          </BoxFlex>
        )
      }
    />
  );
};
const StatisticsContainer = ({ cid, filter, history }) => {
  const year = filter.substring(filter.length - 2, filter.length);

  return (
    <div className="StatisticsContainer">
      <BoxFlex gap="15px" alignItems="flex-start">
        <TotalProcedures cid={cid} year={year} history={history} />
        <ChannelAssignment cid={cid} year={year} history={history} />
      </BoxFlex>
      <br />
      <BoxFlex gap="15px" alignItems="flex-start">
        <ProceduresObserved cid={cid} year={year} history={history} />
        <ClearanceCustoms cid={cid} year={year} history={history} />
      </BoxFlex>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, null)(ClientResume);

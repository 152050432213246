import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { useAuthAxios } from "../../hook";
import Loading from "../animation/Loading";
import { createOption } from "../../utils/ProcessData";
import { validateArray } from "../../utils/Validate";
import Select from "react-select";
import { useEffect } from "react";

const SelectGet = (props) => {
  const {
    label,
    name,
    url,
    keyOption,
    value,
    nameList,
    upload,
    valueInput,
    ...rest
  } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: url,
  });

  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [url]);

  function onChange(value) {
    upload((a) => ({
      ...a,
      [name]: value.value,
    }));
  }

  function handlePlaceholder(valueInitial) {
    const listAux = nameList ? response[nameList] : response;

    if (valueInitial && validateArray(listAux)) {
      const OptionFind = listAux.find(
        (element) => element[value || "value"] === valueInitial
      );
      if (OptionFind) {
        return OptionFind[keyOption];
      } else {
        return value;
      }
    }

    return value ? value : label ? label : "Seleccionar...";
  }

  if (loading) {
    return <Loading />;
  } else if (
    response &&
    validateArray(nameList ? response[nameList] : response)
  ) {
    return (
      <div className="form-control">
        <label htmlFor={name}>{label}</label>
        <div className="FormControl__select">
          <Select
            isSearchable={true}
            onChange={onChange}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1.6rem",
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1.5rem",
              }),
              menuList: (baseStyles) => ({
                ...baseStyles,
                border: "1px solid gray",
                borderRadius: "5px",
                maxHeight: "150px",
              }),
              placeholder: (base) => ({
                ...base,
                color: "black",
                fontSize: "1.6rem",
              }),
            }}
            placeholder={handlePlaceholder(valueInput[name])}
            isOptionSelected={false}
            options={createOption(
              nameList ? response[nameList] : response,
              label,
              keyOption,
              value
            )}
            {...rest}
            // options={options
            //   .filter((option) => option.value !== "")
            //   .sort((a, b) => (a.value || a.key) - (b.value || b.key))
            //   .map((option) => ({
            //     ...option,
            //     label: option.key,
            //   }))}
            // isDisabled={field.lockEdition}
          />
        </div>
        {/* <Field as="select" id={name} name={name} {...rest}>
          <RenderOption
            options={createOption(
              nameList ? response[nameList] : response,
              label,
              keyOption,
              value
            )}
          />
        </Field> */}
        <ErrorMessage component={TextError} name={name} />
      </div>
    );
  } else {
    return null;
  }
};

export default SelectGet;

import AddNotification from './AddNotification'
const ListNotification = (props) => {
  const { onCancel } = props
  // function handleAddNotification() {
  //   changeModal(
  //     true,
  //     <AddNotification
  //       {...props}
  //       onCancel={() =>
  //         changeModal(true, <ListNotification {...props} />, true)
  //       }
  //     />,
  //     false,
  //   )
  // }

  return <AddNotification {...props} onCancel={onCancel} />

  // return (
  //   <>
  //     <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
  //       <BoxFlex>
  //         <GlobalButton onClick={onCancel} padding="0 10px" width="fit-content">
  //           <i className="fas fa-angle-left"></i>
  //         </GlobalButton>
  //         <TextH2
  //           fontSize="2rem"
  //           fontWeight="600"
  //           margin="0"
  //           textAlign="center"
  //         >
  //           {title}
  //         </TextH2>
  //       </BoxFlex>
  //       <GlobalButton
  //         onClick={handleAddNotification}
  //         width="fit-content"
  //         background="#052691"
  //         color="#e2e4f3"
  //       >
  //         Adicionar notificación
  //       </GlobalButton>
  //     </BoxFlex>
  //     <br />
  //     {users.map((user) => (
  //       <TextH3>
  //         {user.name} {user.fatherLastName} {user.motherLastName}
  //       </TextH3>
  //     ))}
  //   </>
  // )
}

export default ListNotification

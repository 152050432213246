import { TableFilters } from "../../components/table";
import { GlobalButton } from "../../components/buttons";
import { BoxFlex } from "../../components/boxes";
import { FormDefault } from "../../components/forms";
import { TextH2, TextH3 } from "../../components/Text";
import { connect } from "react-redux";
import { useState } from "react";
import { changeModal, openSnackbar } from "../../store/actions";
import { GenerateRoute } from "../../containers/procedure";
import { validateStatus } from "../../utils/Validate";
import { inputReportHistory } from "../../utils/FormFields";
import { postAction } from "../../services/actions/AuthAction";

const HistoryProcedures = (props) => {
  const {
    match: { params },
    changeModal,
    openSnackbar,
  } = props;

  function handleAction(procedure) {
    changeModal(
      true,
      <GenerateRoute
        {...props}
        procedure={{
          ...procedure,
          id: procedure.procedureId,
          procedureTypeId: parseInt(params.id),
        }}
      />,
      false,
      "900px"
    );
  }

  function handleHistory(procedure) {
    changeModal(
      true,
      <ProcedureHistory
        {...props}
        procedure={{ ...procedure, id: procedure.procedureId }}
      />,
      false,
      "900px"
    );
  }
  async function onHandleReport() {
    changeModal(true, <FormReport {...props} />, true, "450px");
  }

  return (
    <>
      <BoxFlex justifyContent="space-between">
        <TextH2 fontSize="2rem" color="var(--blue)">
          Historial de Trámites
        </TextH2>
        <GlobalButton
          onClick={onHandleReport}
          color="#e2e4f3"
          background="var(--blue)"
          width="auto"
        >
          Generar reporte del historial
        </GlobalButton>
      </BoxFlex>
      <br />
      <TableFilters
        params={`/procedure/procedureType/${params.id}`}
        functionClick={handleHistory}
        header={[
          { key: "internCode", name: "Código de interno" },
          { key: "company", name: "Compañía", type: "text" },
          { key: "orderNumber", name: "Número de pedido" },
          { key: "dimNumber", name: "Número de DIM o DEX" },
          { key: "billNumber", name: "Número de factura" },
          { key: "step", name: "Proceso actual", type: "text" },
          {
            key: handleAction,
            name: "",
            textBtn: "Hoja de ruta",
            type: "action",
          },
        ]}
        filterBoxes={[
          {
            type: "getSelect",
            label: "Todas las compañías",
            name: "companyId",
            url: "/company/getallcompanies",
            urlName: "id",
            urlLabel: "razonSocial",
          },
        ]}
      />
    </>
  );
};

const FormReport = (props) => {
  const { changeModal, openSnackbar } = props;
  const [load, setload] = useState(false);

  function getParams(value, params) {
    let paramStr = "";
    params.forEach((param) => {
      paramStr = paramStr + (value[param] ? `&${param}=${value[param]}` : "");
    });

    return paramStr;
  }

  async function onSubmit(value) {
    setload(true);
    const params = getParams(value, [
      "IdCompany",
      "Type",
      "SheetTime",
      "SheetData",
    ]);
    const querys = `?Date=${value.Date.getFullYear()}${params}`;
    await postAction(`/procedure/Reports/Trazabilidad${querys}`).then((res) => {
      setload(false);
      if (validateStatus(res.status)) {
        changeModal(
          true,
          <>
            <TextH3 fontSize="1.8rem">Enlace del documento:</TextH3>
            <TextH3 textAlign="center">
              <a href={res.data.url} target="_blank" rel="noopener noreferrer">
                Descargar
              </a>
            </TextH3>
          </>,
          true,
          "300px"
        );
        window.open(res.data.url, "_blank");
        openSnackbar("Reporte generado", true, true);
      } else {
        openSnackbar("No se pudo generar el reporte", true, false);
      }
    });
  }

  return (
    <div style={{ overflowY: "hidden" }}>
      <FormDefault
        load={load}
        title="Formulario para generar el reporte"
        textBtn="Generar"
        InputsJson={inputReportHistory}
        data={{}}
        onSubmit={onSubmit}
        onCancel={() => changeModal(false, null)}
      />
    </div>
  );
};

const ProcedureHistory = (props) => {
  const { procedure } = props;
  return (
    <>
      <TableFilters
        params={`/procedure/procedureHistory/${procedure.id}`}
        functionClick={() => {}}
        header={[
          { key: "user", name: "Encargado" },
          { key: "company", name: "Compañía", type: "text" },
          { key: "step", name: "Proceso actual", type: "text" },
          {
            key: "action",
            name: "Acción del trámite",
            type: "textTranslated",
            translate: {
              create: "Iniciado",
              update: "Actualizado",
            },
          },
          {
            key: "stepAction",
            name: "Acción en el paso",
            type: "textTranslated",
            translate: {
              startStep: "Iniciado",
              updateStep: "Editado",
              finishStep: "Completado",
            },
          },
          { key: "date", name: "Fecha", type: "date" },
        ]}
      />
    </>
  );
};
export default connect(null, { changeModal, openSnackbar })(HistoryProcedures);

import { useEffect } from "react";
import { useAuthAxios } from "../../hook";
import { TextH3 } from "../../components/Text";
import { CardBox } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { GraphicsContainer } from "../../components/graphics";
import { Link } from "react-router-dom";

const ChannelAssignment = ({ cid, year, history }) => {
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/clientStats/companies/${cid}/perChannel?internCode=${year}`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [year]);

  function onClick(item) {
    history.push(`/list-procedure/${cid}?Color=${item.label}`);
  }

  const TextLabel = ({ title, children, to }) => {
    return (
      <Link style={{ textDecoration: "none" }} to={to}>
        <TextH3
          width="max-content"
          fontWeight="500"
          textAlign="start"
          color="var(--white)"
        >
          {title}
          <br />
          <span style={{ fontSize: "2.2rem" }}>{children}</span>
        </TextH3>
      </Link>
    );
  };

  return (
    <CardBox
      headerComponent={
        !loading && (
          <>
            <TextLabel
              to={`/list-procedure/${cid}?Color=Canal Verde`}
              title="Canal verde"
            >
              {response.green}
            </TextLabel>
            <TextLabel
              to={`/list-procedure/${cid}?Color=Canal Amarillo`}
              title="Canal amarillo"
            >
              {response.yellow}
            </TextLabel>
            <TextLabel
              to={`/list-procedure/${cid}?Color=Canal Rojo`}
              title="Canal rojo"
            >
              {response.red}
            </TextLabel>
          </>
        )
      }
      component={
        loading ? (
          <Loading />
        ) : (
          <>
            <TextH3
              textTransform="uppercase"
              fontWeight="700"
              textAlign="center"
              color="#465ffd"
            >
              Asignación de canal
            </TextH3>
            <GraphicsContainer
              size="small"
              type="doughnut"
              onClick={onClick}
              data={[
                {
                  label: "Canal Verde",
                  color: "#7DF506",
                  data: response.green,
                  percentage: Math.ceil(
                    (100 * response.green) /
                      (response.green + response.yellow + response.red)
                  ),
                },
                {
                  label: "Canal Amarillo",
                  color: "#F4E206",
                  data: response.yellow,
                  percentage: Math.ceil(
                    (100 * response.yellow) /
                      (response.green + response.yellow + response.red)
                  ),
                },
                {
                  label: "Canal Rojo",
                  color: "#F52306",
                  data: response.red,
                  percentage: Math.ceil(
                    (100 * response.red) /
                      (response.green + response.yellow + response.red)
                  ),
                },
              ]}
            />
          </>
        )
      }
    />
  );
};

export default ChannelAssignment;

import Input from "./Input";
import SelectInput from "./SelectInput";
import Textarea from "./Textarea";
import SelectGet from "./SelectGet";
import GetCheckbox from "./GetCheckbox";
import RadioButtons from "./RadioButtons";
import CheckboxGroup from "./CheckboxGroup";
import DropzoneInput from "./DropzoneInput";
import DatePicker from "./DatePicker";
import CheckboxDescription from "./CheckboxDescription";
import DescriptionCheck from "./DescriptionCheck";
import InputArray from "./InputArray";

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "hidden":
      return null;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <SelectInput {...rest} />;
    case "selectGet":
    case "getSelect":
      return <SelectGet {...rest} />;
    case "getCheckbox":
      return <GetCheckbox {...rest} />;
    case "radio":
      return <RadioButtons {...rest} />;
    case "checkboxGroup":
      return <CheckboxGroup {...rest} />;
    case "checkbox":
      return <RadioButtons {...rest} />;
    case "checkboxDescription":
      return <CheckboxDescription {...rest} />;
    case "descriptionCheck":
      return <DescriptionCheck {...rest} />;
    case "file":
      return <DropzoneInput {...rest} />;
    // case 'date':
    //   return <DatePicker {...rest} />

    case "getListOption":
      return <InputArray {...rest} />;
    case "datePicker":
      return <DatePicker {...rest} />;
    default:
      return <Input {...rest} />;
  }
}

export default FormikControl;

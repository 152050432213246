import { GlobalButton } from '../buttons'
import { Link } from 'react-router-dom'
import './style/Forms.css'
import { TextH1, TextH2 } from '../Text'
import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import * as Yup from 'yup'
import { postAction } from '../../services/actions/Action'
import Loading from '../animation/Loading'
import { openSnackbar } from '../../store/actions'
import { validateStatus } from '../../utils/Validate'
import { connect } from 'react-redux'
import { useState } from 'react'
const Recover = (props) => {
  const [load, setload] = useState(true)
  const initialValues = {
    UsernameOrEmail: '',
  }
  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required('Campo Obligatorio'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/recoveryAccount', values).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          props.openSnackbar('Enviado revisa tu correo', true, true)
        } else {
          props.openSnackbar('El usuario no existe', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="log_in" style={{ width: '80%', margin: '0 auto' }}>
      <TextH1 fontSize="2.4rem" textAlign="center" color="var(--blue)">
        Recuperar Contraseña
      </TextH1>
      <TextH2>Cambiar contraseña</TextH2>
      <TextH2>
        Indica tu correo electronico y sigue las instrucciones ques se envian
        por correo.
      </TextH2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Correo Electronico o Usuario"
                name="UsernameOrEmail"
              />
              {load ? (
                <center>
                  <GlobalButton type="submit">Enviar correo</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <center>
        <Link className="link_style" to="/auth/login">
          Volver al inicio de sesión
        </Link>
      </center>
    </div>
  )
}

export default connect(null, { openSnackbar })(Recover)

import styled from 'styled-components'
const BoxFlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  @media screen and (max-width: 425px) {
    flex-direction: ${(props) => props.direction};
    gap: 5px;
  }
`
const BoxFlex = (props) => {
  const {
    children,
    justify = 'center',
    gap = '10px',
    direction = 'column',
    wrap = 'wrap',
  } = props
  return (
    <BoxFlexStyle direction={direction} justify={justify} gap={gap} wrap={wrap}>
      {children}
    </BoxFlexStyle>
  )
}

export default BoxFlex

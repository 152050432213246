import { TextH2, TextH3 } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { GlobalButton } from '../../components/buttons'
import { TableDefault } from '../../components/table'
import Loading from '../../components/animation/Loading'
import { useAuthAxios } from '../../hook'
import {
  validateArray,
  validateStatus,
  validateRol,
} from '../../utils/Validate'
import { convertDate } from '../../utils/ConvertDate'
import { postAction, putAction } from '../../services/actions/AuthAction'
import { useState } from 'react'
import './GenerateRoute.css'
import { FormDefault } from '../../components/forms'
import { validateForm, documentForOffice } from '../../utils/FormFields'
import { RenderModal } from '../../components/modal'
import { openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'

const GenerateRoute = (props) => {
  const {
    procedure,
    editBoolean = true,
    openSnackbar,
    sesionUser: { rols },
  } = props

  const rolBoolean = editBoolean ? validateRol(rols, ['admin', 'files']) : false
  const [load, setload] = useState(false)
  const { response, loading, error, reload } = useAuthAxios({
    method: 'POST',
    url: `/files/HojaDeRutePreview/${procedure.id}`,
  })

  async function generatePDF() {
    setload(true)
    await postAction(`/files/HojaDeRuteGen/${procedure.id}`).then(
      (response) => {
        setload(false)
        if (validateStatus(response.status)) {
          window.open(response.data, '_blank')
        }
      },
    )
  }
  async function readFilesList() {
    setload(true)
    await postAction(`/files/DataDim/${procedure.id}`, {}).then((response) => {
      setload(false)
      if (validateStatus(response.status)) {
        reload()
      }
    })
  }
  async function readDIMRegularized() {
    setload(true)
    const result = await readDIM(procedure, 'dimRegularizada')
    if (validateStatus(result.readStatus)) {
      openSnackbar('DIM regularizada leída', true, true)
      setload(false)
      reload()
    } else {
      setload(false)
      openSnackbar(
        result.readData ? result.readData : 'No se pudo guardar',
        true,
        false,
      )
    }
  }
  async function readDIM(procedure, name) {
    const resultConvertDIM = await putAction(
      `/files/convertPdfToCsv/${procedure.id}`,
      {
        name: name,
      },
    ).then((res) => {
      return { readStatus: res.status, readData: res.data }
    })

    const resultDataDIM = await postAction(`/files/DataDim/${procedure.id}`, {
      name: name,
    }).then((res) => {
      return { readStatus: res.status, readData: res.data }
    })

    if (validateStatus(resultConvertDIM.readStatus)) {
      if (validateStatus(resultDataDIM.readStatus)) {
        return resultConvertDIM
      } else {
        return resultDataDIM
      }
    } else {
      return resultConvertDIM
    }
  }
  return (
    <>
      <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
        <TextH2 color="var(--blue)" fontWeight="600" fontSize="2.2rem">
          Hoja de ruta y registro de documentos
        </TextH2>
        <BoxFlex>
          {load || loading ? (
            <Loading />
          ) : (
            <>
              {procedure.procedureTypeId && procedure.procedureTypeId === 3 ? (
                <GlobalButton
                  margin="0"
                  onClick={readDIMRegularized}
                  width="fit-content"
                  background="var(--blue)"
                  color="#e2e4f3"
                >
                  Leer DIM regularizada
                </GlobalButton>
              ) : null}
              {!validateRol(rols, ['admin', 'files']) ? null : response &&
                validateArray(response.filesList) ? (
                <>
                  <GlobalButton
                    margin="0"
                    onClick={readFilesList}
                    width="fit-content"
                    background="var(--blue)"
                    color="#e2e4f3"
                  >
                    Volver a leer datos de la DIM/DEX
                  </GlobalButton>
                  <GlobalButton
                    margin="0"
                    onClick={generatePDF}
                    width="fit-content"
                  >
                    Exportar en PDF
                  </GlobalButton>
                </>
              ) : null}
            </>
          )}
        </BoxFlex>
      </BoxFlex>
      <br />
      {loading ? (
        <Loading />
      ) : validateStatus(error) ? (
        response && validateArray(response.filesList) ? (
          <TableRutePreview
            {...props}
            reload={reload}
            response={response}
            procedure={procedure}
            rolBoolean={rolBoolean}
          />
        ) : (
          <>
            <TextH2 textAlign="center" color="var(--blue)">
              Faltan extraer algunos datos tiene que volver a leer la DIM/DEX
            </TextH2>
            <center>
              <GlobalButton
                margin="0"
                onClick={readFilesList}
                width="fit-content"
                background="var(--blue)"
                color="#e2e4f3"
              >
                Leer datos restantes de la DIM/DEX
              </GlobalButton>
            </center>
          </>
        )
      ) : (
        <TextH2 textAlign="center" color="var(--blue)">
          No se pudo generar la hoja de ruta
        </TextH2>
      )}
    </>
  )
}

const TableRutePreview = (props) => {
  const { response, rolBoolean } = props
  const [modal, setmodal] = useState({
    state: false,
    field: null,
  })

  const handleEdit = (data, typeForm = null) => {
    setmodal({
      state: !modal.state,
      field: { ...data, typeForm: typeForm },
    })
  }
  function TableItem({ title, data, type }) {
    if (response && response[data] && response[data] !== 'null') {
      return (
        <TextH3 color="" fontSize="1.8rem">
          <span style={{ color: 'var(--blue)' }}>
            {title.charAt(0) + title.substring(1, title.length).toLowerCase()}
          </span>
          :{' '}
          <span
            style={{
              color: 'var(--gray)',
              padding: '0 5px 2px',
              borderRadius: '5px',
            }}
          >
            {type === 'date'
              ? convertDate(response[data], 'LLLL')
              : response[data]}
          </span>
        </TextH3>
      )
    } else {
      return null
    }
  }

  return (
    <div className="TableRutePreview">
      <TableItem title="Fecha" data="date" type="date" />
      <TableItem title="NOMBRE IMPORTADOR / EXPORTADOR" data="razonSocial" />
      <TableItem title="Nº INTERNO" data="intern" />
      <TableItem title="Nº DE REGISTRO DIM" data="numeroDim" />
      <TableItem title="ADUANA" data="aduana" />
      <TableItem title="CANAL" data="canal" />
      <TableItem title="FECHA DE PAGO" data="fechaDePago" />
      <TableItem title="Duración total" data="totalDuration" />
      <br />
      <TextH2 textAlign="center" color="var(--blue)">
        REQUERIMIENTOS DE CLIENTE
      </TextH2>
      <TableItem title="IMPORTACIÓN" data="tipoDeImportacion" />
      <TableItem title="EXPORTACIÓN" data="tipoDeExportacion" />
      {response && validateArray(response.filesList) ? (
        <>
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            DOCUMENTO PARA EL DESPACHO
          </TextH2>
          <TableDefault
            functionClick={
              rolBoolean ? (data) => handleEdit(data, 'document') : () => {}
            }
            header={[
              { key: 'name', name: 'TIPO DE DOCUMENTO' },
              { key: 'number', name: 'Nº DOCUMENTO' },
              { key: 'doc', name: 'ESTADO DOC.' },
            ]}
            main={response.filesList}
          />
        </>
      ) : null}
      {response && (
        <>
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            DOCUMENTO DE FACTURACIÓN A ARCHIVO
          </TextH2>
          <TableDefault
            functionClick={rolBoolean ? handleEdit : null}
            header={[
              { key: 'name', name: 'TIPO DE DOCUMENTO' },
              { key: 'data', name: 'Nº DOCUMENTO' },
            ]}
            main={[
              {
                name: 'Factura Vilaseca',
                fieldId: response.numeroFacturaVilasecaId,
                fieldName: 'numeroFacturaVilaseca',
                data: response.numeroFacturaVilaseca,
              },
              {
                name: 'Planilla Vilaseca',
                fieldId: response.numeroPlanillaVilasecaId,
                fieldName: 'numeroPlanillaVilaseca',
                data: response.numeroPlanillaVilaseca,
              },
              {
                name: 'Nro Contable',
                fieldId: response.numeroNotaContableId,
                fieldName: 'numeroNotaContable',
                data: response.numeroNotaContable,
              },
            ]}
          />
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            TRIBUTOS A PAGAR
          </TextH2>
          <TableDefault
            functionClick={rolBoolean ? handleEdit : null}
            header={[
              { key: 'name', name: 'TIPO' },
              { key: 'data', name: 'MONTO' },
            ]}
            main={[
              {
                name: 'GA',
                fieldId: response.gaId,
                fieldName: 'ga',
                data: response.ga,
              },
              {
                name: 'IVA',
                fieldId: response.ivaId,
                fieldName: 'iva',
                data: response.iva,
              },
              {
                name: 'ICE',
                fieldId: response.iceId,
                fieldName: 'ice',
                data: response.ice,
              },
              {
                name: 'ICED',
                fieldId: response.iceDId,
                fieldName: 'iceD',
                data: response.iceD,
              },
              {
                name: 'TOTAL',
                fieldId: response.totalId,
                fieldName: 'total',
                data: response.total,
              },
            ]}
          />
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            RECEPCIÓN DE DOCUMENTO
          </TextH2>
          <TableDefault
            functionClick={rolBoolean ? handleEdit : null}
            header={[
              { key: 'name', name: 'RESP. DE ENTREGA Y RECEPCIÓN' },
              { key: 'data', name: 'NOMBRE' },
            ]}
            main={[
              {
                name: 'LIQUIDADOR ASIGNADO',
                fieldId: response.liquidadorId,
                data: response.liquidador,
                fieldName: 'liquidador',
              },
              {
                name: 'GESTOR ASIGNADO',
                fieldId: response.gestorId,
                data: response.gestor,
                fieldName: 'gestor',
              },
            ]}
          />
          <br />
          <TableDefault
            functionClick={rolBoolean ? handleEdit : null}
            header={[
              {
                key: 'name',
                name: 'ESP. DE ENTREGA Y RECEPCIÓN',
              },
              { key: 'data', name: 'FECHA / HORA', type: 'date' },
            ]}
            main={[
              {
                name: 'IMPRESIÓN DE LA HOJA DE RUTA',
                fieldId: response.ingresoArchivoId,
                fieldName: 'ingresoArchivo',
                data: convertDate(
                  response.ingresoArchivo,
                  'YYYY-MM-DDTHH:mm:ss',
                ),
                type: 'datetime-local',
              },
              // {
              //   name: 'INGRESO DE CARPETA',
              //   fieldId: response.ingresoCarpeta,
              //   fieldName: 'ingresoCarpeta',
              //   data: convertDate(
              //     response.ingresoCarpeta,
              //     'YYYY-MM-DDTHH:mm:ss',
              //   ),
              //   type: 'datetime-local',
              // },
            ]}
          />
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            COMENTARIOS Y OBSERVACIONES
          </TextH2>
          <TableDefault
            header={[
              { key: 'obsValidation', name: 'Liquidador (Validación)' },
              { key: 'obsContravention', name: 'Liquidador (Canal)' },
              {
                key: 'obsCustomClearance',
                name: 'Gestor (Despacho de Aduana)',
              },
              { key: 'obsFacturation', name: 'Facturación (Observación)' },
              { key: 'obsFile', name: 'Archivo (Primera Observación)' },
            ]}
            main={[
              {
                obsValidation: response.obsValidation,
                obsContravention: response.obsContravention,
                obsCustomClearance: response.obsCustomClearance,
                obsFacturation: response.obsFacturation,
                obsFile: (
                  <>
                    {response.obsFileApprovalStatus
                      ? 'Se aprobó'
                      : 'No se aprobó'}
                    {response.obsFileApprovalContent && <br />}
                    {response.obsFileApprovalContent}
                  </>
                ),
              },
            ]}
          />
          <br />
          <TextH2 textAlign="center" color="var(--blue)">
            TRAZABILIDAD POR PROCESO
          </TextH2>
          <TableDefault
            header={[
              { key: 'userInChange', name: 'Encargado' },
              { key: 'stepName', name: 'Proceso' },
              {
                key: 'initialDate',
                name: 'Inicio',
                type: 'dateShort',
              },
              { key: 'finalDate', name: 'Final', type: 'dateShort' },
              { key: 'durationString', name: 'Duración' },
            ]}
            main={response.procedureHistories}
          />
        </>
      )}
      {modal.state && (
        <RenderModal
          width="500px"
          onClose={() =>
            setmodal({
              state: !modal.state,
              field: null,
            })
          }
          closeOutside
        >
          <EditData
            {...props}
            field={modal.field}
            onCancel={() =>
              setmodal({
                state: !modal.state,
                field: null,
              })
            }
          />
        </RenderModal>
      )}
    </div>
  )
}

const EditData = (props) => {
  const { field, onCancel, openSnackbar, procedure, reload } = props
  const [load, setload] = useState(false)
  async function submitDataset(value) {
    setload(true)
    await putAction(
      field.typeForm === 'document'
        ? `/files/UpdateDataDim/${field.fileId}`
        : `/dataset/editdataset/${procedure.id}/fieldid/${field.fieldId}`,

      field.typeForm === 'document'
        ? {
            Name: value.name,
            Number: value.number,
            Doc: value.doc,
          }
        : {
            FieldId: field.fieldId,
            ProcedureId: procedure.id,
            Value: value[field.fieldName],
          },
    ).then((res) => {
      setload(false)
      if (validateStatus(res.status)) {
        reload()
        onCancel()
        openSnackbar('Se guardo exitosamente', true, true)
      } else {
        openSnackbar(res.data ? res.data : 'No se pudo guardar', true, false)
      }
    })
  }
  return (
    <>
      <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
        {'Editar ' + field.name}
      </TextH2>
      <FormDefault
        load={load}
        title=""
        textBtn="Editar"
        InputsJson={
          field.typeForm
            ? documentForOffice
            : [
                {
                  name: field.fieldName,
                  initial: '',
                  validate: validateForm['text'],
                  type: field.type ? field.type : 'text',
                  label: field.name,
                },
              ]
        }
        data={
          field.typeForm
            ? field
            : {
                [field.fieldName]:
                  field.data === 'null' || field.data === '0001-01-01T00:00:00'
                    ? ''
                    : field.data,
              }
        }
        onSubmit={submitDataset}
        onCancel={onCancel}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { openSnackbar })(GenerateRoute)

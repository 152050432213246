import * as Yup from 'yup'
import { useState, Fragment } from 'react'
import FormPutDef from './FormPutDef'
import { useAuthAxios } from '../../hook'
import { TextH2, TextH3 } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { FormDefault } from '../../components/forms'
import { validateForm } from '../../utils/FormFields'
import { stringToJson } from '../../utils/ProcessData'
import { GlobalButton } from '../../components/buttons'
import Loading from '../../components/animation/Loading'
import { postAction, putAction } from '../../services/actions/AuthAction'
import {
  validateArray,
  validateStatus,
  validateUserRol,
} from '../../utils/Validate'

const FormStep = (props) => {
  const [load, setload] = useState(false)
  const {
    changeModal,
    openSnackbar,
    procedure,
    reload,
    sesionUser: { rols },
  } = props

  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=${procedure.procedureTypeId}`,
  })
  const onSubmit = async (values) => {
    setload(true)
    listRecursive(0, response.fields, values, [])
    setTimeout(() => {
      setload()
    }, 4000);
    
  }

  function listRecursive(index, fields, values, result) {
    if (index < fields.length) {
      if (fields[index].type === 'file') {
        fillData(index, fields, values, result)
      } else {
        listRecursive(index + 1, fields, values, [
          ...result,
          validateData(fields[index], values),
        ])
      }
    } else {
      submitDataset(result.filter((n) => n))
    }
  }

  function validateData(element, values) {
    if (element.type === 'getButton') {
      return null
    } else if (element.type === 'checkbox') {
      const auxBoolean = values[element.name] + ''
      return {
        Value: auxBoolean.split(';')[0] || '',
        ProcedureId: procedure.id,
        FieldId: element.id,
      }
    } else if (element.type === 'descriptionCheck') {
      return {
        Value: JSON.stringify(values[element.name]).replace(/"/g, "'") || '',
        ProcedureId: procedure.id,
        FieldId: element.id,
      }
    } else {
      return {
        Value:
          values[element.name] || values[element.name] === 0
            ? values[element.name]
            : '',
        ProcedureId: procedure.id,
        FieldId: element.id,
      }
    }
  }

  async function fillData(index, fields, values, result) {
    if (
      !fields[index].validate &&
      (values[fields[index].name] === '' ||
        values[fields[index].name] === null) &&
      (fields[index].initial === '' || fields[index].initial === null)
    ) {
      listRecursive(index + 1, fields, values, result)
    } else if (
      (values[fields[index].name] !== '' && !fields[index].initial) ||
      (values[fields[index].name] !== '' &&
        values[fields[index].name] !== fields[index].initial)
    ) {
      let formData = new FormData()
      formData.append('Name', fields[index].name)
      formData.append('File', values[fields[index].name])
      formData.append('Status', 'verified')
      formData.append('ProcedureId', procedure.id)
      postAction('/files', formData).then((res) => {
        if (validateStatus(res.status)) {
          listRecursive(index + 1, fields, values, [
            ...result,
            {
              Value: res.data.url,
              ProcedureId: procedure.id,
              FieldId: fields[index].id,
              FileId: res.data.id,
            },
          ])
        } else {
          setload(false)
          openSnackbar('No se pudo subir el archivo', true, false)
        }
      })
    } else {
      listRecursive(index + 1, fields, values, [
        ...result,
        {
          Value: fields[index].initial,
          ProcedureId: procedure.id,
          FieldId: fields[index].id,
        },
      ])
    }
  }

  async function submitDataset(value) {
    await postAction('/dataset', value).then((res) => {
      if (validateStatus(res.status)) {
        if (validateArray(value.filter((field) => field.FieldId === 11))) {
          handleRead()
        } else {
          setload(false)
          openSnackbar('Se subió exitosamente', true, true)
          changeModal(false, null)
          reload()
        }
      } else {
        openSnackbar(
          res.data ? res.data : 'No se pudo subir el archivo',
          true,
          false,
        )
      }
    })
  }

  async function handleRead() {
    await putAction(`/files/readparte/${procedure.id}`).then(async (res) => {
      if (validateStatus(res.status)) {
        await postAction(`/notification/parte/procedures/${procedure.id}`).then(
          (result) => {
            setload(false)
            if (validateStatus(result.status)) {
              openSnackbar('Se realizo la lectura exitosamente', true, true)
              changeModal(false, null)
              reload()
            } else {
              openSnackbar('Se realizo la lectura exitosamente', true, true)
              changeModal(false, null)
              reload()
            }
          },
        )
      } else {
        openSnackbar(
          res.data ? res.data : `Error al leer el parte`,
          true,
          false,
        )
      }
    })
  }

  function handlePreRender(list) {
    let auxList = []
    list.forEach((element) => {
      if (element.type !== 'getButton') {
        let init = ''
        if (element.type === 'descriptionCheck') {
          const auxInit =
            element.initial === ''
              ? { status: null, content: '' }
              : stringToJson(element.initial)
          init = auxInit ? auxInit : { status: null, content: '' }
        } else {
          init =
            element.type === 'checkbox'
              ? element.initial + ';' + element.name
              : element.initial
        }
        auxList.push({
          ...element,
          initial: init,
          control: element.type,
          validate: element.validate
            ? validateForm[element.type] || validateForm['text']
            : null,
          type: element.type === 'switch' ? 'checkbox' : element.type,
        })
      } else {
        auxList.push({
          ...element,
          control: 'hidden',
          validate: element.validate
            ? element.name === 'designacionDeLiquidador' &&
              validateUserRol(rols, 'senasag_procedure')
              ? null
              : validateForm['text']
            : null,
        })
      }
    })
    return auxList
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        {procedure.internCode && (
          <TextH2 color="var(--blue)" fontSize="1.6rem" margin="0">
            Número de interno: {procedure.internCode}
          </TextH2>
        )}
        <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
          Formulario
        </TextH2>
        <BoxFlex justifyContent="space-between" flexWrap="wrap-reverse">
          {validateArray(response.fields) &&
            response.fields.map((field) =>
              field.type === 'getButton' ? (
                <Fragment key={field.id}>
                  {field.name === 'designacionDeLiquidador' &&
                  validateUserRol(rols, 'senasag_procedure') ? null : (
                    <>
                      {field.validate && !field.initial ? (
                        <TextH3
                          margin="0"
                          fontSize="1.5rem"
                          fontWeight="700"
                          color="var(--red)"
                        >
                          <i className="fas fa-exclamation-circle"></i> Para
                          registrar el formulario "{field.label}" no debe estar
                          vació.
                        </TextH3>
                      ) : null}
                    </>
                  )}
                  <TextH3 margin="0" color="var(--gray)">
                    {field.label}:{' '}
                    <strong>{field.initial ? field.initial : '--'}</strong>
                  </TextH3>
                  <ButtonComponet {...props} field={field} />
                </Fragment>
              ) : null,
            )}
        </BoxFlex>
        <FormDefault
          load={load}
          title=""
          textBtn="Registrar"
          InputsJson={handlePreRender(response.fields)}
          data={{}}
          onSubmit={onSubmit}
          onCancel={() => changeModal(false, null)}
        />
      </>
    )
  }
}
const ButtonComponet = (props) => {
  const { field, changeModal } = props
  function handleButtonModal() {
    changeModal(
      true,
      <FormPutDef
        {...props}
        url={field.url}
        title={field.label}
        inputsJson={[
          {
            control: 'getSelect',
            initial: '',
            validate: Yup.string().required('Campo requerido'),
            label: field.label,
            name: 'user',
            url: field.url,
            keyOption: 'name',
            value: 'name',
          },
        ]}
        onCancel={() => changeModal(true, <FormStep {...props} />, false)}
      />,
      false,
    )
  }
  return (
    <GlobalButton width="fit-content" onClick={handleButtonModal}>
      {field.label}
    </GlobalButton>
  )
}
export default FormStep

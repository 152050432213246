import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { useState } from 'react'
import './style/Inputs.css'
import InputStyle from './style/InputStyle'

const Input = (props) => {
  const { label, name, disabled, type, value, ...rest } = props
  const [check, setcheck] = useState({
    typeInput: 'password',
  })
  const handleCheck = (e) => {
    const { checked } = e.target
    setcheck({
      typeInput: checked ? 'text' : 'password',
    })
  }
  return (
    <InputStyle className="form-control" type={type}>
      <label htmlFor={name}>{label}</label>
      {/* <br /> */}
      <Field
        id={name}
        name={name}
        disabled={disabled}
        type={type !== 'password' ? type : check.typeInput}
        step={type === 'time' ? '2' : null}
        className="switch"
        // value={value}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
      {type === 'password' && (
        <div className="Input__check">
          <label htmlFor="checkBox">Mostrar contraseña</label>
          <input onChange={handleCheck} type="checkbox" name="checkBox" />
        </div>
      )}
    </InputStyle>
  )
}

export default Input

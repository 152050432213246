import { connect } from 'react-redux'
import { useState } from 'react'
import { TextH3 } from '../../components/Text'
import { FormDefault } from '../../components/forms'
import { BoxFlex, CardBox } from '../../components/boxes'
import { validateForm } from '../../utils/FormFields'
import { urlAvatar } from '../../utils/Constant'
import { validateArray, validateStatus } from '../../utils/Validate'
import { useAuthAxios } from '../../hook'
import { openSnackbar } from '../../store/actions'
import { putAction } from '../../services/actions/AuthAction'
import './Account.css'
import Loading from '../../components/animation/Loading'
const Account = (props) => {
  const {
    sesionUser: { user },
    openSnackbar,
  } = props
  const [load, setload] = useState(false)
  const { response, loading, reload } = useAuthAxios({
    method: 'GET',
    url: `/users/${window.localStorage.getItem('id')}/preferences`,
  })

  async function onSubmit(values) {
    setload(true)
    const result = await Promise.all(
      response.map(async (element) => {
        if (element.value !== values[element.id]) {
          const fileContents = await putAction('/users/preference/update', {
            idPreference: element.id,
            value: values[element.id],
          }).then((res) => {
            if (!validateStatus(res.status)) {
              openSnackbar(
                res.data ? res.data : 'No se pudo guardar',
                true,
                false,
              )
            }
            return res.status
          })
          return fileContents
        } else {
          return 204
        }
      }),
    )
    const resultStatus = result.filter((status) => !validateStatus(status))
    if (!validateArray(resultStatus)) {
      openSnackbar('Se guardaron tus cambios exitosamente', true, true)
    }
    setload(false)
    reload()
  }

  function handlePreference(list) {
    const aux = list.map((preference) => ({
      name: preference.id,
      initial: preference.value,
      validate: validateForm['switch'],
      type: 'checkbox',
      label: `${
        {
          Notification: 'Enviame un correo al',
          email: 'Enviame una notificación al',
        }[preference.type]
      } ${
        {
          processStart: 'iniciar un trámite',
          liquidatorProcessEnd: 'finalizar el proceso de liquidador',
          AssignChannel: 'al asignar canal',
          BillingProcess: 'finalizar el trámite',
        }[preference.label]
      }`,
    }))
    return aux
  }
  return (
    <div className="Account">
      <BoxFlex>
        <div
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url("${
              user.photoUrl ? user.photoUrl : urlAvatar
            }")`,
          }}
          className="Account__avatar"
        ></div>
        <TextH3 margin="0" fontWeight="600" fontSize="3rem" color="#252E48">
          Hola {user.name},
          <br />
          Bienvenido
        </TextH3>
      </BoxFlex>
      <br />
      <CardBox
        title="Mis datos personales"
        vertical
        component={
          <>
            <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
              Nombres:{' '}
              <span style={{ color: '#5F5961', width: '100%' }}>
                {user.name}
              </span>
            </TextH3>
            <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
              Apellidos:{' '}
              <span style={{ color: '#5F5961', width: '100%' }}>
                {user.fatherLastName} {user.motherLastName}
              </span>
            </TextH3>
            <TextH3 fontWeight="500" fontSize="2rem" color="#252E48">
              Correo electrónico:{' '}
              <span style={{ color: '#5F5961', width: '100%' }}>
                {user.email}
              </span>
            </TextH3>
          </>
        }
      />
      <br />
      {loading ? (
        <Loading />
      ) : (
        <CardBox
          title="Mis preferencias"
          vertical
          component={
            <div className="Account__preference">
              <FormDefault
                title="*Aquí podrás elegir si quieres que te enviemos correos electrónicos y notificaciones"
                load={load}
                textBtn="Guardar"
                InputsJson={handlePreference(response)}
                data={{}}
                onSubmit={onSubmit}
              />
            </div>
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { openSnackbar })(Account)

import { FormDefault } from '../../components/forms'
import { useAuthAxios } from '../../hook'
import Loading from '../../components/animation/Loading'
import { useState } from 'react'
import { createOption } from '../../utils/ProcessData'
import { validateStatus } from '../../utils/Validate'
import { postAction } from '../../services/actions/AuthAction'
import * as Yup from 'yup'
const Formreport = (props) => {
  const {
    onClose,
    openSnackbar,
    handleReload,
    match: { params },
  } = props
  const [load, setload] = useState(false)
  const { loading: loadComp } = useAuthAxios({
    
  })

  const list = [
    {
      id:1,
      Type:"Importación"
    },
    { 
      id:2,
      Type:"Exportación"
    }]

  const onSubmit = async (values) => {
    setload(true)
    console.log(values)
    let initial = values.InitialRangeDate != null ? values.InitialRangeDate.toLocaleDateString() : "";
    let final = values.FinalRangeDate != null ? values.FinalRangeDate.toLocaleDateString() : "";
    let type = values.Tipo;
    try {
      await postAction(`/procedure/ReportAperture?Initial=${initial}&Final=${final}&Type=${type}`, {
        ...values
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          window.open(res.data.url)
          console.log(res.data.url)
          openSnackbar('Se genero el reporte', true, true)
          onClose()
        } else {
          openSnackbar('No se puedo crear', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  if (loadComp) {
    return <Loading />
  } else {
    
    return (
      <div>
        <FormDefault
          load={load}
          title="Generar Reporte"
          textBtn="Iniciar"
          InputsJson={[
            {
              name: 'InitialRangeDate',
              control: 'datePicker',
              label: 'Rango de fecha de Inicio',
              dateFormat: 'yyyy-MM-dd',
              initial: null
            },
            {
              name: 'FinalRangeDate',
              control: 'datePicker',
              label: 'Rango de fecha final',
              dateFormat: 'yyyy-MM-dd ',
              initial: null
            },
            {
              name: 'Tipo',
              initial: '',
              validate: Yup.string().required('Campo requerido'),
              control: 'select',
              label: 'Tipo de Procedimiento',
              options: createOption(list,'------','Type'),
            },
          ]}
          data={{}}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </div>
    )
  }
}
export default Formreport

import styled from 'styled-components'
const FlexContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 8px;
  display: flex;
  flex-direction: ${(props) => (props.small ? 'column' : 'row')};
  gap: 20px;
  border: 1px solid #b1b1b1;
  background: var(--white);
  padding: 15px;
  border-radius: 10px;
  & .StyledDropzone__fields {
    width: ${(props) => (props.small ? '100%' : '50%')};
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    & .StyledDropzone__fields {
      width: 100%;
    }
  }
`

export default FlexContainer

import { useState } from 'react'
import { connect } from 'react-redux'
import { useAuthAxios } from '../../hook'
import {
  postAction,
  putAction,
  addDownloadHistory,
} from '../../services/actions/AuthAction'
import { TextH2, TextH3, SubTitle } from '../../components/Text'
import { TableFilters } from '../../components/table'
import { ActionButton } from '../../components/buttons'
import { BoxFlex } from '../../components/boxes'
import { FormDefault } from '../../components/forms'
import { inputDocument } from '../../utils/FormFields'
import {
  validateArray,
  validateStatus,
  validateUserRol,
} from '../../utils/Validate'
import { Loading } from '../../components/animation'
import { openSnackbar, changeModal } from '../../store/actions'

const header = [
  { key: 'internCode', name: 'Número de interno' },
  { key: 'billNumber', name: 'Número de factura' },
  { key: 'orderNumber', name: 'Número de pedido' },
  { key: 'dimNumber', name: 'Número de DIM o DEX' },
  { key: 'companyName', name: 'Compañía', type: 'text' },
  { key: 'currentStepName', name: 'Proceso actual', type: 'text' },
]
const Documents = (props) => {
  const {
    match: { params },
    changeModal,
    sesionUser: { rols },
  } = props

  function handleClick(procedure, reload) {
    changeModal(
      true,
      <ProcedureDetail {...props} procedure={procedure} reload={reload} />,
      false,
    )
  }
  function handleReadParte(procedure, reload) {
    changeModal(
      true,
      <ReadParteContainer {...props} procedure={procedure} reload={reload} />,
      false,
    )
  }
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Trámites abiertos
      </TextH2>
      <TableFilters
        functionClick={handleClick}
        params={`/procedure/openprocedures/${params.id}`}
        header={
          validateUserRol(rols, 'liquidator')
            ? [
                ...header,
                {
                  key: handleReadParte,
                  name: 'Acciones',
                  textBtn: 'Leer parte',
                  type: 'action',
                },
              ]
            : header
        }
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </>
  )
}
const ProcedureDetail = (props) => {
  const [load, setload] = useState(false)
  const {
    procedure,
    changeModal,
    sesionUser: { location },
    openSnackbar,
  } = props
  const { response: respFiles, loading: loadFiles, reload } = useAuthAxios({
    method: 'GET',
    url: `/field/uploadedfileFields/${procedure.id}`,
  })
  async function onSubmit(value) {
    setload(true)
    let formData = new FormData()
    formData.append('Name', value.FieldId)
    formData.append('File', value.File)
    formData.append('Status', 'verified')
    formData.append('ProcedureId', procedure.id)
    const fileContents = await postAction('/files', formData).then((res) => {
      if (validateStatus(res.status)) {
        return {
          Value: res.data.url,
          ProcedureId: procedure.id,
          FieldId: parseInt(value.FieldId),
          FileId: res.data.id,
        }
      } else {
        openSnackbar('No se pudo subir el archivo', true, false)
      }
    })
    submitDataset(fileContents)
  }

  async function submitDataset(value) {
    await postAction('/dataSet/NewDataSet', [value]).then((res) => {
      setload(false)
      if (validateStatus(res.status)) {
        reload()
        openSnackbar('Se subió exitosamente', true, true)
      } else {
        openSnackbar('No se pudo subir el archivo', true, false)
      }
    })
  }
  return (
    <>
      {loadFiles ? null : validateArray(respFiles) ? (
        <>
          <TextH2
            color="var(--blue)"
            margin="0"
            fontSize="2rem"
            textAlign="center"
          >
            Archivos subidos
          </TextH2>
          <BoxFlex flexWrap="wrap" gap="5px 20px">
            {respFiles.map((file, index) => (
              <TextH3 margin="0" fontWeight="600" key={index}>
                <a
                  style={{ color: 'var(--gray)' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={file.value}
                  onClick={() =>
                    addDownloadHistory(
                      file.field.label,
                      file.value,
                      procedure.id,
                      'procedure',
                      location,
                    )
                  }
                >
                  {file?.field ? file.field.label : ''}
                </a>
              </TextH3>
            ))}
          </BoxFlex>
        </>
      ) : null}
      <br />
      {load ? (
        <Loading />
      ) : (
        <FormDefault
          load={false}
          title="Subir documento"
          textBtn="Adjuntar"
          InputsJson={inputDocument}
          data={{}}
          onSubmit={onSubmit}
          onCancel={() => changeModal(false, null)}
        />
      )}
    </>
  )
}

const ReadParteContainer = (props) => {
  const { procedure, changeModal, openSnackbar, reload } = props
  const [load, setload] = useState(true)

  async function handleRead(procedure) {
    setload(false)
    await putAction(`/files/readparte/${procedure.id}`, {}).then((res) => {
      if (validateStatus(res.status)) {
        postAction(`/notification/parte/procedures/${procedure.id}`, {}).then(
          (res) => {
            setload(true)
            if (validateStatus(res.status)) {
              openSnackbar('Se guardaron los datos exitosamente', true, true)
              changeModal(false, null)
              reload()
            } else {
              openSnackbar(
                res.data ? res.data : 'No se pudo guardar',
                true,
                false,
              )
            }
          },
        )
      } else {
        setload(true)
        openSnackbar(res.data ? res.data : 'No se pudo guardar', true, false)
      }
    })
  }

  return (
    <>
      <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
        ¿Estas seguro que quieres leer el parte de recepción?
      </TextH2>
      <SubTitle subTitle="Número de interno">{procedure.internCode}</SubTitle>
      {!load ? (
        <Loading />
      ) : (
        <ActionButton
          onClick={() => handleRead(procedure)}
          onCancel={() => changeModal(false, null)}
          textBtn="Leer"
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { openSnackbar, changeModal })(
  Documents,
)

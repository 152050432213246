// import { changeAccount } from "../actions";

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        snackbarReducer: {
          open: action.status,
          message: action.content,
          kind: action.kind,
        },
      }
    case 'CHANGE_MODAL':
      return {
        ...state,
        modalReducer: {
          status: action.status,
          children: action.children,
          width: action.width,
          closeOutside: action.closeOutside,
        },
      }
    case 'START_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: action.user,
          rols: action.rols,
          institutions: action.institutions,
          authenticate: action.status,
          rating: action.rating,
          companys: action.companys,
          location: action.location,
        },
      }
    case 'CLOSE_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: {},
          authenticate: false,
        },
      }
    default:
      return state
  }
}
export default reducer

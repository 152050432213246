import { TextH2, TextH3 } from '../../components/Text'
import { ModalConfirmation } from '../../components/modal'
import { TableFilters } from '../../components/table'
import { BoxFlex } from '../../components/boxes'
import { GlobalButton } from '../../components/buttons'
import { FormDefault } from '../../components/forms'
import { useAuthAxios } from '../../hook'
import { validateStatus } from '../../utils/Validate'
import { inputSendEmail } from '../../utils/FormFields'
import { changeModal, openSnackbar } from '../../store/actions'
import { putAction, postAction } from '../../services/actions/AuthAction'
import { connect } from 'react-redux'
import { useState } from 'react'
import { Loading } from '../../components/animation'

const EnabledUsers = (props) => {
  const { changeModal, openSnackbar } = props
  const { response, loading, reload } = useAuthAxios({
    method: 'GET',
    url: `/procedure/getReportDate`,
  })

  function handleClick(user, reload) {
    changeModal(
      true,
      <ModalConfirmation
        url={`/users/EmailReport/${user.id}`}
        textBtn={user.emailReport ? 'Deshabilitar' : 'Habilitar'}
        textSuccess="Se guardaron los cambios del usuario"
        textError="No se guardar tus cambios"
        openSnackbar={openSnackbar}
        action={putAction}
        handleSuccess={reload}
        onCancel={() => changeModal(false, null)}
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres{' '}
          {user.emailReport ? 'deshabilitar' : 'habilitar'} a {user.name}?
        </TextH2>
      </ModalConfirmation>,
      false,
      '500px',
    )
  }
  function SendEmail() {
    changeModal(
      true,
      <ModalConfirmation
        url="/procedure/Reports"
        textBtn="Enviar correo"
        textSuccess="Se enviaron los correos"
        textError="No se pudo enviar los correos"
        openSnackbar={openSnackbar}
        action={postAction}
        // handleSuccess={reload}
        onCancel={() => changeModal(false, null)}
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres el correo a los usuarios habilitados?
        </TextH2>
      </ModalConfirmation>,
      false,
      '500px',
    )
  }

  function editSendDate() {
    changeModal(
      true,
      <FormSendEmail
        data={
          response.day
            ? {
                Day: response.day,
                Hour: response.hour.split(':')[0],
              }
            : {}
        }
        reload={reload}
        onCancel={() => changeModal(false, null)}
        openSnackbar={openSnackbar}
      />,
      false,
      '500px',
    )
  }
  const dayContant = {
    '1': 'Lunes',
    '2': 'Martes',
    '3': 'Miércoles',
    '4': 'Jueves',
    '5': 'Viernes',
    '6': 'Sábado',
    '7': 'Domingo',
  }
  return (
    <>
      <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
        <TextH2 fontSize="2.2rem" color="var(--blue)" margin="0 0 10px">
          Usuarios habilitados para recibir el correo
        </TextH2>
        <GlobalButton
          width="fit-content"
          color="var(--white)"
          background="var(--blue)"
          onClick={SendEmail}
        >
          Enviar correo con el reporte
        </GlobalButton>
      </BoxFlex>
      {loading ? (
        <Loading />
      ) : (
        <BoxFlex flexWrap="wrap-reverse" margin="0 0 5px" gap="10px">
          <TextH3 margin="0">
            El correo se enviara los:{' '}
            <strong>
              {response.day
                ? `${dayContant[response.day]} a las ${response.hour}`
                : 'Aun no definido'}
            </strong>
          </TextH3>
          <GlobalButton width="fit-content" onClick={editSendDate}>
            <i className="fas fa-edit"></i>
          </GlobalButton>
        </BoxFlex>
      )}
      <TableFilters
        params="/users/ListUsers"
        addUrl={`userId=${window.localStorage.getItem(
          'id',
        )}&OrderBy=reportStatus`}
        functionClick={handleClick}
        header={[
          { key: 'name', name: 'Nombres', search: 'firstName' },
          { key: 'fatherLastName', name: 'Ap. paterno' },
          { key: 'motherLastName', name: 'Ap. materno' },
          { key: 'email', name: 'Correo electronico' },
          {
            key: 'emailReport',
            name: 'Estado',
            type: 'textBoolean',
            translate: {
              true: 'Habilitado',
              false: 'Deshabilitado',
            },
            color: {
              true: 'var(--green)',
              false: 'var(--red)',
            },
          },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todos los roles',
            name: 'RolName',
            url: '/rol',
            urlName: 'name',
            urlLabel: 'label',
          },
          //   {
          //     type: 'select',
          //     label: 'Estado',
          //     name: 'status',
          //     options: [
          //       { key: 'Activo', value: 'activo' },
          //       { key: 'Inactivo', value: 'inactivo' },
          //     ],
          //   },
        ]}
      />
    </>
  )
}

const FormSendEmail = (props) => {
  const { reload, onCancel, openSnackbar, data } = props
  const [load, setload] = useState(false)
  async function onSubmit(value) {
    await postAction('/procedure/RegisterMainReport', {
      ...value,
      Hour: `${value.Hour}:00:00`,
    }).then((res) => {
      setload(false)
      if (validateStatus(res.status)) {
        openSnackbar('Se guardo exitosamente', true, true)
        onCancel()
        reload()
      } else {
        openSnackbar(res.data ? res.data : 'No se pudo guardar', true, false)
      }
    })
  }
  return (
    <FormDefault
      load={load}
      title="Editar intervalo de envió"
      textBtn="Editar"
      InputsJson={inputSendEmail}
      data={data}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
export default connect(null, { changeModal, openSnackbar })(EnabledUsers)

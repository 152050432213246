import DateView, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import './style/RangeDate.css'
registerLocale('es', es)
const RangeDate = ({ filter, setDateRange, dateRange }) => {
  const [startDate, endDate] = dateRange

  return (
    <div className="form-control RangeDate">
      <label htmlFor="">{filter.label}:</label>
      <div className="">
        <DateView
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update)
          }}
          isClearable={true}
        />
      </div>
    </div>
  )
}

export default RangeDate

import { FormDefault } from "../../components/forms";
import { useAuthAxios } from "../../hook";
import Loading from "../../components/animation/Loading";
import { useState } from "react";
import { createOption } from "../../utils/ProcessData";
import { validateStatus } from "../../utils/Validate";
import { postAction } from "../../services/actions/AuthAction";
import * as Yup from "yup";
const FormStart = (props) => {
  const {
    onClose,
    openSnackbar,
    handleReload,
    match: { params },
  } = props;

  const [load, setload] = useState(false);
  const { response: companies, loading: loadComp } = useAuthAxios({
    method: "GET",
    url: `/company/getallcompanies`,
  });

  const onSubmit = async (values) => {
    setload(true);
    try {
      await postAction("/procedure", {
        ...values,
        ProcedureTypeId: params.tid,
        YearForInternCode: values.YearForInternCode.getFullYear(),
      }).then((res) => {
        setload(false);
        if (validateStatus(res.status)) {
          openSnackbar("Se creo con exito", true, true);
          handleReload();
          onClose();
        } else {
          openSnackbar("No se puedo crear", true, false);
        }
      });
    } catch (e) {
      setload(false);
    }
  };

  if (loadComp) {
    return <Loading />;
  } else {
    const currentDate = new Date();
    return (
      <FormDefault
        load={load}
        title="Crear un proceso"
        textBtn="Iniciar"
        InputsJson={[
          {
            name: "CompanyId",
            initial: "",
            validate: Yup.string().required("Campo requerido"),
            control: "select",
            label: "Compañía",
            options: createOption(companies, "Compañía", "razonSocial"),
          },
          {
            name: "YearForInternCode",
            initial: currentDate,
            validate: Yup.string().required("Campo requerido").nullable(),
            control: "datePicker",
            label: "Gestión",
            dateFormat: "yyyy",
            showYearPicker: true,
            minDate: new Date(currentDate.getFullYear() - 1, 1, 1),
            maxDate: new Date(currentDate.getFullYear() + 1, 1, 1),
          },
        ]}
        data={{}}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    );
  }
};
export default FormStart;

import { TextH2 } from '../../components/Text'
import { TableFilters } from '../../components/table'
import CreateLoan from '../../containers/loan/CreateLoan'
import ReturnLoans from '../../containers/loan/ReturnLoans'
import { changeModal, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'

const Loan = (props) => {
  const {
    changeModal,
    match: { params },
  } = props

  function handleClick(procedure, reload) {
    changeModal(
      true,
      <CreateLoan {...props} procedure={procedure} reload={reload} />,
      true,
      '500px',
    )
  }
  function handleLoansClick(loans, reload) {
    changeModal(
      true,
      <ReturnLoans {...props} loans={loans} reload={reload} />,
      true,
      '500px',
    )
  }

  // {
  //   control: 'getSelect',
  //   initial: '',
  //   validate: Yup.string().required('Campo requerido.'),
  //   label: 'Seleccione el tipo de procedimiento',
  //   name: 'ProcedureTypeId',
  //   url: '/proceduretype',
  //   keyOption: 'name',
  //   value: 'id',
  // },
  return (
    <>
      {params.id === '1' ? (
        <>
          <TextH2 fontSize="2rem" color="var(--blue)">
            Trámites terminados
          </TextH2>
          <br />
          <TableFilters
            params={`/loans/procedures`}
            functionClick={handleClick}
            header={[
              { key: 'internCode', name: 'Número de interno' },
              { key: 'orderNumber', name: 'Número de pedido' },
              { key: 'billNumber', name: 'Número de factura' },
              { key: 'dimNumber', name: 'Número de DIM o DEX' },
              {
                key: 'customsClearance',
                name: 'Número de aduana',
                type: 'text',
              },
              { key: 'companyName', name: 'Compañía', type: 'text' },
            ]}
            filterBoxes={[
              {
                type: 'getSelect',
                label: 'Todos los trámites',
                name: 'ProcedureTypeId',
                url: '/proceduretype',
                urlName: 'id',
                urlLabel: 'name',
              },
              // {
              //   type: 'getSelect',
              //   label: 'Todas las compañías',
              //   name: 'companyId',
              //   url: '/company/getallcompanies',
              //   urlName: 'id',
              //   urlLabel: 'razonSocial',
              // },
            ]}
          />
        </>
      ) : (
        <>
          <TextH2 fontSize="2rem" color="var(--blue)">
            Lista de trámites prestados
          </TextH2>
          <br />
          <TableFilters
            params={`/loans`}
            functionClick={handleLoansClick}
            addUrl={`type=giver&userId=${window.localStorage.getItem('id')}`}
            header={[
              { key: 'internCode', name: 'Número de interno' },
              {
                key: 'fullNameRequestingUser',
                name: 'Solicitante',
                type: 'text',
              },
              { key: 'loanDate', name: 'Fecha de registro', type: 'dateShort' },
              {
                key: 'tentativeDate',
                name: 'Fecha tentativa de la devolución',
                type: 'dateShort',
              },
              {
                key: 'conformity',
                name: 'Estado',
                type: 'textBoolean',
                translate: {
                  true: 'Entregado',
                  false: 'Prestado',
                },
                color: {
                  true: 'var(--green)',
                  false: 'var(--red)',
                },
              },
            ]}
          />
        </>
      )}
    </>
  )
}

export default connect(null, { openSnackbar, changeModal })(Loan)

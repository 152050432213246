import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { TextH2 } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { GlobalButton } from '../../components/buttons'
import { ModalConfirmation } from '../../components/modal'
import { validateStatus } from '../../utils/Validate'
import { inputContravention } from '../../utils/FormFields'
import { putAction, postAction } from '../../services/actions/AuthAction'

const FormRegularization = (props) => {
  const [load, setload] = useState(false)
  const { reload, procedure, changeModal, openSnackbar } = props
  async function onSubmit(values) {
    setload(true)
    try {
      await postAction('/procedure/validateField', {
        ...values,
        ProcedureId: procedure.procedureId,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  function handleEnlarge() {
    changeModal(
      true,
      <ModalConfirmation
        url={`/notification/procedure/${procedure.procedureId}`}
        textBtn="Ampliar"
        textSuccess="Se realizo la ampliación"
        textError="No se pudo ampliar"
        openSnackbar={openSnackbar}
        action={putAction}
        handleSuccess={reload}
        onCancel={() =>
          changeModal(true, <FormRegularization {...props} />, true, '500px')
        }
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres ampliar la fecha limite para la
          contravención?
        </TextH2>
      </ModalConfirmation>,
      false,
      '500px',
    )
  }

  return (
    <>
      <BoxFlex justifyContent="space-between">
        <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
          Formulario de regularización
        </TextH2>
        {!procedure.ampliacion && (
          <GlobalButton onClick={handleEnlarge} width="fit-content">
            Ampliar regularización
          </GlobalButton>
        )}
      </BoxFlex>
      <FormDefault
        load={load}
        textBtn="Guardar"
        InputsJson={inputContravention}
        data={{
          interCode: procedure.codeOfRegularization ?? '',
          DimDexCode: procedure.dimDexNumber ?? '',
        }}
        onSubmit={onSubmit}
        onCancel={() => changeModal(false, null)}
      />
    </>
  )
}

export default FormRegularization

import { BoxFlex, ToolTip } from '../boxes'
import { TextH3 } from '../Text'
import { validateArray } from '../../utils/Validate'
const Bar = (props) => {
  const { data, onClick = () => {} } = props
  // const dataMax = data
  //   .map((item) => item.data)
  //   .reduce((prev, curr) => prev + curr, 0)
  const dataMax = Math.max(...data.map((item) => item.data))
  return (
    <BoxFlex justifyContent="space-around">
      {validateArray(data) &&
        data.map((item, index) => (
          <div key={index}>
            <ToolTip
              content={
                <>
                  <TextH3 margin="0" minWidth="fit-content" color="var(--gray)">
                    {item.label}
                  </TextH3>
                  <TextH3 margin="0" minWidth="fit-content" color="var(--gray)">
                    Cantidad: <strong>{item.data}</strong>
                  </TextH3>
                  <TextH3 margin="0" minWidth="fit-content" color="var(--gray)">
                    Porcentaje:{' '}
                    <strong>{`${
                      dataMax === 0
                        ? 0
                        : Math.ceil((100 * item.percentage) / dataMax)
                    }%`}</strong>
                  </TextH3>
                </>
              }
            >
              <div
                onClick={() => onClick(item)}
                style={{
                  height: '70px',
                  background: 'rgba(128, 128, 128, 0.1)',
                  width: '10px',
                  margin: '0 1px',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    height: `${
                      dataMax === 0 ? 0 : (100 * item.percentage) / dataMax
                    }%`,
                    background: item.color,
                    width: '10px',
                    borderRadius: '10px',
                  }}
                ></div>
              </div>
            </ToolTip>
            {/* <TextH3 color="var(--gray)">{item.label}</TextH3> */}
          </div>
        ))}
    </BoxFlex>
  )
}

export default Bar

import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { validateStatus, validateArray } from '../../utils/Validate'
import { capitalizeJson } from '../../utils/ProcessData'
import { inputsClient } from '../../utils/FormFields'
import { putAction } from '../../services/actions/AuthAction'
const FormUpdateCompany = (props) => {
  const [load, setload] = useState(false)
  const { onCancel, handleReload, company, openSnackbar } = props
  async function onSubmit(values) {
    setload(true)
    try {
      await putAction(`/company`, {
        ...values,
        Id: company.id,
        legalRepresentatives: values.LegalRepresentative,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          handleReload()
          onCancel()
          openSnackbar('Se guardo exitosamente', true, true)
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  return (
    <FormDefault
      load={load}
      title="Editar compañía"
      textBtn="Guardar"
      // InputsJson={inputUpdateCompany}
      InputsJson={inputsClient}
      data={
        validateArray(company.legalRepresentatives)
          ? {
              ...capitalizeJson(company),
              LegalRepresentative: company.legalRepresentatives[0].name,
            }
          : capitalizeJson(company)
      }
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default FormUpdateCompany

import { TextH2 } from '../../components/Text'
import { TableFilters } from '../../components/table'

const SurveyHistory = () => {
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Lista de ingreso al enlace de encuesta
      </TextH2>
      <br />
      <TableFilters
        params="/files/DownloadHistoryList"
        functionClick={() => {}}
        addUrl="type=poll"
        addQuery={[{ key: 'user', name: 'Nombre del usuario' }]}
        header={[
          { key: 'name', name: 'Nombre del usuario', type: 'text' },
          { key: 'email', name: 'Correo electrónico' },
          {
            key: 'type',
            name: 'Tipo',
            type: 'textTranslated',
            translate: {
              company: 'Compañía',
              user: 'Usuario',
              procedure: 'Procedimiento',
              poll: 'Enlace de encuesta',
            },
          },
          { key: 'creationDate', name: 'Fecha', type: 'dateShort' },
          { key: 'location', name: 'Ubicación', type: 'text' },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </>
  )
}
export default SurveyHistory

import { useState } from 'react'
import { inputUploadPhoto } from '../../utils/FormFields'
import { validateStatus } from '../../utils/Validate'
import { FormDefault } from '../../components/forms'
import { postAction } from '../../services/actions/AuthAction'
const FormUploadImg = (props) => {
  const { openSnackbar, url, name, onCancel, title } = props
  const [load, setload] = useState(false)
  async function onSubmit(values) {
    setload(true)
    let formData = new FormData()
    formData.append('Name', name)
    formData.append('File', values.File)
    try {
      await postAction(url, formData).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se subió exitosamente', true, true)
          onCancel()
        } else {
          openSnackbar('No se pudo subir el archivo', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  return (
    <FormDefault
      load={load}
      title={title}
      textBtn="Guardar"
      InputsJson={inputUploadPhoto}
      data={{}}
      onSubmit={onSubmit}
      onCancel={() => onCancel()}
    />
  )
}
export default FormUploadImg

export const validateStatus = (state) => {
  const status = [200, 201, 202, 203, 204]
  if (status && status.indexOf(state) > -1) {
    return true
  }
  return false
}

export const validateUserRol = (list, rolName) => {
  const result = list.filter((r) => r.name === rolName)
  return validateArray(result)
}
export const validateRol = (list, listAd) => {
  const result = validateArray(list)
    ? list.filter((item) => {
        if (listAd.indexOf(item.name) > -1) {
          return item
        }
      })
    : []
  return validateArray(result)
}
export const validateBoolean = (state) => {
  const status = [true, false]
  if (status && status.indexOf(state) > -1) {
    return true
  }
  return false
}
export const validateArray = (list) => {
  if (list && list.length > 0) {
    return true
  }
  return false
}

import "./ManageForms.css";
import { connect } from "react-redux";
import { useAuthAxios } from "../../hook";
import { TypeInput } from "../../utils/Constant";
import { validateArray } from "../../utils/Validate";
import { TextH2, TextH3 } from "../../components/Text";
import {
  NewStep,
  FieldForm,
  FieldAddForm,
  FormEditStep,
  ManageFields,
  RecoverSteps,
  FieldOrderForm,
  FormDeleteStep,
  FieldFormDelete,
} from "../../containers/manageForms";
import { GlobalButton } from "../../components/buttons";
import { BoxFlex } from "../../components/boxes";
import Loading from "../../components/animation/Loading";
import { openSnackbar, changeModal } from "../../store/actions";
import { useEffect } from "react";
const ManageForms = (props) => {
  const {
    changeModal,
    match: { params },
  } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/processstep/GetProcessStepsByProcedureTypeId/${params.id}`,
  });

  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [params.id]);
  function onClose() {
    reload();
    changeModal(false, null);
  }
  function handleNewStep() {
    changeModal(true, <NewStep {...props} reload={reload} />, false);
  }
  function handleOrderStep() {
    changeModal(
      true,
      <FieldOrderForm
        {...props}
        id="id"
        url={`/processstep/order/${params.id}`}
        name="name"
        order="order"
        reload={reload}
        fields={response}
        onClose={onClose}
      />,
      false
    );
  }
  function handleManageFields() {
    changeModal(
      true,
      <ManageFields {...props} reload={reload} />,
      false,
      "800px"
    );
  }
  function handleRecoverSteps() {
    changeModal(true, <RecoverSteps {...props} reload={reload} />, true);
  }

  return (
    <>
      <div className="ManageForms__header">
        <TextH2 fontSize="2rem" color="var(--blue)">
          Proceso del flujo
        </TextH2>
        <div className="ManageForms__btns">
          <GlobalButton
            onClick={handleManageFields}
            width="fit-content"
            background="transparent"
            color="#052691"
          >
            Gestionar campos
          </GlobalButton>
          <GlobalButton
            onClick={handleOrderStep}
            width="fit-content"
            background="transparent"
            color="#052691"
          >
            Ordenar pasos
          </GlobalButton>
          <GlobalButton
            onClick={handleRecoverSteps}
            width="fit-content"
            background="#e2e4f3"
            color="#052691"
          >
            Recuperar pasos
          </GlobalButton>
          <GlobalButton
            onClick={handleNewStep}
            width="fit-content"
            background="#052691"
            color="#e2e4f3"
          >
            Nuevo proceso
          </GlobalButton>
        </div>
      </div>
      <br />
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response
          .sort((a, b) => a.step - b.step)
          .map((step) => (
            <FormSteps key={step.id} step={step} reload={reload} {...props} />
          ))
      ) : (
        <TextH3>No tienes pasos en el flujo</TextH3>
      )}
    </>
  );
};
const FormSteps = (props) => {
  const { step, changeModal, reload } = props;
  function handleOpenStep() {
    changeModal(true, <OpenStepForm {...props} />, true, "600px");
  }
  function handleEditStep() {
    changeModal(
      true,
      <FormEditStep {...props} field={step} reload={reload} />,
      true,
      "600px"
    );
  }
  function handleDeleteStep() {
    changeModal(
      true,
      <FormDeleteStep {...props} field={step} reload={reload} />,
      true,
      "600px"
    );
  }
  return (
    <div className="FormSteps">
      <div className="FormSteps__card" onClick={handleOpenStep}>
        <TextH3 fontSize="2rem" color="var(--blue)" margin="0">
          {step.step}
        </TextH3>
        <TextH3 margin="0" color="var(--gray)">
          {step.name}
        </TextH3>
      </div>
      <GlobalButton
        onClick={handleEditStep}
        width="fit-content"
        background="#e2e4f3"
        color="#052691"
        border="none"
      >
        <i className="fas fa-edit"></i>
      </GlobalButton>
      <GlobalButton
        onClick={handleDeleteStep}
        width="fit-content"
        background="#c93f3f1a"
        color="var(--red)"
        border="none"
      >
        <i className="fas fa-trash"></i>
      </GlobalButton>
    </div>
  );
};
const OpenStepForm = (props) => {
  const { step, changeModal } = props;
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: `/processstep/${step.id}`,
  });
  function handleField() {
    changeModal(
      true,
      <FieldForm
        {...props}
        field={null}
        onClose={() =>
          changeModal(true, <OpenStepForm {...props} />, true, "600px")
        }
      />,
      false
    );
  }
  function handleAddField() {
    changeModal(
      true,
      <FieldAddForm
        {...props}
        onClose={() =>
          changeModal(true, <OpenStepForm {...props} />, true, "600px")
        }
      />,
      false,
      "900px"
    );
  }
  function handleOrderField() {
    changeModal(
      true,
      <FieldOrderForm
        {...props}
        fields={response.fields}
        onClose={() =>
          changeModal(true, <OpenStepForm {...props} />, true, "600px")
        }
      />,
      false
    );
  }
  return (
    <>
      <div className="OpenStepForm__header">
        <BoxFlex justifyContent="flex-end">
          <GlobalButton
            onClick={handleAddField}
            width="fit-content"
            background="transparent"
            color="#052691"
          >
            Adicionar campo
          </GlobalButton>
          <GlobalButton
            onClick={handleOrderField}
            width="fit-content"
            background="transparent"
            color="#052691"
          >
            Ordenar campos
          </GlobalButton>
          <GlobalButton
            onClick={handleField}
            width="fit-content"
            background="#052691"
            color="#e2e4f3"
          >
            Nuevo campo
          </GlobalButton>
        </BoxFlex>
        <br />
        <TextH3 fontSize="2rem" fontWeight="600" color="var(--blue)" margin="0">
          {step.name}
        </TextH3>
      </div>
      <br />
      {loading ? (
        <Loading />
      ) : validateArray(response.fields) ? (
        response.fields.map((field) => (
          <FieldCard {...props} field={field} key={field.id} />
        ))
      ) : (
        <TextH3>No tienes campos</TextH3>
      )}
    </>
  );
};
const FieldCard = (props) => {
  const { field, changeModal } = props;
  function handleField() {
    changeModal(
      true,
      <FieldForm
        {...props}
        field={field}
        onClose={() =>
          changeModal(true, <OpenStepForm {...props} />, true, "600px")
        }
      />,
      false
    );
  }
  function handleDelete() {
    changeModal(
      true,
      <FieldFormDelete
        {...props}
        field={field}
        onClose={() =>
          changeModal(true, <OpenStepForm {...props} />, true, "600px")
        }
      />,
      false
    );
  }
  return (
    <div className="FieldCard">
      <div className="FieldCard__main">
        <TextH3
          color="var(--blue)"
          fontSize="1.8rem"
          textAlign="end"
          margin="0"
        >
          {TypeInput[field.type] || field.type}
        </TextH3>
        {field.validate && (
          <TextH3
            color="var(--red)"
            fontSize="1.6rem"
            textAlign="end"
            margin="0"
          >
            Campo requerido
          </TextH3>
        )}
        <TextH3
          margin="0"
          fontWeight="600"
          fontSize="1.8rem"
          color="var(--gray)"
        >
          {field.label}
        </TextH3>
      </div>
      <div className="FieldCard__option">
        <i className="fas fa-edit" onClick={handleField}></i>
        <i className="fas fa-trash iconRed" onClick={handleDelete}></i>
      </div>
    </div>
  );
};
export default connect(null, { openSnackbar, changeModal })(ManageForms);

import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
// import "./style/Textarea.css";

function Textarea(props) {
  const { label, name, ...rest } = props
  return (
    <div className="Textarea">
      <label className="Textarea__label" htmlFor="cambiando">
        {label}
      </label>
      <div style={{ height: '5px' }}></div>
      <Field
        as="textarea"
        className="Textarea__input"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
      <br />
      <br />
    </div>
  )
}

export default Textarea

import { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { TextH3 } from '../Text'

function CheckboxDescription(props) {
  const { label, name, upload, initial, validate, ...rest } = props
  const [option, setoption] = useState({
    status:
      typeof initial === 'object' && typeof initial.status === 'boolean'
        ? initial.status
        : '',
    content: initial.content,
  })
  function handleOption(e) {
    const { name: nameOption } = e.target
    upload((a) => ({
      ...a,
      [name]: {
        ...a[name],
        status: nameOption === `${name}true`,
      },
    }))
    setoption((a) => ({
      ...a,
      status: nameOption === `${name}true`,
    }))
  }
  function handleDescription(e) {
    const { value } = e.target
    upload((a) => ({
      ...a,
      [name]: {
        ...a[name],
        content: value,
      },
    }))
    setoption((a) => ({
      ...a,
      content: value,
    }))
  }
  return (
    <div
      style={{
        border: '1px solid #b1b1b1',
        padding: '10px 0 0 10px',
        borderRadius: '10px',
        marginBottom: '5px',
        width: '96%',
      }}
      className="Textarea"
    >
      <label className="Textarea__label">{label}</label>
      <div className="RadioButtons">
        <div className="RadioButtons__itemContainer">
          <div className="RadioButtons__item">
            <input
              type="radio"
              name={`${name}true`}
              id={`${name}true`}
              onChange={handleOption}
              checked={option.status === true}
            />
            <label htmlFor={`${name}true`}>Si</label>
          </div>
          <div className="RadioButtons__item">
            <input
              type="radio"
              name={`${name}false`}
              id={`${name}false`}
              onChange={handleOption}
              checked={option.status === false}
            />
            <label htmlFor={`${name}false`}>No</label>
          </div>
        </div>
      </div>
      <ErrorMessage component={TextError} name={name} subtitle="status" />
      {option.status === false ? (
        <>
          <label className="Textarea__label" htmlFor="cambiando">
            Descripción
          </label>
          <Field
            {...rest}
            as="textarea"
            className="Textarea__input"
            id={`${name}-content`}
            name={`${name}-content`}
            value={option.content}
            onChange={handleDescription}
          />
        </>
      ) : (
        <TextH3 color="var(--gray)" opacity="0.6" margin=" 0 5px 0 0">
          {option.content}
        </TextH3>
      )}
      <ErrorMessage component={TextError} name={name} subtitle="content" />
      <br />
      <br />
    </div>
  )
}

export default CheckboxDescription

import { useState, useEffect } from 'react'
import { TableHeader, FilterBoxes } from './'
import TableFiltersContainer from './TableFiltersContainer'
import { validateArray } from '../../utils/Validate'
import { withRouter } from 'react-router-dom'
const TableFilters = (props) => {
  const {
    header,
    params,
    addUrl,
    handleReload,
    order = null,
    filterName = '',
    filter = null,
    addQuery = [],
    filterBoxes,
    location,
    paramsDownload,
  } = props

  const searchParams = new URLSearchParams(location.search)
  const headerSelector = generteHeader(header)
  function generteHeader(header) {
    let aux = []
    header.forEach((element) => {
      if (element && (!element.type || element.type === 'textAction')) {
        aux.push(element)
      }
    })
    return [...aux, ...addQuery]
  }
  const [filters, setfilters] = useState('')
  const [load, setload] = useState(true)
  const [url, seturl] = useState({
    PageNumber: 1,
    OrderBy: order ? order[0].key : '',
    [filterName]: '',
  })
  const [search, setsearch] = useState({
    search: searchParams.get('TypeQuery') ? searchParams.get('TypeQuery') : '',
    field: searchParams.get('ForQuery')
      ? searchParams.get('ForQuery')
      : validateArray(headerSelector)
      ? headerSelector[0].search || headerSelector[0].key
      : '',
  })

  useEffect(() => {
    setsearch({
      search: searchParams.get('TypeQuery')
        ? searchParams.get('TypeQuery')
        : '',
      field: searchParams.get('ForQuery')
        ? searchParams.get('ForQuery')
        : validateArray(headerSelector)
        ? headerSelector[0].search || headerSelector[0].key
        : '',
    })
  }, [header])

  function onChange(e) {
    const { name, value } = e.target
    setsearch((a) => ({ ...a, [name]: value }))
  }
  const [urlParam, seturlParam] = useState(
    params +
      `?PageNumber=${url.PageNumber}` +
      `${url.OrderBy ? '&OrderBy=' + url.OrderBy : ''}` +
      `${url[filterName] ? `&${filterName}=${url[filterName]}` : ''}` +
      `${addUrl ? '&' + addUrl : ''}` +
      `${
        search?.search
          ? '&ForQuery=' + search.field + '&TypeQuery=' + search.search
          : ''
      }` +
      `${filters && filters}`,
  )

  useEffect(() => {
    const urlAux =
      params +
      `?PageNumber=${url.PageNumber}` +
      `${url.OrderBy ? '&OrderBy=' + url.OrderBy : ''}` +
      `${url[filterName] ? `&${filterName}=${url[filterName]}` : ''}` +
      `${addUrl ? '&' + addUrl : ''}` +
      `${
        search?.search
          ? '&ForQuery=' + search.field + '&TypeQuery=' + search.search
          : ''
      }` +
      `${filters && filters}`

    let interval
    if (urlAux !== urlParam) {
      if (load === true) {
        seturlParam(urlAux)
        setload(false)
        interval = setTimeout(() => {
          setload(true)
        }, 10)
      }
    }
    return () => {
      clearInterval(interval)
    }
  }, [url, params, addUrl, filters])

  function onSearch() {
    seturl((a) => ({ ...a, PageNumber: 1 }))
  }

  useEffect(() => {
    if (typeof handleReload === 'boolean') {
      setload(false)
      setTimeout(() => {
        setload(true)
      }, 10)
    }
  }, [handleReload])

  function reload() {
    if (load === true) {
      setload(false)
      setTimeout(() => {
        setload(true)
      }, 10)
    }
  }

  return (
    <>
      <TableHeader
        search={search}
        onChange={onChange}
        setsearch={setsearch}
        options={headerSelector}
        onSearch={onSearch}
        order={order ? order : null}
        seturl={seturl}
        url={url}
        filterName={filterName}
        filter={
          filter
            ? [{ name: 'Seleccione un rol:', key: '', id: 0 }, ...filter]
            : filter
        }
        urlParam={urlParam}
        paramsDownload={paramsDownload}
      />
      <FilterBoxes
        filterBoxes={filterBoxes}
        setfilters={setfilters}
        filters={filters}
        seturl={seturl}
      />
      <br />
      {load && (
        <TableFiltersContainer
          {...props}
          url={url}
          seturl={seturl}
          reload={reload}
          filters={filters}
          urlParam={urlParam}
        />
      )}
    </>
  )
}

export default withRouter(TableFilters)

import { useState } from 'react'
import { TextH2 } from '../../components/Text'
import GraphicsFilters from './GraphicsFilters'
import { RenderModal } from '../../components/modal'
import { TableFilters } from '../../components/table'
import { getAction } from '../../services/actions/AuthAction'
import { validateStatus, validateUserRol } from '../../utils/Validate'

const ProcedureInProcess = (props) => {
  const { filters, handleDate, changeModal } = props

  async function handleClickPending(process, params) {
    getAction(`/procedureStats/listRolsStep/${process.step}`).then((res) => {
      if (validateStatus(res.status)) {
        if (
          validateUserRol(res.data, 'liquidator') ||
          validateUserRol(res.data, 'customs_manager')
        ) {
          handlePendingPerUser(process, params)
        }
      }
    })
  }

  function handlePendingPerUser(process, params) {
    changeModal(
      true,
      <PendingPerUser
        {...props}
        params={params}
        process={process}
        filters={filters}
        handleDate={handleDate}
      />,
      true,
      '900px',
    )
  }

  return (
    <GraphicsFilters
      type="bar"
      url="/procedureStats/in-process?"
      filters={filters}
      getFilters={handleDate}
      typeData="list"
      title="Trámites pendientes"
      name="stepName"
      label="step"
      stringAdd="Proc. "
      onClick={handleClickPending}
      params={[
        {
          type: 'getSelect',
          label: 'Todas las compañías',
          name: 'companyId',
          url: '/company/getallcompanies',
          urlName: 'id',
          urlLabel: 'razonSocial',
        },
        {
          type: 'select',
          label: 'Departamento',
          name: 'Department',
          options: [
            { key: 'La Paz', value: 'La Paz' },
            { key: 'Santa Cruz', value: 'Santa Cruz' },
            { key: 'Chuquisaca', value: 'Chuquisaca' },
            { key: 'Cochabamba', value: 'Cochabamba' },
            { key: 'Oruro', value: 'Oruro' },
            { key: 'Potosí', value: 'Potosí' },
            { key: 'Tarija', value: 'Tarija' },
            { key: 'Beni', value: 'Beni' },
            { key: 'Pando', value: 'Pando' },
          ],
        },
        {
          type: 'select',
          label: 'Filtrar por regional de oficina',
          name: 'CityUser',
          options: [
            { key: 'La Paz', value: 'La Paz' },
            { key: 'Santa Cruz', value: 'Santa Cruz' },
            { key: 'Chuquisaca', value: 'Chuquisaca' },
            { key: 'Cochabamba', value: 'Cochabamba' },
            { key: 'Oruro', value: 'Oruro' },
            { key: 'Potosí', value: 'Potosí' },
            { key: 'Tarija', value: 'Tarija' },
            { key: 'Beni', value: 'Beni' },
            { key: 'Pando', value: 'Pando' },
          ],
        },
        {
          type: 'getSelect',
          label: 'Tipo de procedimiento',
          name: 'procedureTypeId',
          url: '/proceduretype',
          urlName: 'id',
          urlLabel: 'name',
        },
      ]}
    />
  )
}

const PendingPerUser = (props) => {
  const { process, filters, handleDate, params } = props
  const search = new URLSearchParams(params)
  const [modal, setmodal] = useState(null)
  function handleClickStepUser(user, params) {
    setmodal({ user: user, params: params })
  }

  return (
    <>
      {modal && (
        <RenderModal onClose={() => setmodal(null)} closeOutside>
          <ListStepPerUser {...props} user={modal.user} params={modal.params} />
        </RenderModal>
      )}
      <TextH2 textAlign="center" fontSize="2.2rem" color="var(--blue)">
        Trámites pendientes de {process.name}
      </TextH2>
      <GraphicsFilters
        type="bar"
        url={`/procedureStats/listamount/${process.step}?`}
        filters={filters}
        getFilters={handleDate}
        preProcess={(item) => item.split(' ')[0]}
        typeData="list"
        name="user"
        label="user"
        onClick={handleClickStepUser}
        params={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
            initial: search.get('companyId'),
          },
          {
            type: 'select',
            label: 'Departamento',
            name: 'Department',
            options: [
              { key: 'La Paz', value: 'La Paz' },
              { key: 'Santa Cruz', value: 'Santa Cruz' },
            ],
            initial: search.get('Department'),
          },
          {
            type: 'select',
            label: 'Filtrar por regional de oficina',
            name: 'CityUser',
            options: [
              { key: 'La Paz', value: 'La Paz' },
              { key: 'Santa Cruz', value: 'Santa Cruz' },
              { key: 'Chuquisaca', value: 'Chuquisaca' },
              { key: 'Cochabamba', value: 'Cochabamba' },
              { key: 'Oruro', value: 'Oruro' },
              { key: 'Potosí', value: 'Potosí' },
              { key: 'Tarija', value: 'Tarija' },
              { key: 'Beni', value: 'Beni' },
              { key: 'Pando', value: 'Pando' },
            ],
            initial: search.get('CityUser'),
          },
          {
            type: 'getSelect',
            label: 'Tipo de procedimiento',
            name: 'procedureTypeId',
            url: '/proceduretype',
            urlName: 'id',
            urlLabel: 'name',
            initial: search.get('procedureTypeId'),
          },
        ]}
      />
    </>
  )
}

const ListStepPerUser = (props) => {
  const { user, process, params } = props
  const search = new URLSearchParams(params)
  return (
    <>
      <TextH2 fontSize="2.5rem" color="var(--blue)">
        Trámites pendientes de {user.name} en el {process.label}
      </TextH2>
      <TableFilters
        params={`/procedureStats/listProcedureForUser/${process.step}/user/${user.userId}`}
        header={[
          { key: 'internCode', name: 'Número de interno', type: 'text' },
          { key: 'company', name: 'Compañía', type: 'text' },
          { key: 'billNumber', name: 'Nro. de factura', type: 'text' },
          { key: 'dimNumber', name: 'Número de DIM o DEX', type: 'text' },
          { key: 'orderNumber', name: 'Número de pedido', type: 'text' },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
            initial: search.get('companyId'),
          },
          {
            type: 'select',
            label: 'Departamento',
            name: 'Department',
            options: [
              { key: 'La Paz', value: 'La Paz' },
              { key: 'Santa Cruz', value: 'Santa Cruz' },
            ],
            initial: search.get('Department'),
          },

          {
            type: 'getSelect',
            label: 'Tipo de procedimiento',
            name: 'procedureTypeId',
            url: '/proceduretype',
            urlName: 'id',
            urlLabel: 'name',
            initial: search.get('procedureTypeId'),
          },
        ]}
      />
    </>
  )
}
export default ProcedureInProcess

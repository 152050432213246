const TextH1 = (props) => {
  const { children, textAlign = 'left', color, fontSize = '1.6rem' } = props
  return (
    <h1
      style={{ textAlign: textAlign, color: color, fontSize: fontSize }}
      className="TextH1"
    >
      {children}
    </h1>
  )
}
export default TextH1

import { TextH2, TextH3 } from '../../components/Text'
import { ActionButton } from '../../components/buttons'
import Loading from '../../components/animation/Loading'
import { validateStatus } from '../../utils/Validate'
import { putAction } from '../../services/actions/AuthAction'
import { useState } from 'react'
const FormDeleteStep = (props) => {
  const { field, openSnackbar, changeModal, reload } = props
  const [load, setload] = useState(false)
  async function onSubmit() {
    setload(true)
    try {
      await putAction(`/processstep/deleteProcessStep/${field.id}`, {}).then(
        (res) => {
          setload(false)
          if (validateStatus(res.status)) {
            openSnackbar('Se guardo exitosamente', true, true)
            changeModal(false, null)
            reload()
          } else {
            openSnackbar('No se pudo guardar', true, false)
          }
        },
      )
    } catch (e) {
      setload(false)
    }
  }
  return (
    <div>
      <TextH2 fontSize="2rem" textAlign="center">
        Estas seguro que quieres <strong>eliminar</strong> este paso:
      </TextH2>
      <TextH3
        fontSize="2rem"
        color="var(--blue)"
        textAlign="center"
        fontWeight="600"
      >
        {field.name}
      </TextH3>
      <br />
      {load ? (
        <Loading />
      ) : (
        <ActionButton
          onClick={onSubmit}
          onCancel={() => changeModal(false, null)}
          textBtn="Eliminar"
        />
      )}
    </div>
  )
}

export default FormDeleteStep

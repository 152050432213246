import { validateArray } from '../../utils/Validate'
import { TextH2 } from '../../components/Text'
import DataType from './DataType'
const CellMobile = ({
  cell,
  id,
  handleActivate,
  header,
  activate,
  handleAddHistory,
  filters,
}) => {
  return (
    <>
      <div className="TableDefault__container">
        <div
          className={`TableDefault__cell ${
            activate === id ? 'TableDefault__cell-activate' : ''
          }`}
        >
          {validateArray(header)
            ? header.map((a, i) => {
                return (
                  <div
                    onClick={
                      a.type === 'action' ||
                      a.type === 'textAction' ||
                      a.type === 'listActions'
                        ? () => {}
                        : () => handleActivate(i, cell)
                    }
                    className="TableDefault__column"
                    key={i}
                  >
                    <TextH2 fontWeight="700" className="TableDefault__head">
                      {a.name}
                    </TextH2>
                    <DataType
                      a={a}
                      head={cell}
                      handleAddHistory={handleAddHistory}
                      filters={filters}
                    />
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <hr />
    </>
  )
}

export default CellMobile

import { FormDefault } from '../../components/forms'
import { useState } from 'react'
import { validateStatus } from '../../utils/Validate'
import { inputAddNotify } from '../../utils/FormFields'
import { postAction } from '../../services/actions/AuthAction'
const AddNotification = (props) => {
  const { onCancel, users, openSnackbar, titleForm } = props
  const [load, setload] = useState(false)

  async function handleOnSubmit(values) {
    setload(true)
    try {
      const result = await Promise.all(
        users.map(async (user) => {
          const notifyResult = await postAction(`/Notification/send`, {
            ...values,
            UserId: user.id,
          }).then((res) => {
            setload(false)
            return {
              status: res.status,
              user: `${user.name} ${user.fatherLastName} ${user.motherLastName}`,
            }
          })
          return notifyResult
        }),
      )
      let aux = false
      result.forEach((item) => {
        if (validateStatus(item.status)) {
          aux = true
        } else {
          openSnackbar(`No se pudo enviar a ${item.data}`, true, false)
        }
      })
      if (aux) {
        openSnackbar('Se guardo exitosamente', true, true)
        onCancel()
      }
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  return (
    <FormDefault
      load={load}
      title={titleForm}
      // title={`Enviar notificación a ${user.name}`}
      textBtn="Guardar"
      InputsJson={inputAddNotify}
      data={{}}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
    />
  )
}

export default AddNotification

import './Doughnut.css'
import { validateArray } from '../../utils/Validate'
const Doughnut = (props) => {
  const { data, type } = props
  let count = 0
  return (
    <div className="Doughnut">
      {type !== 'pie' && <div className="Doughnut__center"></div>}
      {validateArray(data) &&
        data.map((item, index) => {
          count = count + (index > 0 ? data[index - 1].percentage * 4 : 0)
          return (
            <div
              key={index}
              style={{
                background: `conic-gradient(${item.color || 'var(--blue)'} ${
                  item.percentage
                }%, transparent ${item.percentage}%)`,
                transform: `rotate(${count}grad )`,
              }}
              className="Doughnut__item"
            ></div>
          )
        })}
    </div>
  )
}
export default Doughnut

import React from 'react'
import { TextH2, TextH3 } from '../../components/Text'
import { TableFilters } from '../../components/table'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'

const ProcessesStopped = (props) => {
  const {
    match: { params },
  } = props

  function handleDay(item, name, filters) {
    const days = 60 - parseInt(item[name])
    return (
      <TextH3
        background={
          parseInt(days) > 30
            ? 'var(--green)'
            : parseInt(days) > 20
            ? 'var(--green)'
            : 'var(--red)'
        }
        margin="0"
        padding="2px 10px 5px"
        borderRadius="15px"
        width="fit-content"
        color="var(--white)"
      >
        {filters.includes('Regularization=true')
          ? 'Regularizado'
          : `${days} / 60`}
      </TextH3>
    )
  }

  return (
    <div>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Lista de trámites en abandono importación
      </TextH2>
      <br />
      <TableFilters
        params="/procedure/stop"
        addUrl={
          params.type === 'only'
            ? `userId=${window.localStorage.getItem('id')}`
            : ''
        }
        header={[
          { key: 'interNumber', name: 'Número de interno' },
          { key: 'company', name: 'Compañía', type: 'text' },
          { key: 'billNumber', name: 'Número de factura' },
          { key: 'orderNumber', name: 'Número de pedido' },
          {
            key: 'assignedLiquidator',
            name: 'Liquidador asignado',
            type: 'text',
          },
          {
            key: 'parteDate',
            name: 'Fecha de recepción del parte',
            type: 'text',
          },
          {
            key: 'days',
            name: 'Días para regularizar',
            type: 'validate',
            validate: handleDay,
          },
          {
            key: 'parteDateAdd',
            name: 'Fecha de vencimiento',
            type: 'text',
          },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
          {
            type: 'select',
            label: 'Sin regularizar',
            name: 'Regularization',
            options: [{ key: 'Regularizados', value: 'true' }],
          },
        ]}
      />
    </div>
  )
}

export default connect(null, { changeModal })(ProcessesStopped)

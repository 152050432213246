import { Loading } from '../animation'
import { ActionButton } from '../buttons'
import { validateStatus } from '../../utils/Validate'
import { useState } from 'react'
import { deleteAction } from '../../services/actions/AuthAction'
const ModalConfirmation = (props) => {
  const {
    textBtn,
    children,
    onCancel,
    url,
    textSuccess,
    textError = 'No se pudo eliminar',
    openSnackbar,
    action = deleteAction,
    handleSuccess = () => {},
  } = props
  const [load, setload] = useState(true)

  async function handleOnSubmit() {
    setload(false)
    await action(url).then((res) => {
      setload(true)
      if (validateStatus(res.status)) {
        openSnackbar(textSuccess, true, true)
        onCancel()
        handleSuccess()
      } else {
        openSnackbar(res.data ? res.data : textError, true, false)
      }
    })
  }

  return (
    <>
      {children}
      {!load ? (
        <Loading />
      ) : (
        <ActionButton
          onClick={handleOnSubmit}
          onCancel={onCancel}
          textBtn={textBtn}
        />
      )}
    </>
  )
}

export default ModalConfirmation

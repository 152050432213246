import { TableFilters } from '../../components/table'
import { headerUsers } from '../../utils/TableFields'

const ListUser = (props) => {
  const { handleClick, rolStatus = false, addUrl = '' } = props
  return (
    <div>
      <TableFilters
        params="/users/ListUsers"
        addUrl={`userId=${window.localStorage.getItem('id')}${addUrl}`}
        functionClick={handleClick}
        header={headerUsers}
        filterBoxes={
          rolStatus
            ? [
                {
                  type: 'getSelect',
                  label: 'Todos los roles',
                  name: 'RolName',
                  url: '/rol',
                  urlName: 'name',
                  urlLabel: 'label',
                },
              ]
            : [
                {
                  type: 'getSelect',
                  label: 'Todas las compañías',
                  name: 'companyId',
                  url: '/company/getallcompanies',
                  urlName: 'id',
                  urlLabel: 'razonSocial',
                },
              ]
        }
      />
    </div>
  )
}

export default ListUser

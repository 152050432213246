const TextH2 = (props) => {
  const {
    children,
    color,
    fontSize = '1.6rem',
    margin = '0 0 10px',
    fontWeight = '500',
    textAlign,
    onClick,
  } = props
  return (
    <h2
      onClick={onClick}
      style={{
        textAlign: textAlign,
        color: color,
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
      }}
      className="TextH2"
    >
      {children}
    </h2>
  )
}
export default TextH2

import { useState } from "react";
import { validateStatus } from "../../utils/Validate";
import { capitalizeJson } from "../../utils/ProcessData";
import { inputEditProcedure } from "../../utils/FormFields";
import { FormDefault } from "../../components/forms";
import { putAction } from "../../services/actions/AuthAction";
const EditProcedure = (props) => {
  const { reload, onCancel, openSnackbar, procedure } = props;
  const [load, setload] = useState(false);
  async function onSubmit(value) {
    await putAction("/procedure", {
      id: procedure.id,
      ...value,
    }).then((res) => {
      setload(false);
      if (validateStatus(res.status)) {
        openSnackbar("Se guardo exitosamente", true, true);
        onCancel();
        reload();
      } else {
        openSnackbar(res.data ? res.data : "No se pudo guardar", true, false);
      }
    });
  }

  return (
    <FormDefault
      load={load}
      title="Editar trámite"
      textBtn="Editar"
      InputsJson={inputEditProcedure}
      data={capitalizeJson(procedure)}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};
export default EditProcedure;

import "./GraphicsFilters.css";
import { useEffect, useState } from "react";
import { useAuthAxios } from "../../hook";
import { TextH3 } from "../../components/Text";
import { validateArray } from "../../utils/Validate";
import { Loading } from "../../components/animation";
import { GraphicsContainer } from "../../components/graphics";
import { FilterBoxes } from "../../components/table";
const GraphicsFilters = (props) => {
  const {
    type,
    url,
    filters,
    getFilters,
    title,
    typeData,
    name,
    label,
    stringAdd,
    params: listParams,
    preProcess,
    onClick,
  } = props;
  const [params, setparams] = useState("");
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `${url}${getFilters()}${params}`,
  });

  useEffect(() => {
    if (!loading) {
      if (filters !== undefined && response !== undefined) {
        reload();
      }
    }
  }, [filters]);

  useEffect(() => {
    if (!loading) {
      if (response !== undefined) {
        reload();
      }
    }
  }, [params]);

  function handleData(data) {
    if (typeData === "unique") {
      const amount = Math.ceil((100 * data.amount) / data.total);
      return [
        {
          label: "Total",
          color: "#80808039",
          data: data.total,
          percentage: 100,
        },
        {
          label: title,
          color: "var(--blue)",
          data: data.amount,
          percentage: amount,
        },
      ];
    } else if (typeData === "list") {
      let aux = [];
      data.forEach((element) => {
        aux.push({
          ...element,
          label: `${stringAdd ? stringAdd : ""} ${
            preProcess ? preProcess(element[label]) : element[label]
          }`,
          name: element[name],
          color: `var(--blue)`,
          data: element.amount,
          percentageCalc: element.percentage,
          percentage: element.amount,
        });
      });
      return aux;
    }
    return [];
  }

  return (
    <div className="GraphicsFilters">
      <div className="GraphicsFilters__header">
        <TextH3 margin="0" color="var(--blue)" fontSize="2rem">
          {title}
        </TextH3>
        <FilterBoxes
          filterBoxes={listParams}
          setfilters={setparams}
          filters={params}
          seturl={() => {}}
        />
      </div>
      <div className="GraphicsFilters__main">
        {loading ? (
          <Loading />
        ) : validateArray(response) ? (
          <GraphicsContainer
            size="small"
            type={type}
            params={params}
            onClick={onClick}
            data={handleData(response)}
          />
        ) : (
          <>
            <TextH3
              textAlign="center"
              margin="10px 0 0"
              color="var(--blue)"
              fontSize="2rem"
            >
              No hay datos
            </TextH3>
            <br />
          </>
        )}
      </div>
    </div>
  );
};

export default GraphicsFilters;

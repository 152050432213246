import './Auth.css'
import { Switch, Route } from 'react-router-dom'
import { Login, Recover, PasswordRecovery } from '../../components/forms'
const Auth = () => {
  return (
    <div className="Auth">
      <div className="login">
        <div className="login_view">
          <div className="login_view_img">
            <img
              className="login_imgCertificate"
              alt="Certificados"
              src="https://res.cloudinary.com/sistemavilaseca/image/upload/v1663777131/Assets/CERTIFICADOS_kgq4fb.png"
            />
            <img
              className="login_imgLogo"
              alt="Logotipo"
              src="https://res.cloudinary.com/sistemavilaseca/image/upload/v1663777128/Assets/logotipo-png_qgoq3w.png"
            />
          </div>
          <div className="login_view_form">
            <Switch>
              <Route exact path="/auth/login" component={Login} />
              <Route exact path="/auth/recover" component={Recover} />
              <Route
                path="/auth/passwordrecovery/:verifyKey"
                component={PasswordRecovery}
              />
              <Route component={Login} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Auth

import { validateArray, validateStatus } from '../../utils/Validate'
import { convertDate, daysMonth } from '../../utils/ConvertDate'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { TextH3 } from '../../components/Text'
import { postAction, putAction } from '../../services/actions/AuthAction'
import * as Yup from 'yup'

const CreateLoan = (props) => {
  const { changeModal, openSnackbar } = props

  const [load, setload] = useState(false)
  async function onSubmit(values, procedures) {
    try {
      handleReadH(0, procedures, values)
    } catch (e) {
      setload(false)
    }
  }

  function handleReadH(index, procedures, values) {
    postAction(`/procedure/readH/${procedures[index].id}`).then((res) => {
      if (!validateStatus(res.status)) {
        openSnackbar('No se pudo subir el archivo', true, false)
      }
      if (index < procedures.length - 1) {
        handleReadH(index + 1, procedures, values)
      } else {
        submitGenerate(values)
      }
    })
  }

  async function submitGenerate(values) {
    await putAction(
      `/procedure/Xlsx?initial=${values.initial}&final=${values.final}`,
      validateArray(values.company)
        ? values.company.filter(
            (n, index) => n && values.company.indexOf(n) === index,
          )
        : [],
    ).then((res) => {
      setload(false)
      if (validateStatus(res.status)) {
        if (res.data === 'list_empty') {
          openSnackbar('Se genero un documento vació', true, false)
        } else {
          window.open(res.data.url, '_blank')
        }
      } else {
        openSnackbar('No se pudo generar el documento', true, false)
      }
    })
  }

  async function handlePreprocess(values) {
    setload(true)
    const initial = values.initial + '-01'
    const final = `${values.initial}-${daysMonth(values.initial)}`
    await putAction(
      `/procedure/NoReadDim?initial=${initial}&final=${final}`,
      validateArray(values.company)
        ? values.company.filter(
            (n, index) => n && values.company.indexOf(n) === index,
          )
        : [],
    ).then((res) => {
      if (validateStatus(res.status)) {
        if (validateArray(res.data)) {
          onSubmit({ ...values, initial: initial, final: final }, res.data)
        } else {
          submitGenerate({ ...values, initial: initial, final: final })
        }
      } else {
        setload(false)
        openSnackbar('No se pudo generar el reporte', true, false)
      }
    })
  }
  return (
    <>
      <FormDefault
        load={load}
        title="Formulario para generar el reporte"
        textBtn="Generar"
        InputsJson={[
          {
            name: 'initial',
            initial: convertDate(
              new Date(),
              // new Date(currentDate.getFullYear(), 0, 1, 0, 0, 0),
              'YYYY-MM',
            ),
            validate: Yup.string().required('Campo requerido').nullable(),
            type: 'month',
            label: 'Fecha',
          },
          // {
          //   name: 'final',
          //   initial: convertDate(
          //     new Date(currentDate.getFullYear(), 11, 31, 23, 59, 59),
          //     'YYYY-MM-DD',
          //   ),
          //   validate: Yup.string().required('Campo requerido').nullable(),
          //   type: 'month',
          //   label: 'Fecha final',
          // },
          {
            control: 'getListOption',
            initial: [],
            validate: null,
            label: 'Lista de compañías',
            name: 'company',
            url: '/company/getallcompanies',
            keyOption: 'razonSocial',
            value: 'id',
          },
        ]}
        data={{}}
        onSubmit={handlePreprocess}
        onCancel={() => changeModal(false, null)}
      />
      {load && (
        <TextH3 textAlign="center" color="var(--blue)">
          Esto puede tardar varios minutos
        </TextH3>
      )}
    </>
  )
}

export default CreateLoan

import { useState, useEffect } from "react";
import { TextH2, TextH3 } from "../../components/Text";
import { RenderModal } from "../../components/modal";
import { GlobalButton } from "../../components/buttons";
import { BoxFlex } from "../../components/boxes";
import { TableFilters } from "../../components/table";
import { validateUserRol } from "../../utils/Validate";
import { openSnackbar, changeModal } from "../../store/actions";
import { connect } from "react-redux";
import {
  FormStart,
  FormStep,
  FormCompany,
  GenerateRoute,
  CompleteContainer,
} from "../../containers/procedure";
import Formreport from "../../containers/procedure/Formreport";
import FormReportArchive from "../../containers/procedure/FormReportArchive";

const Procedure = (props) => {
  const {
    match: { params },
    procedureSocket,
    setProcedureSocket,
    changeModal,
  } = props;

  const [modal, setmodal] = useState(false);
  const [modalReport, setmodalReport] = useState(false);
  const [statusReload, setStatusReload] = useState(false);

  useEffect(() => {
    if (
      procedureSocket &&
      procedureSocket.reloadTable &&
      procedureSocket.step === parseInt(params.id) &&
      (procedureSocket.procedureType === parseInt(params.tid) ||
        (procedureSocket.procedureType === 3 && parseInt(params.tid) === 2))
    ) {
      setStatusReload((status) => !status);
      setProcedureSocket((s) => ({
        ...s,
        reloadTable: false,
      }));
    }
  }, [procedureSocket, params]);

  async function onHandleReport() {
    changeModal(true, <FormReportArchive {...props} />, true, "450px");
  }

  return (
    <div>
      <BoxFlex justifyContent="space-between">
        <TextH2 fontSize="2rem" color="var(--blue)">
          Lista para {params.name}
        </TextH2>
        {params.id === "11" && (
          <GlobalButton
            onClick={onHandleReport}
            width="fit-content"
            background="#052691"
            color="#e2e4f3"
          >
            Generar reporte archivo
          </GlobalButton>
        )}
        {params.id === "1" && (
          <BoxFlex>
            <GlobalButton
              onClick={() => setmodal(true)}
              width="fit-content"
              background="#052691"
              color="#e2e4f3"
            >
              Iniciar un proceso
            </GlobalButton>

            <GlobalButton
              onClick={() => setmodalReport(true)}
              background="#052691"
              color="#e2e4f3"
              width="fit-content"
            >
              Generar Reporte
            </GlobalButton>
          </BoxFlex>
        )}
      </BoxFlex>
      <br />
      {modal && (
        <RenderModal width="500px" onClose={() => setmodal(false)} closeOutside>
          <FormStart
            {...props}
            onClose={() => setmodal(false)}
            handleReload={() => setStatusReload(!statusReload)}
          />
        </RenderModal>
      )}
      {modalReport && (
        <RenderModal
          width="500px"
          height="300px"
          onClose={() => setmodalReport(false)}
          closeOutside
        >
          <Formreport
            {...props}
            onClose={() => setmodalReport(false)}
            handleReload={() => setStatusReload(!statusReload)}
          />
        </RenderModal>
      )}
      <TableContainder
        {...props}
        step={parseInt(params.id)}
        tid={parseInt(params.tid)}
        statusReload={statusReload}
      />
    </div>
  );
};

const TableContainder = (props) => {
  const {
    step,
    statusReload,
    changeModal,
    tid,
    sesionUser: { rols },
  } = props;

  function handleClick(procedure, reload) {
    changeModal(
      true,
      <FormStep {...props} procedure={procedure} reload={reload} />,
      false
    );
  }
  function handleCompleteProcess(procedure, reload) {
    changeModal(
      true,
      <CompleteContainer
        {...props}
        procedure={procedure}
        reload={reload}
        handleGenerateRoute={handleAction}
      />,
      false
    );
  }

  function handleEditCompany(procedure, reload) {
    changeModal(
      true,
      <FormCompany {...props} procedure={procedure} reload={reload} />,
      false
    );
  }
  function handleAction(procedure) {
    changeModal(
      true,
      <GenerateRoute {...props} procedure={procedure} />,
      false,
      "900px"
    );
  }

  function handleDay(item, name) {
    const days = parseInt(item[name]);
    return (
      <TextH3
        background={
          days
            ? item.dimNumber
              ? "var(--green)"
              : parseInt(days) > 30
              ? "var(--green)"
              : parseInt(days) > (item.procedureTypeId === 2 ? 20 : 10)
              ? "var(--green)"
              : "var(--red)"
            : "var(--gray)"
        }
        margin="0"
        padding="2px 10px 5px"
        borderRadius="15px"
        width="fit-content"
        color="var(--white)"
      >
        {item.procedureTypeId === 1 || !days
          ? ""
          : item.dimNumber
          ? "Regularizado"
          : item.procedureTypeId === 2
          ? `${days} / 60`
          : `${days} / 20`}
      </TextH3>
    );
  }

  const headerDef = [
    { key: "orderNumber", name: "Número de pedido" },
    { key: "billNumber", name: "Número de factura" },
    { key: "dimNumber", name: "Número de DIM o DEX" },
    { key: "companyName", name: "Compañía", type: "text" },
    { key: "customsClearance", name: "Aduana de despacho", type: "text" },
    {
      key: "procedureTypeName",
      name: "Tipo",
      type: "text",
    },
    {
      key: "timeToParte",
      name: "Días para regularizar DIM",
      type: "validate",
      validate: handleDay,
    },
    {
      key: "urgent",
      name: "Urgente",
      type: "textBoolean",
      textDefault: "No",
      translate: {
        true: "Urgente",
        false: " ",
      },
      color: {
        true: "var(--red)",
      },
    },
    {
      key: "listActions",
      name: "Acciones",
      textBtn: "Acciones",
      type: "listActions",
      listBtn: [
        step === 1 && validateUserRol(rols, "senasag_procedure")
          ? null
          : {
              children: "Completar",
              action: handleCompleteProcess,
              label: "Completar",
            },
        step === 1
          ? {
              children: <i className="fas fa-edit"></i>,
              action: handleEditCompany,
              label: "Modificar compañía",
            }
          : null,
      ],
    },
  ];

  return (
    <TableFilters
      params={`/procedure/bystepbyproceduretypeid/${step}/proceduretypeid/${tid}`}
      functionClick={handleClick}
      header={
        step === 1
          ? [
              {
                key: "internCode",
                name: "Número de interno",
              },
              {
                key: "userStart",
                name: "Encargado",
                type: "text",
              },
              ...headerDef,
            ]
          : step >= 5
          ? [
              {
                key: "internCode",
                name: "Número de interno",
                type: "textAction",
                action: handleAction,
              },
              ...headerDef,
            ]
          : [
              {
                key: "internCode",
                name: "Número de interno",
              },
              ...headerDef,
            ]
      }
      handleReload={statusReload}
      filterBoxes={[
        {
          type: "getSelect",
          label: "Todas las compañías",
          name: "companyId",
          url: "/company/getallcompanies",
          urlName: "id",
          urlLabel: "razonSocial",
        },
      ]}
    />
  );
};

export default connect((state) => ({ sesionUser: state.sesionUser }), {
  openSnackbar,
  changeModal,
})(Procedure);

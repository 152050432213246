import { Main } from "../../routes";
import { Snackbar, ModalGlobal } from "../../components/common";
import { RenderModal } from "../../components/modal";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { withRouter } from "react-router-dom";
import { closeSession, changeModal, openSnackbar } from "../../store/actions";
import { useSize } from "../../hook";
import { inputGetFeedback } from "../../utils/FormFields";
import { validateStatus, validateUserRol } from "../../utils/Validate";
import { TextH3 } from "../../components/Text";
import { postAction } from "../../services/actions/AuthAction";
import { useAuthAxios } from "../../hook";
import { FormDefault } from "../../components/forms";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import "./Style/Style.css";
import { HubConnectionBuilder } from "@microsoft/signalr";
import fetchURL from "../../config.json";

const Template = (props) => {
  const { sesionUser } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/users/${window.localStorage.getItem("id")}/preferencesForPoll`,
  });
  const rolClient = validateUserRol(sesionUser.rols, "client");

  const [target, currentSize] = useSize();
  const [view, setview] = useState(true);
  const [procedureSocket, setProcedureSocket] = useState({
    step: null,
    procedureType: null,
    reload: false,
    reloadTable: false,
  });

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${fetchURL}/notificationHub`)
      .withAutomaticReconnect()
      .build();
    const connect = connection.connection;
    connection
      .start()
      .then(() => {
        connection.on("ListTypeProcedure", (stepHub, procedureTypeId) => {
          setProcedureSocket({
            step: stepHub,
            procedureType: procedureTypeId,
            reload: true,
            reloadTable: true,
          });
        });
        postAction(`/auth/SocketConnection/${connect.connectionId}`).then(
          () => {}
        );
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  return (
    <>
      <div ref={target} className="Template">
        <Header
          {...props}
          view={view}
          setview={setview}
          loading={loading}
          reload={reload}
          response={response}
          rolClient={rolClient}
        />
        <div className="Template__main">
          {currentSize.width && sesionUser.authenticate ? (
            <ContentSidebar
              width={currentSize.width}
              sesionUser={sesionUser}
              view={view}
              setview={setview}
              procedureSocket={procedureSocket}
              setProcedureSocket={setProcedureSocket}
            />
          ) : null}
          <div
            className="Template__container"
            style={{
              height:
                !loading && !response && rolClient
                  ? "calc(100vh - 115px)"
                  : "calc(100vh - 80px)",
            }}
          >
            <Main
              procedureSocket={procedureSocket}
              setProcedureSocket={setProcedureSocket}
            />
          </div>
        </div>
      </div>
      <ModalGlobal />
      <Snackbar />
      {sesionUser.rating && rolClient ? (
        <GiveFeedback rating={sesionUser.rating} />
      ) : null}
    </>
  );
};

const ContentSidebar = (props) => {
  const {
    width,
    sesionUser,
    view,
    setview,
    procedureSocket,
    setProcedureSocket,
  } = props;
  useEffect(() => {
    if (width < 900) {
      setview(false);
    }
  }, []);

  return (
    <Sidebar
      view={view}
      setview={setview}
      maxWidth={width < 900}
      sesionUser={sesionUser}
      procedureSocket={procedureSocket}
      setProcedureSocket={setProcedureSocket}
    />
  );
};

const GiveFeedback = ({ rating }) => {
  const [modal, setmodal] = useState(rating);
  const [load, setload] = useState(false);
  async function onSubmit(values) {
    try {
      await postAction(
        `/clientStats/users/${window.localStorage.getItem("id")}/rating`,
        values
      ).then((res) => {
        setload(false);
        setmodal(!modal);
        if (validateStatus(res.status)) {
          openSnackbar("Se guardo exitosamente", true, true);
        } else {
          openSnackbar("No se pudo guardar", true, false);
        }
      });
    } catch (e) {
      setload(false);
    }
  }
  if (modal) {
    return (
      <div className="GiveFeedback">
        <RenderModal width="auto" closeOutside>
          <TextH3 fontWeight="600" fontSize="2rem" margin="0 0 5px">
            Danos tu opinión
          </TextH3>
          <FormDefault
            load={load}
            textBtn="Enviar"
            InputsJson={inputGetFeedback}
            data={{}}
            onSubmit={onSubmit}
          />
        </RenderModal>
      </div>
    );
  } else {
    return null;
  }
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  closeSession,
  changeModal,
  openSnackbar,
})(withRouter(Template));

export const openSnackbar = (content, status, kind) => ({
  type: 'OPEN_SNACKBAR',
  content,
  status,
  kind,
})
export const changeModal = (
  status,
  children,
  closeOutside = true,
  width = '500px',
) => ({
  type: 'CHANGE_MODAL',
  status,
  children,
  width,
  closeOutside,
})
export const startSession = (
  user,
  rols,
  institutions,
  status,
  rating,
  companys,
  location,
) => ({
  type: 'START_SESSION',
  user,
  rols,
  institutions,
  status,
  rating,
  companys,
  location,
})
export const closeSession = () => ({
  type: 'CLOSE_SESSION',
})

import { useAuthAxios } from '../../hook'
import Loading from '../../components/animation/Loading'
import { TableFilters } from '../../components/table'
import { TextH2, TextH3 } from '../../components/Text'
import { LineCircle } from '../../components/progress'
import { BoxFlex } from '../../components/boxes'
import { connect } from 'react-redux'
import { changeModal } from '../../store/actions'
import { validateArray } from '../../utils/Validate'
import { createOption } from '../../utils/ProcessData'
import { addDownloadHistory } from '../../services/actions/AuthAction'

const Client = (props) => {
  const {
    changeModal,
    match: { params },
  } = props
  const search = new URLSearchParams(props.location.search)

  const { response: resImport } = useAuthAxios({
    method: 'GET',
    url: 'rol/ImportRegimen',
  })
  const { response: resExport } = useAuthAxios({
    method: 'GET',
    url: 'rol/ExportRegimen',
  })
  function handleClick(procedure) {
    changeModal(
      true,
      <DetailProcedure
        {...props}
        procedure={procedure}
        progress={(procedure.step * 100) / procedure.totalSteps}
      />,
      true,
      '900px',
    )
  }

  function handleProgress(value) {
    return value.step >= 9
      ? '100%'
      : (value.step * 100) / value.totalSteps + '%'
  }
  function handleStep(value) {
    return value.step >= 9 ? 'Terminado' : value.stepName
  }
  function handleRegulation(value) {
    let status = undefined
    if (value.procedureTypeId === 2) {
      status = value.dimNumber
    } else if (value.procedureTypeId === 3) {
      status = value.regularizedDim
    } else if (value.procedureTypeId === 1) {
      status =
        value.regime === 'Exportación temporal'
          ? value.regularizationInternCode
          : value.dimNumber
    }

    return status !== undefined ? (
      <div
        style={{
          background: status ? 'var(--green)' : 'var(--red)',
          margin: '0',
          padding: '2px 10px 5px',
          borderRadius: '15px',
          width: 'fit-content',
          color: 'var(--white)',
        }}
      >
        {status ? 'Regularizado' : 'Sin regularizar'}
      </div>
    ) : (
      '--'
    )
  }
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Mis trámites
      </TextH2>
      <br />
      {validateArray(resExport) && validateArray(resImport) ? (
        <TableFilters
          params={`/procedure/byuserid/${window.localStorage.getItem(
            'id',
          )}/companyId/${params.id}/pag`}
          addQuery={[{ key: 'internCode', name: 'Número de interno' }]}
          header={[
            { key: 'internCode', name: 'Número de interno', type: 'text' },
            { key: 'orderNumber', name: 'Número de pedido' },
            { key: 'dimNumber', name: 'Número de DIM o DEX' },
            { key: 'billNumber', name: 'Número de factura' },
            { key: 'customsClearance', name: 'Aduana', type: 'text' },
            { key: 'regime', name: 'Régimen', type: 'text' },
            {
              key: 'regulation',
              name: 'Estado',
              type: 'validate',
              validate: handleRegulation,
            },
            {
              key: 'stepName',
              name: 'Paso actual',
              type: 'validate',
              validate: handleStep,
            },
            {
              key: 'step',
              name: 'Progreso',
              type: 'validate',
              validate: handleProgress,
            },
          ]}
          functionClick={handleClick}
          filterBoxes={[
            {
              type: 'getSelect',
              label: 'Seleccione un régimen',
              name: 'import',
              url: '/proceduretype',
              urlName: 'id',
              urlLabel: 'name',
              initial: search.get('import'),
            },
            {
              type: 'select',
              label: 'Todos los trámites',
              name: 'status',
              options: [
                { key: 'Trámites en curso', value: 'inCourse' },
                { key: 'Trámites finalizados', value: 'finished' },
              ],
              initial: search.get('status'),
            },
            {
              type: 'getSelect',
              label: 'Seleccione la aduana',
              name: 'customsClearance',
              url: '/dataSet/CustomsClearances',
              urlName: 'id',
              urlLabel: 'name',
              initial: search.get('customsClearance'),
            },
            {
              type: 'getSelect',
              label: 'Seleccione el canal',
              name: 'Color',
              url: '/rol/Color',
              urlName: 'name',
              urlLabel: 'name',
              initial: search.get('Color'),
            },
            {
              type: 'select',
              label: 'Seleccione el régimen',
              name: 'Regime',
              options: createOption(
                [...resExport, ...resImport],
                '',
                'name',
                'name',
                false,
              ),
              initial: search.get('Regime'),
            },
          ]}
        />
      ) : (
        <Loading />
      )}
    </>
  )
}
const DetailProcedure = (props) => {
  const {
    procedure,
    progress,
    sesionUser: { location },
  } = props
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
  })
  const { response: respDocuments, loading: loadDocuments } = useAuthAxios({
    method: 'GET',
    url: `/clientStats/procedures/${procedure.id}/documents`,
  })
  const { response: respData, loading: loadData } = useAuthAxios({
    method: 'GET',
    url: `/clientStats/procedures/${procedure.id}/data`,
  })

  return (
    <div className="DetailProcedure">
      <TextH2
        color="var(--blue)"
        fontWeight="600"
        fontSize="2rem"
        textAlign="center"
      >
        Detalles de trámite
      </TextH2>
      <TextH3 fontSize="1.8rem" color="var(--blue)">
        Progreso del trámite
      </TextH3>
      {loading ? (
        <Loading />
      ) : (
        <LineCircle
          list={response}
          step={procedure.step}
          progress={progress}
          maxItem={8}
        />
      )}
      <br />
      <br />
      {loadDocuments ? (
        <Loading />
      ) : (
        validateArray(respDocuments) && (
          <>
            <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
              Documentos del trámite
            </TextH2>
            <BoxFlex flexWrap="wrap" gap="5px 20px">
              {respDocuments.map((document, index) =>
                document.value ? (
                  <TextH3 margin="0" fontWeight="600" key={index}>
                    <a
                      onClick={() =>
                        addDownloadHistory(
                          document.label,
                          document.value,
                          procedure.id,
                          'procedure',
                          location,
                        )
                      }
                      style={{ color: 'var(--gray)' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={document.value}
                    >
                      {labelTranslate[document.name] || document.label}
                    </a>
                  </TextH3>
                ) : null,
              )}
            </BoxFlex>
          </>
        )
      )}
      <br />
      <br />
      <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
        Datos generales del trámite
      </TextH2>
      {loadData ? (
        <Loading />
      ) : (
        validateArray(respData) &&
        respData.map((data) =>
          data.value ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #c0c2c9',
                borderRadius: '5px',
                margin: '5px 0',
                padding: '5px 5px 10px',
              }}
            >
              <TextH2 margin="0">
                {labelTranslate[data.name] || data.label}:
              </TextH2>
              <TextH3 margin="0 auto" justifySelf="center" color="var(--gray)">
                {data.value}
              </TextH3>
            </div>
          ) : null,
        )
      )}
    </div>
  )
}
const labelTranslate = {
  codigoDeInterno: 'Número de interno Vilaseca',
  designacionDeLiquidador: 'Nombre del liquidador',
  asignarGestor: 'Nombre del gestor',
  'pedido/ordenDeCompra': 'Número de pedido',
  g5TotalTributosAPagar: 'Total de tributos a pagar G5',
  'a2FechaDeRegistro(Dam)': 'Fecha de registro de la Dam a2',
  adjuntarDim: 'DIM',
  adjuntarDex: 'DEX',
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal })(Client)

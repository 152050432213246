import { validateArray, validateStatus } from '../../utils/Validate'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { TextH3 } from '../../components/Text'
import { getAction } from '../../services/actions/AuthAction'

const GenerateExcelClient = (props) => {
  const { changeModal, openSnackbar } = props
  const [load, setload] = useState(false)

  async function Generate()
  {
    setload(true)
    await getAction('/ClientStats/Clientreport')
        .then((res) => {
            if(validateStatus(res.status))
            {
                setload(false)
                window.open(res.data.url)
                openSnackbar('Excel generado', true, true)
                changeModal(false, null)
            }
            else{
                setload(false)
                openSnackbar('No se pudo generar el reporte', true, false)
            }
        })
  }

  return (
    <>
      <FormDefault
        load={load}
        title="Generar Reporte de Satisfaccion de Cliente"
        textBtn="Generar"
        data={{}}
        onSubmit={Generate}
        onCancel={() => changeModal(false, null)}
      />
      {load && (
        <TextH3 textAlign="center" color="var(--blue)">
          Esto puede tardar varios minutos
        </TextH3>
      )}
    </>
  )
}

export default GenerateExcelClient

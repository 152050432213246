import { validateStatus } from '../../utils/Validate'
import { postAction, putAction } from './AuthAction'

export async function readDim(element, procedureId) {
  const resultConvertDIM = await putAction(
    `/files/convertPdfToCsv/${procedureId}`,
    {
      name: element.name,
    },
  ).then(async (res) => {
    if (validateStatus(res.status)) {
      const resultDataDIM = await postAction(`/files/DataDim/${procedureId}`, {
        name: element.name,
      }).then(async (resData) => {
        if (validateStatus(resData.status)) {
          const resultReadH = await postAction(
            `/procedure/readH/${procedureId}`,
          ).then((resH) => {
            return {
              readStatus: resH.status,
              readData: resH.data
                ? resH.data
                : `Error al leer los documentos "${element.name}"`,
              validate: element.validate,
            }
          })
          return resultReadH
        } else {
          return {
            readStatus: resData.status,
            readData: resData.data
              ? resData.data
              : `Error al leer "${element.name}"`,
            validate: element.validate,
          }
        }
      })
      return resultDataDIM
    } else {
      return {
        readStatus: res.status,
        readData: res.data ? res.data : `Error al leer "${element.name}"`,
        validate: element.validate,
      }
    }
  })
  return resultConvertDIM
}

export async function adjuntarDex(element, procedureId) {
  const resultConvertDIM = await putAction(
    `/files/convertPdfToCsv/${procedureId}`,
    {
      name: element.name,
    },
  ).then(async (res) => {
    if (validateStatus(res.status)) {
      const resultDataDIM = await postAction(`/files/DataDim/${procedureId}`, {
        name: element.name,
      }).then(async (resData) => {
        return {
          readStatus: resData.status,
          readData: resData.data
            ? resData.data
            : `Error al leer "${element.name}"`,
          validate: element.validate,
        }
      })
      return resultDataDIM
    } else {
      return {
        readStatus: res.status,
        readData: res.data ? res.data : `Error al leer "${element.name}"`,
        validate: element.validate,
      }
    }
  })
  return resultConvertDIM
}

export async function readDam(element, procedureId) {
  const result = await putAction(`/files/ReadDam/${procedureId}`).then(
    (res) => {
      return {
        readStatus: res.status,
        readData: res.data ? res.data : `Error al leer "${element.name}"`,
        validate: element.validate,
      }
    },
  )
  return result
}

export async function readParte(element, procedureId) {
  const result = await putAction(`/files/readparte/${procedureId}`).then(
    async (res) => {
      if (validateStatus(res.status)) {
        const resultNotify = await postAction(
          `/notification/parte/procedures/${procedureId}`,
        ).then((result) => {
          return {
            readStatus: result.status,
            readData: result.data
              ? result.data
              : `Error al crear la notificación del "${element.name}"`,
            validate: element.validate,
          }
        })
        return resultNotify
      } else {
        return {
          readStatus: res.status,
          readData: res.data ? res.data : `Error al leer "${element.name}"`,
          validate: element.validate,
        }
      }
    },
  )
  return result
}

import { TypeInput } from './Constant'
export const headerUsers = [
  { key: 'name', name: 'Nombres', search: 'firstName' },
  { key: 'fatherLastName', name: 'Ap. paterno' },
  { key: 'motherLastName', name: 'Ap. materno' },
  { key: 'email', name: 'Correo electronico' },
  { key: 'status', name: 'Estado', type: 'text' },
  // { key: 'cellphoneNumber', name: 'Numero de celular', search: 'telephone' },
]
export const headerClient = [
  { key: 'razonSocial', name: 'Razón social' },
  { key: 'status', name: 'Estado', type: 'status' },
  { key: 'nit', name: 'NIT' },
]
export const headerField = [
  { key: 'label', name: 'Etiqueta' },
  // { key: 'initial', name: 'Valor inicial', type: 'text' },
  {
    key: 'type',
    name: 'Tipo',
    type: 'textTranslated',
    translate: TypeInput,
  },
]
export const headerAddClient = [
  { key: 'name', name: 'Nombre' },
  { key: 'fatherLastName', name: 'Ap. paterno' },
  { key: 'motherLastName', name: 'Ap. materno' },
  { key: 'email', name: 'Correo electronico' },
]

import './Management.css'
import { useState } from 'react'
import { connect } from 'react-redux'
import DateView from 'react-datepicker'
import { GlobalButton } from '../../components/buttons'
import { BoxFlex } from '../../components/boxes'
import { SimpleSelect } from '../../components/simpleInput'
import { openSnackbar, changeModal } from '../../store/actions'
import {
  FormGenerateExcel,
  ManagementContainer,
  FormGenerateItems,
} from '../../containers/management'
import GenerateExcelClient from '../../containers/management/GenerateExcelClient'
const Management = (props) => {
  const { changeModal } = props
  const today = new Date()
  const [city, setcity] = useState('')
  const [filters, setfilters] = useState({
    yearStart: today,
  })
  function onChange(e, name) {
    setfilters((a) => ({
      ...a,
      [name]: e,
    }))
  }

  function handleDate() {
    const { yearStart } = filters
    return 'internCode=' + yearStart.getFullYear()
  }

  function handleGenerateExcel() {
    changeModal(true, <FormGenerateExcel {...props} />, true, '500px')
  }

  function handleGenerateItems() {
    changeModal(true, <FormGenerateItems {...props} />, true, '500px')
  }

  function handleGenerateExcelClientRaiting() {
    changeModal(true, <GenerateExcelClient {...props} />, true, '500px')
  }

  return (
    <div className="Management">
      <div className="Management__filters">
        <BoxFlex>
          <div className="DatePicker form-control Management__year">
            <DateView
              locale="es"
              id="yearStart"
              name="yearStart"
              selected={filters.yearStart}
              showYearPicker
              maxDate={new Date()}
              dateFormat="yyyy"
              onChange={(date) => onChange(date, 'yearStart')}
            />
          </div>
          <SimpleSelect
            name="city"
            value={city}
            options={[
              { key: 'Ciudad', value: '' },
              { key: 'La Paz', value: 'La Paz' },
              { key: 'Santa Cruz', value: 'Santa Cruz' },
              { key: 'Chuquisaca', value: 'Chuquisaca' },
              { key: 'Cochabamba', value: 'Cochabamba' },
              { key: 'Oruro', value: 'Oruro' },
              { key: 'Potosí', value: 'Potosí' },
              { key: 'Tarija', value: 'Tarija' },
              { key: 'Beni', value: 'Beni' },
              { key: 'Pando', value: 'Pando' },
            ]}
            onChange={({ target }) => setcity(target.value)}
            className="Management__select"
          />
        </BoxFlex>
        <BoxFlex className="Management__date">
          <GlobalButton
            background="var(--blue)"
            color="var(--white)"
            onClick={handleGenerateItems}
            width="fit-content"
          >
            Excel por items
          </GlobalButton>
          <GlobalButton
            background="var(--blue)"
            color="var(--white)"
            width="fit-content"
            onClick={handleGenerateExcel}
          >
            Generar excel
          </GlobalButton>

          <GlobalButton
            background="var(--blue)"
            color="var(--white)"
            width="fit-content"
            onClick={handleGenerateExcelClientRaiting}
          >
            Generar Reporte de Satisfaccion
          </GlobalButton>
        </BoxFlex>
      </div>
      <br />
      <ManagementContainer
        handleDate={handleDate}
        filters={filters}
        city={city}
        changeModal={changeModal}
      />
    </div>
  )
}

export default connect(null, { openSnackbar, changeModal })(Management)

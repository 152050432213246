import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { validateArray } from "../../utils/Validate";
import Select from "react-select";

const SelectInput = (props) => {
  const { label, name, options, upload, value } = props;

  function onChange(value) {
    upload((a) => ({
      ...a,
      [name]: value.value,
    }));
  }
  function handlePlaceholder(value) {
    return value
      ? value
      : validateArray(options) && options[0].value === ""
      ? options[0].label || options[0].key
      : "Seleccionar...";
  }
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <Select
        isSearchable={true}
        onChange={onChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: "1.6rem",
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            fontSize: "1.5rem",
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            border: "1px solid gray",
            borderRadius: "5px",
            maxHeight: "150px",
          }),
          placeholder: (base) => ({
            ...base,
            color: "black",
            fontSize: "1.6rem",
          }),
        }}
        placeholder={handlePlaceholder(value[name])}
        isOptionSelected={false}
        options={options
          .filter((option) => option.value !== "")
          .map((option) => ({
            ...option,
            label: option.key,
          }))}
        // isDisabled={field.lockEdition}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

// const SelectInput = (props) => {
//   const { label, name, options, ...rest } = props;
//   return (
//     <div className="form-control">
//       <label htmlFor={name}>{label}</label>
//       <Field as="select" id={name} name={name} {...rest}>
//         {options.map((option) => {
//           return (
//             <option key={option.value} value={option.value}>
//               {option.key}
//             </option>
//           );
//         })}
//       </Field>
//       <ErrorMessage component={TextError} name={name} />
//     </div>
//   );
// };

export default SelectInput;

import './Bar.css'
import { TextH3 } from '../Text'
import { ToolTip } from '../boxes'
import { Fragment, useState } from 'react'
import { validateArray } from '../../utils/Validate'
import { GlobalButton } from '../../components/buttons'
const BarColumn = (props) => {
  const { data } = props
  const [view, setview] = useState(false)
  const dataMaxSum = data
    .map((item) => item.data)
    .reduce((prev, curr) => prev + curr, 0)
  const dataMax = Math.max(...data.map((item) => item.data))
  return (
    <>
      <div className="BarColumn">
        {validateArray(data) && (
          <>
            {data.map((item, index) => {
              if (index < 15 || view) {
                const {
                  percentageCalc = `${
                    dataMax === 0
                      ? 0
                      : ((100 * item.percentage) / dataMaxSum).toFixed(2)
                  } `,
                } = item
                return (
                  <Fragment key={index}>
                    <TextH3
                      margin="0"
                      minWidth="fit-content"
                      color="var(--gray)"
                      textTransform="uppercase"
                    >
                      {item.label}
                    </TextH3>
                    <ToolTip
                      content={
                        <>
                          <TextH3
                            margin="0"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            {item.name}
                          </TextH3>
                          <TextH3
                            margin="0"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Cantidad: <strong>{item.data}</strong>
                          </TextH3>
                          <TextH3
                            margin="0"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Porcentaje: {percentageCalc}%
                            {/* <strong>{`${
                              dataMax === 0
                                ? 0
                                : Math.ceil(
                                    (100 * item.percentage) / dataMax || 0,
                                  )
                            }%`}</strong> */}
                          </TextH3>
                        </>
                      }
                    >
                      <div
                        style={{
                          height: '10px',
                          background: 'rgba(128, 128, 128, 0.4)',
                          width: '100%',
                          borderRadius: '10px',
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div
                          style={{
                            width: `${Math.ceil(
                              (100 * item.percentage) / dataMax,
                            )}%`,
                            background: item.color,
                            height: '10px',
                            borderRadius: '10px',
                          }}
                        ></div>
                      </div>
                    </ToolTip>
                    <TextH3
                      margin="0"
                      minWidth="fit-content"
                      color="var(--gray)"
                    >
                      {/* {`${
                        dataMax === 0
                          ? 0
                          : Math.ceil((100 * item.percentage) / dataMax)
                      }%`} */}
                      {percentageCalc}%
                    </TextH3>
                  </Fragment>
                )
              } else {
                return null
              }
            })}
            {data.length >= 15 && (
              <>
                <br />
                <center>
                  <GlobalButton
                    onClick={() => setview(!view)}
                    padding="0 20px"
                    width="fit-content"
                  >
                    {view ? (
                      <>
                        Ver menos <i className="fas fa-angle-up"></i>
                      </>
                    ) : (
                      <>
                        Ver más <i className="fas fa-angle-down"></i>
                      </>
                    )}
                  </GlobalButton>
                </center>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default BarColumn

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikControl } from "../inputs";
import { ActionButton, GlobalButton } from "../buttons";
import Loading from "../animation/Loading";
import { validateArray, validateBoolean } from "../../utils/Validate";
import { TextH2 } from "../Text";

const FormDefault = (props) => {
  const {
    InputsJson,
    title = "",
    textBtn,
    data,
    onSubmit,
    load,
    onCancel,
  } = props;

  function initialGeneration(InputsJson) {
    let init = {};
    let validate = {};
    if (validateArray(InputsJson)) {
      InputsJson.forEach((input) => {
        if (input.control === "checkbox") {
          init = {
            ...init,
            [input.name]: data[input.name] ? data[input.name] : input.initial,
          };
          validate = {
            ...validate,
            [input.name]: input.validate
              ? Yup.mixed().notOneOf([`;${input.name}`], "Campo requerido")
              : null,
          };
        } else if (input.type === "checkbox" || input.control === "switch") {
          init = {
            ...init,
            [input.name]: validateBoolean(data[input.name])
              ? data[input.name]
              : input.initial,
          };
          validate = { ...validate, [input.name]: input.validate };
        } else {
          init = {
            ...init,
            [input.name]: data[input.name] ? data[input.name] : input.initial,
          };
          validate = { ...validate, [input.name]: input.validate };
        }
      });
    }
    return { init, validate };
  }
  const { init, validate } = initialGeneration(InputsJson);
  const initialValues = init;
  const validationSchema = Yup.object(validate);
  return (
    <div>
      <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
        {title}
      </TextH2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        // onSubmit={onCancel ? onSubmit : null}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              {validateArray(InputsJson)
                ? InputsJson.map((inpt, index) => {
                    const condition = (inpt.condition + "").split(",");
                    if (
                      (condition.indexOf("*") > -1 &&
                        formik.values[inpt.reference] + "" !== "") ||
                      condition.indexOf(formik.values[inpt.reference] + "") > -1
                    ) {
                      switch (inpt.control) {
                        case "select":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control ? inpt.control : "input"}
                              options={inpt.options}
                              label={inpt.label}
                              name={inpt.name}
                              upload={formik.setValues}
                              value={formik.values}
                            />
                          );
                        case "checkbox":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control}
                              options={inpt.options}
                              label={inpt.label}
                              name={inpt.name}
                              // values={inpt.initial}
                            />
                          );
                        case "file":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control}
                              name={inpt.name}
                              small={true}
                              label={inpt.label}
                              uploadFiles={formik.setValues}
                              validate={inpt.validate}
                              // type={inpt.type}
                              initial={inpt.initial}
                            />
                          );
                        case "selectGet":
                        case "getSelect":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control}
                              label={inpt.label}
                              name={inpt.name}
                              url={
                                inpt.url +
                                `${
                                  inpt.urlInput
                                    ? "/" + formik.values[inpt.urlInput]
                                    : ""
                                }`
                              }
                              nameList={inpt.nameList || null}
                              keyOption={inpt.keyOption || "name"}
                              value={inpt.value || "name"}
                              upload={formik.setValues}
                              valueInput={formik.values}
                            />
                          );
                        case "getCheckbox":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control}
                              label={inpt.label}
                              name={inpt.name}
                              url={inpt.url}
                              nameList={inpt.nameList}
                              keyOption={inpt.keyOption}
                              value={inpt.value}
                            />
                          );
                        case "checkboxDescription":
                        case "descriptionCheck":
                          return (
                            <FormikControl
                              key={index}
                              control={inpt.control ? inpt.control : "input"}
                              type={inpt.type}
                              upload={formik.setValues}
                              label={inpt.label}
                              name={inpt.name}
                              initial={inpt.initial}
                              validate={inpt.validate}
                            />
                          );
                        case "datePicker":
                          return (
                            <FormikControl
                              {...inpt}
                              key={index}
                              control={inpt.control}
                              type={inpt.type}
                              label={inpt.label}
                              name={inpt.name}
                              placeholderText={inpt.label}
                              dateFormat={inpt.dateFormat}
                              showTimeSelect={
                                inpt.showTimeSelect ? true : false
                              }
                              timeIntervals={inpt.timeIntervals}
                            />
                          );
                        case "getListOption":
                          return (
                            <FormikControl
                              {...inpt}
                              key={index}
                              control={inpt.control ? inpt.control : "input"}
                              type={inpt.type}
                              label={inpt.label}
                              name={inpt.name}
                              upload={formik.setValues}
                              value={formik.values}
                            />
                          );
                        default:
                          return (
                            <FormikControl
                              {...inpt}
                              key={index}
                              control={inpt.control ? inpt.control : "input"}
                              type={inpt.type}
                              label={inpt.label}
                              name={inpt.name}
                            />
                          );
                      }
                    } else {
                      return null;
                    }
                  })
                : null}
              {load ? (
                <Loading />
              ) : onCancel ? (
                <ActionButton
                  type="submit"
                  onCancel={onCancel}
                  textBtn={textBtn}
                  textCancel="Cancelar"
                  disabled={!formik.isValid}
                  alertBoolean
                />
              ) : (
                <GlobalButton type="submit">{textBtn}</GlobalButton>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormDefault;

import App from './App'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import reducer from './store/reducers'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
const initialState = {
  themeReducer: {
    white: '#ffffff',
    black: '#000000',
    gray: '#5F5961',
    blue: '#052691',
    red: '#c93f3f',
    green: '#458e7f',
  },
  sesionUser: {
    user: {},
    rols: [],
    institutions: [],
    authenticate: false,
    rating: false,
    companys: null,
    location: null,
  },
  snackbarReducer: {
    open: false,
    message: '',
    kind: true,
  },
  modalReducer: {
    status: false,
    children: null,
    width: '500px',
    closeOutside: true,
  },
}
const store = createStore(reducer, initialState)
const container = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  container,
)
reportWebVitals()

import { Formik, Form } from 'formik'
import { FormikControl } from '../inputs'
import './style/Forms.css'
import { useState } from 'react'
import * as Yup from 'yup'
import { postAction } from '../../services/actions/Action'
import { Link } from 'react-router-dom'
import { GlobalButton } from '../buttons'
import Loading from '../animation/Loading'
import { validateStatus } from '../../utils/Validate'
import { TextH1 } from '../Text'
import { openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
const PasswordRecovery = (props) => {
  const { verifyKey } = props.match.params
  const [load, setload] = useState(true)
  const initialValues = {
    Email: '',
    Password: '',
    Password_confirmation: '',
  }
  const validationSchema = Yup.object({
    Email: Yup.string()
      .email('Ingrese un email valido')
      .required('Requiere Este Campo'),
    Password: Yup.string()
      .required('Campo Obligatorio')
      .min(4, 'mínimo 4 caracteres requeridos'),
    Password_confirmation: Yup.string()
      .required('Campo Obligatorio')
      .min(4, 'mínimo 4 caracteres requeridos')
      .oneOf([Yup.ref('Password'), null], 'Las contraseñas no coinciden'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/verifyrecovery', {
        ...values,
        VerifyKey: verifyKey,
      }).then((response) => {
        if (response && validateStatus(response.status)) {
          props.openSnackbar('Ahora usar su nueva contraseña', true, true)
          props.history.push('/')
        } else {
          props.openSnackbar('El enlace no es valido', true, false)
          props.history.push('/')
        }
        setload(true)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="log_in">
      <TextH1 fontSize="2.4rem" textAlign="center" color="#052691">
        Cambiar contraseña
      </TextH1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="email"
                label="Correo electrónico:"
                name="Email"
              />
              <FormikControl
                control="input"
                type="password"
                label="Contraseña"
                name="Password"
              />
              <FormikControl
                control="input"
                type="password"
                label="Confirmar Contraseña"
                name="Password_confirmation"
              />
              {load ? (
                <center>
                  <GlobalButton type="submit">Cambiar contraseña</GlobalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <center>
        <Link className="link_style" to="/auth/login">
          Volver al inicio de sesión
        </Link>
      </center>
    </div>
  )
}
export default connect(null, { openSnackbar })(PasswordRecovery)

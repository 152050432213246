import './TableDefault.css'
import DataType from './DataType'
import CellMobile from './CellMobile'
import { validateArray } from '../../utils/Validate'
import { TextH2 } from '../../components/Text'
import { useSize } from '../../hook'
import { useState } from 'react'
const TableDefault = (props) => {
  const {
    header,
    main,
    keyOrder = '',
    functionClick,
    reload,
    handleAddHistory,
    filters,
  } = props
  const [target, currentSize] = useSize()
  const gridTable = {
    gridTemplate: `auto / repeat(${header.filter(Boolean).length}, 1fr)`,
  }
  const [activate, setactivate] = useState(null)
  function handleActivate(index, item) {
    setactivate(index)
    if (functionClick) {
      functionClick(item, reload)
    }
  }

  function TableForMobile() {
    return (
      <>
        {validateArray(main)
          ? main.map((head, i) => {
              return (
                <CellMobile
                  key={i}
                  id={i}
                  cell={head}
                  header={header}
                  handleActivate={handleActivate}
                  activate={activate}
                  handleAddHistory={handleAddHistory}
                  filters={filters}
                />
              )
            })
          : null}
      </>
    )
  }

  function TableFordesk() {
    return (
      <>
        <div className="TableDefault__header" style={gridTable}>
          {validateArray(header)
            ? header.map((a, i) =>
                a ? (
                  <TextH2
                    key={i}
                    fontWeight="600"
                    color="var(--blue)"
                    className="TableDefault__head"
                  >
                    {a.name}
                  </TextH2>
                ) : null,
              )
            : null}
        </div>
        <div className="TableDefault__main">
          {validateArray(main)
            ? main
                .sort((a, b) => a[keyOrder] - b[keyOrder])
                .map((head, i) => (
                  <div
                    key={i}
                    style={gridTable}
                    className={`TableDefault__cell ${
                      activate === i ? 'TableDefault__cell-activate' : ''
                    }`}
                  >
                    {validateArray(header)
                      ? header.map((a, j) =>
                          a ? (
                            <div
                              key={j}
                              onClick={
                                a.type === 'action' ||
                                a.type === 'textAction' ||
                                a.type === 'listActions'
                                  ? () => {}
                                  : () => handleActivate(i, head)
                              }
                              style={{ padding: '10px 0 15px 10px' }}
                            >
                              <DataType
                                a={a}
                                head={head}
                                filters={filters}
                                reload={reload}
                                handleAddHistory={handleAddHistory}
                              />
                            </div>
                          ) : null,
                        )
                      : null}
                  </div>
                ))
            : null}
        </div>
      </>
    )
  }

  return (
    <div ref={target} className="TableDefault">
      {currentSize.width ? (
        currentSize.width > 700 ? (
          <TableFordesk />
        ) : (
          <TableForMobile />
        )
      ) : (
        <TableFordesk />
      )}
    </div>
  )
}

export default TableDefault

import { validateStatus } from '../../utils/Validate'
import { inputReturnLoan } from '../../utils/FormFields'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { TextH2, TextH3 } from '../../components/Text'
import { putAction } from '../../services/actions/AuthAction'

const ReturnLoans = (props) => {
  const { reload, loans, changeModal, openSnackbar } = props
  const [load, setload] = useState(false)

  async function onSubmit(values) {
    try {
      await putAction(`/loans/${loans.id}`, values).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se registro exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo crear', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }

  return (
    <>
      <TextH2 color="var(--blue)" fontSize="2rem" textAlign="center">
        Formulario de devolución
      </TextH2>
      <TextH3>
        Observación:{' '}
        <span style={{ color: 'var(--gray)' }}>{loans.observation}</span>
      </TextH3>
      {loans.conformity ? (
        <TextH3>
          Observaciones de la devolución:{' '}
          <span style={{ color: 'var(--gray)' }}>
            {loans.returnObservation}
          </span>
        </TextH3>
      ) : (
        <FormDefault
          load={load}
          textBtn="Guardar"
          InputsJson={inputReturnLoan}
          data={{}}
          onSubmit={onSubmit}
          onCancel={() => changeModal(false, null)}
        />
      )}
    </>
  )
}

export default ReturnLoans

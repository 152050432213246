import { inputEditStep } from '../../utils/FormFields'
import { capitalizeJson, jsonToArray } from '../../utils/ProcessData'
import { validateStatus } from '../../utils/Validate'
import { FormDefault } from '../../components/forms'
import { putAction } from '../../services/actions/AuthAction'
import { useState } from 'react'
const FormEditStep = (props) => {
  const {
    field,
    changeModal,
    match: { params },
    openSnackbar,
    reload,
  } = props
  const [load, setload] = useState(false)

  async function onSubmit(values) {
    try {
      await putAction(`/processstep/${field.id}`, {
        ...values,
        ProcedureTypeId: params.id,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }

  return (
    <FormDefault
      load={load}
      title="Editar paso"
      textBtn="Editar"
      InputsJson={inputEditStep}
      data={
        field
          ? { ...capitalizeJson(field), Rol: jsonToArray(field.rolId, 'rolId') }
          : {}
      }
      onSubmit={onSubmit}
      onCancel={() => changeModal(false, null)}
    />
  )
}

export default FormEditStep

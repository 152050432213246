import { TextH3 } from '../Text'
import { validateArray } from '../../utils/Validate'
import { useAuthAxios } from '../../hook'
import { GlobalButton } from '../buttons'
import { Loading } from '../animation'
import { BoxFlex } from '../boxes'
import { Fragment } from 'react'

const InputArray = (props) => {
  const { label, name, value, url, upload, keyOption } = props
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: url,
  })
  function handleAdd() {
    upload((a) => ({
      ...a,
      [name]: [...a[name], ''],
    }))
  }
  function handleRemove(index, item) {
    let newList = value[name].filter(
      (data, indexData) => data !== item || index !== indexData,
    )
    upload((a) => ({
      ...a,
      [name]: [...newList],
    }))
  }

  function handleChange(e, index) {
    const { value: valueData } = e.target
    let aux = value[name]
    aux[index] = valueData
    upload((a) => ({
      ...a,
      [name]: [...aux],
    }))
  }
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <div className="form-control">
          <label>{label}</label>
          {!validateArray(value[name]) ? (
            <>
              <TextH3>
                Si no selecciona alguna compañía se generara de todas las
                compañías
              </TextH3>
            </>
          ) : (
            <>
              {value[name].map((item, index) => (
                <Fragment key={index}>
                  <BoxFlex margin="0 0 10px">
                    <select
                      name={`${name}-${index}`}
                      id=""
                      value={item}
                      onChange={(e) => handleChange(e, index)}
                    >
                      <option value="">Seleccione la compañía</option>
                      {response.map((option, index) => {
                        return (
                          <option key={index} value={option.id}>
                            {option[keyOption]}
                          </option>
                        )
                      })}
                    </select>
                    <GlobalButton
                      padding="5px 12px"
                      width="fit-content"
                      onClick={() => handleRemove(index, item)}
                    >
                      <i className="fas fa-times"></i>
                    </GlobalButton>
                  </BoxFlex>
                </Fragment>
              ))}
            </>
          )}
          <GlobalButton onClick={handleAdd}>Adicionar compañía</GlobalButton>
        </div>
      </>
    )
  }
}

export default InputArray

import { useState } from 'react'
import { capitalizeJson } from '../../utils/ProcessData'
import { validateStatus } from '../../utils/Validate'
import { inputNewField, inputEditField } from '../../utils/FormFields'
import { FormDefault } from '../../components/forms'
import { postAction, putAction } from '../../services/actions/AuthAction'
const FieldForm = (props) => {
  const [load, setload] = useState(false)
  const { onClose, openSnackbar, step, field = null } = props

  function save(res) {
    if (validateStatus(res.status)) {
      openSnackbar('Se guardo exitosamente', true, true)
      onClose()
    } else {
      openSnackbar('No se pudo guardar', true, false)
    }
  }
  async function onSubmit(values) {
    setload(true)
    try {
      await postAction('/field', {
        ...values,
        ProcessStepIdList: [step.id],
      }).then((res) => {
        setload(false)
        save(res)
      })
    } catch (e) {
      setload(false)
    }
  }
  async function onEdit(values) {
    setload(true)
    try {
      await putAction(
        `/field/${field.id}/processStepId/${step.id}`,
        values,
      ).then((res) => {
        setload(false)
        save(res)
      })
    } catch (e) {
      setload(false)
    }
  }

  return (
    <FormDefault
      load={load}
      title={field ? 'Editar campo' : 'Crear nuevo campo'}
      textBtn={field ? 'Editar' : 'Crear'}
      InputsJson={field ? inputEditField : inputNewField}
      data={field ? capitalizeJson(field) : {}}
      onSubmit={field ? onEdit : onSubmit}
      onCancel={onClose}
    />
  )
}

export default FieldForm

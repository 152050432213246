const BoxFixed = ({ onClose }) => {
  return (
    <div
      onClick={onClose}
      style={{
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        background: '#05269107',
        top: '0',
        left: '0',
        zIndex: '0',
      }}
    ></div>
  )
}

export default BoxFixed

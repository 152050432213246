import { validateStatus } from '../../utils/Validate'
import { inputAddLoan } from '../../utils/FormFields'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { postAction } from '../../services/actions/AuthAction'

const CreateLoan = (props) => {
  const { reload, procedure, changeModal, openSnackbar } = props
  const [load, setload] = useState(false)
  async function onSubmit(values) {
    try {
      await postAction(`/loans/procedures/${procedure.id}`, {
        ...values,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se registro exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo crear', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  return (
    <FormDefault
      load={load}
      title="Formulario de préstamo"
      textBtn="Prestar"
      InputsJson={inputAddLoan}
      data={{}}
      onSubmit={onSubmit}
      onCancel={() => changeModal(false, null)}
    />
  )
}

export default CreateLoan

import { useAuthAxios } from '../../hook'
import { putAction } from '../../services/actions/AuthAction'
import { validateArray, validateStatus } from '../../utils/Validate'
import { TextH2 } from '../../components/Text'
import { GlobalButton } from '../../components/buttons'
import Loading from '../../components/animation/Loading'
import { useState } from 'react'
import './style.css'
const RecoverSteps = (props) => {
  const { changeModal, openSnackbar, reload } = props
  const { response, loading, reload: reloadResp } = useAuthAxios({
    method: 'GET',
    url: `/processstep/deletedProcessSteps`,
  })
  function handleReload() {
    reload()
    reloadResp()
  }
  return (
    <div>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Recuperar pasos
      </TextH2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((step) => (
          <ProcessStep
            key={step.id}
            step={step}
            changeModal={changeModal}
            reload={handleReload}
            openSnackbar={openSnackbar}
          />
        ))
      ) : (
        <TextH2 fontSize="2rem" color="var(--blue)">
          No tienes pasos que recuperar
        </TextH2>
      )}
    </div>
  )
}
function ProcessStep(props) {
  const { step, changeModal, reload, openSnackbar } = props
  const [load, setload] = useState(false)
  async function handleRecoverStep() {
    setload(true)
    try {
      await putAction(`/processstep/recoverProcessStep/${step.id}`, {}).then(
        (res) => {
          setload(false)
          if (validateStatus(res.status)) {
            openSnackbar('Se recuperó exitosamente', true, true)
            changeModal(false, null)
            reload()
          } else {
            openSnackbar('No se pudo recuperar', true, false)
          }
        },
      )
    } catch (e) {
      setload(false)
    }
  }
  return (
    <div className="RecoverSteps__processStep">
      <TextH2 fontSize="1.6rem" color="var(--gray)" margin="0">
        {step.name}
      </TextH2>
      {load ? (
        <Loading />
      ) : (
        <GlobalButton
          onClick={handleRecoverStep}
          width="fit-content"
          background="#458e7f1a"
          color="var(--blue)"
          border="none"
        >
          <i className="fas fa-redo"></i>
        </GlobalButton>
      )}
    </div>
  )
}
export default RecoverSteps

import { BoxFlex, ToolTip } from '../boxes'
import { TextH3 } from '../Text'
import { validateArray } from '../../utils/Validate'
const Bar = (props) => {
  const { data, onClick = () => {}, params } = props
  const dataMaxSum = data
    .map((item) => item.data)
    .reduce((prev, curr) => prev + curr, 0)
  const dataMax = Math.max(...data.map((item) => item.data))
  return (
    <div className="Bar">
      <BoxFlex gap="10px" justifyContent="space-around" padding="0 5px">
        {validateArray(data) &&
          data.map((item, index) => {
            const {
              percentageCalc = `${
                dataMax === 0
                  ? 0
                  : ((100 * item.percentage) / dataMaxSum).toFixed(2)
              } `,
            } = item
            return (
              <div key={index}>
                <ToolTip
                  content={
                    <>
                      <TextH3
                        margin="0"
                        minWidth="fit-content"
                        color="var(--gray)"
                      >
                        {item.name}
                      </TextH3>
                      <TextH3
                        margin="0"
                        minWidth="fit-content"
                        color="var(--gray)"
                      >
                        Cantidad: <strong>{item.data}</strong>
                      </TextH3>
                      <TextH3
                        margin="0"
                        minWidth="fit-content"
                        color="var(--gray)"
                      >
                        Porcentaje: <strong>{`${percentageCalc} %`}</strong>
                      </TextH3>
                      {item.skipped > 0 ? (
                        <>
                          <TextH3
                            margin="0"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Tramites que saltaron el paso
                          </TextH3>
                          <TextH3
                            margin="0"
                            minWidth="fit-content"
                            color="var(--gray)"
                          >
                            Cantidad: <strong>{item.skipped}</strong>
                          </TextH3>
                        </>
                      ) : null}
                    </>
                  }
                >
                  <div
                    onClick={() => onClick(item, params)}
                    style={{
                      height: '100px',
                      background: 'rgba(128, 128, 128, 0.4)',
                      width: '10px',
                      margin: '0 auto',
                      borderRadius: '10px',
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        height: `${
                          dataMax === 0
                            ? 0
                            : (100 *
                                (item.percentage +
                                  (item.skipped > 0 ? item.skipped : 0))) /
                              dataMax
                        }%`,
                        minHeight: item.percentage === 0 ? 'auto' : '12%',
                        background: 'red',
                        width: '10px',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div
                        style={{
                          height: `${
                            dataMax === 0
                              ? 0
                              : (100 * item.percentage) /
                                (item.percentage +
                                  (item.skipped > 0 ? item.skipped : 0))
                          }%`,
                          minHeight: item.percentage === 0 ? 'auto' : '12%',
                          background: item.color,
                          width: '10px',
                          borderRadius: '10px',
                        }}
                      ></div>
                    </div>
                  </div>
                </ToolTip>
                <div className="Bar__label">
                  <TextH3 minWidth="max-content" color="var(--gray)">
                    {item.label}
                  </TextH3>
                </div>
              </div>
            )
          })}
      </BoxFlex>
    </div>
  )
}

export default Bar

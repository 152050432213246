import { useEffect } from "react";
import { useAuthAxios } from "../../hook";
import { TextH3 } from "../../components/Text";
import { CardBox } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { GraphicsContainer } from "../../components/graphics";
import { Link } from "react-router-dom";

const TotalProcedures = (props) => {
  const { cid, year, history } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/clientStats/companies/${cid}?internCode=${year}`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [year]);

  function onClick(item) {
    history.push(
      `/list-procedure/${cid}?status=${
        item.label === "Terminados" ? "finished" : "inCourse"
      }`
    );
  }

  const TextLabel = ({ title, children, to }) => {
    return (
      <Link style={{ textDecoration: "none" }} to={to}>
        <TextH3 fontWeight="500" textAlign="start" color="var(--white)">
          {title}
          <br />
          <span style={{ fontSize: "2.2rem" }}>{children}</span>
        </TextH3>
      </Link>
    );
  };

  return (
    <CardBox
      headerComponent={
        !loading && (
          <>
            <TextLabel to={`/list-procedure/${cid}`} title="Total de Trámites">
              {response.total}
            </TextLabel>
            <TextLabel
              to={`/list-procedure/${cid}?status=finished`}
              title="Terminados"
            >
              {response.concluidos}
            </TextLabel>
            <TextLabel
              to={`/list-procedure/${cid}?status=inCourse`}
              title="En progreso"
            >
              {response.proceso}
            </TextLabel>
          </>
        )
      }
      component={
        loading ? (
          <Loading />
        ) : (
          <>
            <TextH3
              textTransform="uppercase"
              fontWeight="700"
              textAlign="center"
              color="#465ffd"
              margin="0"
            >
              Total de Trámites
            </TextH3>
            <GraphicsContainer
              size="small"
              type="doughnut"
              onClick={onClick}
              data={[
                {
                  label: "En progreso",
                  color: "#F4E206",
                  data: response.proceso,
                  percentage: Math.ceil(
                    (100 * response.proceso) / response.total
                  ),
                },
                {
                  label: "Terminados",
                  color: "#7889FD",
                  data: response.concluidos,
                  percentage: Math.ceil(
                    (100 * response.concluidos) / response.total
                  ),
                },
              ]}
            />
          </>
        )
      }
    />
  );
};

export default TotalProcedures;

import { useState, useEffect } from 'react'
import axios from 'axios'
import config from '../config.json'

axios.defaults.baseURL = config.requestURL

axios.interceptors.request.use(
  (config) => {
    const token_seguridad = window.localStorage.getItem('token_seguridad')
    if (token_seguridad) {
      config.headers.Authorization = 'Bearer ' + token_seguridad
      return config
    }
  },
  (error) => {
    return Promise.reject(error)
  },
)
const useAxiosAuth = (axiosParams, load = true) => {
  const [response, setResponse] = useState(undefined)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [headers, setHeaders] = useState(undefined)

  const fetchData = async () => {
    try {
      const result = axiosParams?.url
        ? await axios.request(axiosParams)
        : { data: null, headers: null, status: 500 }
      setResponse(result.data)
      setHeaders(result.headers)
      setError(result.status)
    } catch (error) {
      setError(500)
    } finally {
      setLoading(false)
    }
  }
  function reload() {
    if (load) {
      setLoading(true)
    }
    fetchData()
  }

  useEffect(() => {
    const ac = new AbortController()
    fetchData()
    return () => ac.abort()
  }, [])

  return { response, headers, loading, error, reload }
}
export default useAxiosAuth

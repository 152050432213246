import { useState } from 'react'
import { inputLoadCompany, inputUploadUser } from '../../utils/FormFields'
import { FormDefault } from '../../components/forms'
import { validateStatus } from '../../utils/Validate'
import { postAction } from '../../services/actions/AuthAction'
const FileUpload = (props) => {
  const { handleModal, openSnackbar, reload, id, type } = props
  const [load, setload] = useState(false)
  async function onSubmit(values) {
    setload(true)
    let formData = new FormData()
    formData.append(
      'Name',
      values.Type === 'otro' ? values.TypeOther : values.Type,
    )
    formData.append(
      'Type',
      values.Type === 'otro' ? values.TypeOther : values.Type,
    )
    formData.append('File', values.File)
    formData.append(type, id)
    try {
      await postAction('/files', formData).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se subió exitosamente', true, true)
          reload()
          handleModal()
        } else {
          openSnackbar('No se pudo subir el archivo', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  return (
    <FormDefault
      load={load}
      title="Subir archivo"
      textBtn="Subir"
      InputsJson={type === 'CompanyId' ? inputLoadCompany : inputUploadUser}
      data={{}}
      onSubmit={onSubmit}
      onCancel={handleModal}
    />
  )
}

export default FileUpload

import { useState } from 'react'
import FileUpload from './FileUpload'
import AddUserCompany from './AddUserCompany'
import { useAuthAxios } from '../../hook'
import { BoxFlex, BoxCard, ToolTip } from '../../components/boxes'
import Loading from '../../components/animation/Loading'
import { validateArray, validateStatus } from '../../utils/Validate'
import { convertDate } from '../../utils/ConvertDate'
import {
  putAction,
  getAction,
  addDownloadHistory,
} from '../../services/actions/AuthAction'
import { GlobalButton, ActionButton } from '../../components/buttons'
import { TextH2, TextH3 } from '../../components/Text'
import { RenderModal, ModalConfirmation } from '../../components/modal'
import UserDetail from './UserDetail'
import FormUpdateCompany from './FormUpdateCompany'
import FormUploadImg from './FormUploadImg'
import ListNotification from './ListNotification'

const CompanyDetail = (props) => {
  const {
    company,
    openSnackbar,
    changeModal,
    handleReload,
    sesionUser: { location },
  } = props
  const [load, setload] = useState(false)
  const [modal, setmodal] = useState({
    status: false,
    view: null,
  })
  const { response, loading, reload } = useAuthAxios({
    method: 'GET',
    url: `/company/${company.id}`,
  })
  const {
    response: respCompany,
    loading: loadCompany,
    reload: relCompany,
  } = useAuthAxios({
    method: 'GET',
    url: `/company/${company.id}/ClientsCompany`,
  })
  function handleModal(view) {
    setmodal({
      status: !modal.status,
      view: view,
    })
  }
  async function enableOnSubmit() {
    try {
      await putAction(`/company/Activate/${company.id}`, {}).then((res) => {
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          reload()
          relCompany()
          handleReload()
          onCancel()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {}
  }
  function onCancel() {
    changeModal(true, <CompanyDetail {...props} />)
  }
  function handleEnable() {
    changeModal(
      true,
      <>
        <TextH2
          color="var(--blue)"
          fontSize="2rem"
          fontWeight="500"
          textAlign="center"
        >
          Estas seguro que quieres{' '}
          {response?.status === 'Activo' ? 'deshabilitar' : 'habilitar'} a la
          compañía:
        </TextH2>
        <TextH3 textAlign="center" fontWeight="600" color="var(--gray)">
          {response.razonSocial}
        </TextH3>
        <ActionButton
          onClick={enableOnSubmit}
          onCancel={onCancel}
          textBtn={response?.status === 'Activo' ? 'Deshabilitar' : 'Habilitar'}
        />
      </>,
    )
  }

  function handleUpdateCompany() {
    changeModal(
      true,
      <FormUpdateCompany
        {...props}
        // reload={reload}
        handleReload={handleReload}
        company={response}
        onCancel={() => changeModal(true, <CompanyDetail {...props} />, true)}
      />,
      false,
    )
  }

  function handleDetailPdf() {
    setload(true)
    getAction(`/company/${company.id}/CompanyDataPDF`)
      .then((response) => {
        setload(false)
        if (validateStatus(response.status)) {
          window.open(response.data, '_blank')
        }
      })
      .catch((e) => console.log(e))
  }

  function handleUploadImg() {
    changeModal(
      true,
      <FormUploadImg
        {...props}
        url={`/company/${company.id}/uploadLogo`}
        name={company.razonSocial}
        title="Subir logotipo"
        onCancel={() => changeModal(true, <CompanyDetail {...props} />)}
      />,
      false,
    )
  }

  function handleDeleteImg(fileId) {
    changeModal(
      true,
      <ModalConfirmation
        url={`/files/${fileId}`}
        textBtn="Eliminar"
        textSuccess="Se elimino el archivo"
        openSnackbar={openSnackbar}
        onCancel={() =>
          changeModal(
            true,
            <CompanyDetail {...props} />,
            true,
            // '800px'
          )
        }
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres eliminar este archivo?
        </TextH2>
      </ModalConfirmation>,
      false,
    )
  }
  function handleNotification() {
    changeModal(
      true,
      <ListNotification
        {...props}
        title={`Notificaciones de la compañía de ${response.razonSocial}`}
        titleForm={`Enviar notificación a los usuarios de ${response.razonSocial}`}
        users={respCompany}
        onCancel={() => changeModal(true, <CompanyDetail {...props} />, true)}
      />,
      false,
    )
  }

  const TextData = ({ children, title }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        margin: '5px 0',
        padding: '5px 5px 10px',
      }}
    >
      <TextH2 margin="0">{title}:</TextH2>
      <TextH3 margin="0 auto" justifySelf="center" color="var(--gray)">
        {children}
      </TextH3>
    </div>
  )

  return (
    <>
      <BoxFlex
        justifyContent="space-between"
        flexWrap="wrap-reverse"
        marginBottom="10px"
      >
        <TextH2 fontSize="2rem" fontWeight="600">
          Detalles de la compañía
        </TextH2>
        <BoxFlex justifyContent="flex-end">
          {loading ? null : (
            <GlobalButton onClick={handleEnable} width="fit-content">
              {response?.status === 'Activo' ? 'Deshabilitar' : 'Habilitar'}
            </GlobalButton>
          )}
          {!loadCompany && validateArray(respCompany) ? (
            <ToolTip content="Enviar notificación">
              <div>
                <GlobalButton onClick={handleNotification} width="fit-content">
                  <i className="fas fa-bullhorn"></i>
                </GlobalButton>
              </div>
            </ToolTip>
          ) : null}
          <ToolTip content="Exportar en PDF">
            <div>
              <GlobalButton onClick={handleDetailPdf} width="fit-content">
                <i className="fas fa-file-pdf"></i>
              </GlobalButton>
            </div>
          </ToolTip>
          <ToolTip content="Editar compañía">
            <div>
              <GlobalButton
                background="#052691"
                color="#e2e4f3"
                onClick={handleUpdateCompany}
                width="fit-content"
              >
                <i className="fas fa-edit"></i>
              </GlobalButton>
            </div>
          </ToolTip>
        </BoxFlex>
      </BoxFlex>
      {loading ? null : (
        <>
          <TextData title="Razon Social">{response.razonSocial}</TextData>
          <TextData title="NIT">{response.nit}</TextData>
          <TextData title="Fecha de creación">
            {convertDate(response.creationDate, 'LLLL')}
          </TextData>
          {validateArray(response.legalRepresentatives) && (
            <TextData title="Representante legal">
              {response.legalRepresentatives[0].name}
            </TextData>
          )}
          <TextData title="Estado">{response.status}</TextData>
          {response.logoUrl && (
            <TextData title="Logotipo">
              <img
                style={{
                  height: '60px',
                  border: '1px solid var(--gray)',
                  borderRadius: '10px',
                }}
                src={response.logoUrl}
                alt="logotipo"
              />
            </TextData>
          )}
          <br />
          <BoxFlex justifyContent="space-between">
            <TextH2
              fontSize="1.8rem"
              fontWeight="500"
              textAlign="center"
              margin="0"
            >
              Usuarios de la compañía
            </TextH2>
            <GlobalButton
              onClick={() => handleModal('formUser')}
              width="fit-content"
            >
              Adicionar usuarios
            </GlobalButton>
          </BoxFlex>
          <br />
          {!loadCompany && validateArray(respCompany) ? (
            respCompany.map((user) => (
              <CardUser
                {...props}
                key={user.id}
                user={user}
                reload={relCompany}
              />
            ))
          ) : (
            <TextH3>No tienes usuarios</TextH3>
          )}
          <br />
          <BoxFlex justifyContent="space-between">
            <TextH2 fontSize="1.8rem" fontWeight="500" textAlign="center">
              Archivos de la compañía
            </TextH2>
            {load ? (
              <Loading />
            ) : (
              <BoxFlex justifyContent="flex-end">
                <GlobalButton onClick={handleUploadImg} width="fit-content">
                  Subir logotipo
                </GlobalButton>
                <GlobalButton
                  onClick={() => handleModal('formFile')}
                  width="fit-content"
                  background="#052691"
                  color="#e2e4f3"
                >
                  Subir archivo
                </GlobalButton>
              </BoxFlex>
            )}
          </BoxFlex>
          <BoxFlex flexWrap="wrap" gap="10px 20px">
            {validateArray(response.files) ? (
              response.files.map((item) => (
                <BoxFlex key={item.id}>
                  <TextH3 color="var(--blue)" margin="0 0 5px">
                    <a
                      target="_blank"
                      onClick={() =>
                        addDownloadHistory(
                          item.name,
                          item.url,
                          company.id,
                          'company',
                          location,
                        )
                      }
                      href={item.url}
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  </TextH3>
                  <GlobalButton
                    height="20px"
                    lineHeight="10px"
                    fontSize="1.4rem"
                    onClick={() => handleDeleteImg(item.id)}
                    width="fit-content"
                  >
                    <i className="fas fa-times"></i>
                  </GlobalButton>
                </BoxFlex>
              ))
            ) : (
              <TextH3>No se subio archivos</TextH3>
            )}
          </BoxFlex>
        </>
      )}
      {modal.status && (
        <RenderModal
          width="500px"
          onClose={() => handleModal(null)}
          closeOutside
        >
          {modal.view === 'formFile' ? (
            <FileUpload
              handleModal={() => handleModal(null)}
              modal={modal}
              openSnackbar={openSnackbar}
              reload={reload}
              id={company.id}
              type="CompanyId"
            />
          ) : (
            <AddUserCompany
              {...props}
              handleModal={() => handleModal(null)}
              modal={modal}
              reload={relCompany}
            />
          )}
        </RenderModal>
      )}
    </>
  )
}

const CardUser = (props) => {
  const { user, changeModal, openSnackbar, reload, company } = props
  const [load, setload] = useState()
  async function handleEnableUser(user) {
    try {
      setload(true)
      await putAction(`/users/Activate/${user.id}`, {}).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }
  async function handleDeleteUser(user) {
    try {
      setload(true)
      await putAction(`/company/${company.id}/userId/${user.id}`, {}).then(
        (res) => {
          setload(false)
          if (validateStatus(res.status)) {
            openSnackbar('Se guardo exitosamente', true, true)
            reload()
          } else {
            openSnackbar('No se pudo guardar', true, false)
          }
        },
      )
    } catch (e) {
      setload(false)
    }
  }

  function handleEditUser(user) {
    changeModal(true, <UserDetail {...props} user={user} />)
  }
  return (
    <BoxFlex alignItems="">
      <BoxCard width="100%" onClick={() => handleEditUser(user)}>
        <BoxFlex justifyContent="space-between" flexWrap="wrap">
          <TextH3 color="var(--blue)" margin="0 0 5px" fontWeight="600">
            {user.name} {user.fatherLastName} {user.motherLastName}
          </TextH3>
          <TextH3 color="var(--blue)" margin="0 0 5px">
            {user.status === 'Activo' ? 'Activado' : 'Desactivado'}
          </TextH3>
        </BoxFlex>
        <BoxFlex justifyContent="space-between" flexWrap="wrap">
          {user.cellphoneNumber && (
            <TextH3 color="var(--gray)" margin="0">
              Nro. de celular: {user.cellphoneNumber}
            </TextH3>
          )}
          <TextH3 color="var(--gray)" margin="0">
            {user.email}
          </TextH3>
        </BoxFlex>
      </BoxCard>
      {load ? (
        <Loading />
      ) : (
        <>
          <GlobalButton
            margin="0 0 5px"
            onClick={() => handleEnableUser(user)}
            width="fit-content"
          >
            <i
              className={`fas fa-${
                user.status === 'Activo' ? 'eye-slash' : 'eye'
              }`}
            ></i>
          </GlobalButton>
          <GlobalButton
            margin="0 0 5px"
            onClick={() => handleDeleteUser(user)}
            width="fit-content"
            background="#c93f3f1a"
            color="var(--red)"
          >
            <i className="fas fa-trash"></i>
          </GlobalButton>
        </>
      )}
    </BoxFlex>
  )
}

export default CompanyDetail

import { validateArray, validateStatus } from '../../utils/Validate'
import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { TextH3 } from '../../components/Text'
import { postAction, putAction } from '../../services/actions/AuthAction'

const CreateLoan = (props) => {
  const { changeModal, openSnackbar } = props
  const [load, setload] = useState(false)

  async function onSubmit(values, procedures) {
    try {
      handleReadH(0, procedures, values)
    } catch (e) {
      setload(false)
    }
  }

  function handleReadH(index, procedures, values) {
    postAction(`/procedure/readHPoint/${procedures[index].id}`).then((res) => {
      if (!validateStatus(res.status)) {
        openSnackbar('No se pudo leer el archivo', true, false)
      }
      if (index < procedures.length - 1) {
        handleReadH(index + 1, procedures, values)
      } else {
        submitGenerate(values)
      }
    })
  }

  async function submitGenerate(values) {
    await putAction(
      '/procedure/Xlsx/hs',
      validateArray(values.company)
        ? values.company.filter(
            (n, index) => n && values.company.indexOf(n) === index,
          )
        : [],
    ).then((res) => {
      setload(false)
      if (validateStatus(res.status)) {
        if (res.data === 'list_empty') {
          openSnackbar('Se genero un documento vació', true, false)
        } else {
          window.open(res.data.url, '_blank')
        }
      } else {
        openSnackbar('No se pudo generar el documento', true, false)
      }
    })
  }

  async function handlePreprocess(values) {
    setload(true)
    await putAction(
      `/procedure/NoReadDim?readH=true`,
      validateArray(values.company)
        ? values.company.filter(
            (n, index) => n && values.company.indexOf(n) === index,
          )
        : [],
    ).then((res) => {
      if (validateStatus(res.status)) {
        if (validateArray(res.data)) {
          onSubmit(values, res.data)
        } else {
          submitGenerate(values)
        }
      } else {
        setload(false)
        openSnackbar('No se pudo generar el reporte', true, false)
      }
    })
  }

  return (
    <>
      <FormDefault
        load={load}
        title="Formulario para generar el reporte por ITEMS"
        textBtn="Generar"
        InputsJson={[
          {
            control: 'getListOption',
            initial: [],
            validate: null,
            label: 'Lista de compañías',
            name: 'company',
            url: '/company/getallcompanies',
            keyOption: 'razonSocial',
            value: 'id',
          },
        ]}
        data={{}}
        onSubmit={handlePreprocess}
        onCancel={() => changeModal(false, null)}
      />
      {load && (
        <TextH3 textAlign="center" color="var(--blue)">
          Esto puede tardar varios minutos
        </TextH3>
      )}
    </>
  )
}

export default CreateLoan

import { useEffect } from "react";
import { useAuthAxios } from "../../hook";
import { TextH3 } from "../../components/Text";
import { CardBox } from "../../components/boxes";
import { validateArray } from "../../utils/Validate";
import { Loading } from "../../components/animation";
import { GraphicsContainer } from "../../components/graphics";
import { Link } from "react-router-dom";

const ClearanceCustoms = ({ cid, year, history }) => {
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/clientStats/companies/${cid}/perCustoms?internCode=${year}`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [year]);

  function handleCustoms(data) {
    let aux = [];
    if (validateArray(data)) {
      data.forEach((element) => {
        aux.push({
          label: element.name,
          name: element.name,
          data: element.amount,
          percentage: element.amount,
          color: "#7889FD",
        });
      });
    }
    return aux;
  }

  function onClick(item) {
    history.push(`/list-procedure/${cid}?customsClearance=${item.name}`);
  }

  return (
    <CardBox
      headerComponent={
        !loading && (
          <>
            {validateArray(response) &&
              response.map((customs, index) => {
                if (index < 3) {
                  return (
                    <Link
                      key={index}
                      to={`/list-procedure/${cid}?customsClearance=${customs.name}`}
                      style={{ textDecoration: "none" }}
                    >
                      <TextH3
                        width="max-content"
                        fontWeight="500"
                        textAlign="start"
                        color="var(--white)"
                      >
                        {customs.name}
                        <br />
                        <span style={{ fontSize: "2.2rem" }}>
                          {customs.amount}
                        </span>
                      </TextH3>
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
          </>
        )
      }
      component={
        loading ? (
          <Loading />
        ) : (
          <>
            <TextH3
              textTransform="uppercase"
              fontWeight="700"
              textAlign="center"
              color="#465ffd"
              margin="10px 0 5px"
            >
              Aduanas de despachos
            </TextH3>
            <GraphicsContainer
              size="small"
              type="barList"
              onClick={onClick}
              data={handleCustoms(response)}
            />
          </>
        )
      }
    />
  );
};

export default ClearanceCustoms;

import { TextH2, TextH3 } from '../../components/Text'
import { TableFilters } from '../../components/table'

const ProceduresRegularize = () => {
  function handleColor(item, name) {
    const days = item[name].split('.')[0]
    return (
      <TextH3
        background={
          parseInt(days) > 18
            ? 'var(--green)'
            : parseInt(days) > 5
            ? 'var(--green)'
            : 'var(--red)'
        }
        margin="0"
        padding="2px 10px 5px"
        borderRadius="15px"
        width="fit-content"
        color="var(--white)"
      >
        {days} / 20
      </TextH3>
    )
  }
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Regularización de anticipados
      </TextH2>
      <br />
      <TableFilters
        params="/procedure/regularized"
        header={[
          { key: 'interNumber', name: 'Número de interno' },
          { key: 'dimNumber', name: 'Número de DIM o DEX' },
          { key: 'invoiceNumber', name: 'Número de factura' },
          { key: 'orderNumber', name: 'Número de pedido' },
          { key: 'company', name: 'Compañía' },
          {
            key: 'assignedLiquidator',
            name: 'Liquidador asignado',
            type: 'text',
          },
          {
            key: 'receptionDate',
            name: 'Fecha de parte de recepción',
            type: 'text',
          },
          {
            key: 'receptionDateAdd',
            name: 'Fecha de vencimiento parte de recepción',
            type: 'text',
          },
          {
            key: 'remainingDays',
            name: 'Dias para regularizar la DIM (Parte de recepción)',
            type: 'validate',
            validate: handleColor,
          },
          {
            key: 'chanelDate',
            name: 'Fecha de asignación de canal',
            type: 'text',
          },
          {
            key: 'chanelDateAdd',
            name: 'Fecha de vencimiento asignación de canal',
            type: 'text',
          },
          {
            key: 'remainingChanelDays',
            name: 'Dias para regularizar la DIM (Asignación de canal)',
            type: 'validate',
            validate: handleColor,
          },
        ]}
      />
    </>
  )
}

export default ProceduresRegularize

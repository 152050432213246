import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
// import "./style/RadioButton.css";

function RadioButtons(props) {
  const {
    label,
    name,
    options = [
      { key: "Si", value: `true;${name}` },
      { key: "No", value: `false;${name}` },
    ],
    ...rest
  } = props;
  return (
    <div className="RadioButtons">
      <label className="RadioButtons__label">{label}</label>
      <div className="RadioButtons__itemContainer">
        <Field name={name}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <div className="RadioButtons__item" key={option.key}>
                  <input
                    type="radio"
                    className="RadioButtons__input--rb"
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label htmlFor={option.value}>{option.key}</label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default RadioButtons;

import { useAuthAxios } from '../../hook'
import { validateStatus } from '../../utils/Validate'
import { TableDefault, TableFooter } from './'
import Loading from '../animation/Loading'
import { TextH3 } from '../Text'
const TableFilters = (props) => {
  const {
    header,
    functionClick,
    urlParam,
    seturl,
    url,
    handleAddHistory = () => {},
    reload,
    filters,
  } = props

  const { response, loading, headers, error } = useAuthAxios({
    method: 'GET',
    url: urlParam,
  })

  return (
    <>
      {loading ? (
        <Loading />
      ) : validateStatus(error) ? (
        <>
          <TableDefault
            functionClick={(item) => functionClick(item, reload)}
            filters={filters}
            header={header}
            main={response}
            reload={reload}
            handleAddHistory={handleAddHistory}
          />
          <br />
          <TableFooter
            seturl={seturl}
            url={url}
            reload={reload}
            pageInfo={headers}
            amount={response.length}
          />
        </>
      ) : (
        <TextH3 textAlign="center" color="var(--red)">
          <i className="fas fa-exclamation-circle"></i> Ha ocurrido un error
        </TextH3>
      )}
    </>
  )
}

export default TableFilters

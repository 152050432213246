import { TableFilters } from '../../components/table'
import { GlobalButton } from '../../components/buttons'
import { BoxFlex } from '../../components/boxes'
import { TextH2, TextH3, SubTitle } from '../../components/Text'
import { connect } from 'react-redux'
import { changeModal, openSnackbar } from '../../store/actions'
import { validateArray, validateStatus } from '../../utils/Validate'
import { procedureReadDex } from '../../utils/Constant'
import {
  addDownloadHistory,
  putAction,
  postAction,
} from '../../services/actions/AuthAction'
import EditProcedure from '../../containers/editDataset/EditProcedure'
import { GenerateRoute } from '../../containers/procedure'

const ClosedProcedures = (props) => {
  const {
    changeModal,
    match: { params },
  } = props
  function handleClick(procedure) {
    changeModal(
      true,
      <DetailProcedure
        {...props}
        procedure={procedure}
        steps={Array(procedure.totalSteps).fill('')}
        progress={(procedure.step * 100) / procedure.totalSteps}
      />,
      true,
      '900px',
    )
  }

  function handleEditProcedure(procedure, reload) {
    changeModal(
      true,
      <EditProcedure
        {...props}
        procedure={procedure}
        reload={reload}
        onCancel={() => changeModal(false, null)}
      />,
      false,
    )
  }

  function handleGenerateRoute(procedure) {
    changeModal(
      true,
      <GenerateRoute {...props} procedure={procedure} />,
      true,
      '900px',
    )
  }
  function onSubmit() {
    handleParte(0, procedureReadDex)
  }
  async function handleParte(index, list) {
    await putAction(`/files/convertPdfToCsv/${list[index].ProcedureId}`, {
      name: 'adjuntarDex',
    }).then(async () => {
      await postAction(`/files/DataDim/${list[index].ProcedureId}`, {
        name: 'adjuntarDex',
      }).then(async (resData) => {
        changeModal(
          true,
          <TextH3 fontSize="1.8rem" color="var(--blue)">
            {index} de {list.length}
          </TextH3>,
          true,
          '300px',
        )
        if (!validateStatus(resData.status)) {
          openSnackbar('No se pudo subir el archivo', true, false)
        }
        if (index < list.length - 1) {
          handleParte(index + 1, list)
        } else {
          changeModal(false, null)
          console.log('terminado', index + 1, list)
        }
      })
    })
  }

  return (
    <>
      <BoxFlex justifyContent="space-between">
        <TextH2 fontSize="2rem" color="var(--blue)">
          Trámites terminados
        </TextH2>
        <GlobalButton onClick={onSubmit} width="auto">
          Leer todas las exportaciones
        </GlobalButton>
      </BoxFlex>
      <br />
      <TableFilters
        params={`/procedure/closedprocedures/${params.id}`}
        functionClick={handleClick}
        header={[
          {
            key: 'internCode',
            name: 'Número de interno',
            type: 'textAction',
            action: handleGenerateRoute,
          },
          { key: 'orderNumber', name: 'Número de pedido' },
          { key: 'dimNumber', name: 'Número de DIM o DEX' },
          { key: 'billNumber', name: 'Número de factura' },
          {
            key: 'customsClearance',
            name: 'Número de aduana',
            type: 'text',
          },
          { key: 'companyName', name: 'Compañía', type: 'text' },
          {
            key: handleEditProcedure,
            name: 'Acciones',
            textBtn: 'Editar trámite',
            type: 'action',
          },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </>
  )
}
const DetailProcedure = (props) => {
  const {
    procedure,
    steps,
    progress,
    sesionUser: { location },
  } = props

  function handleAddHistory(label, initial) {
    addDownloadHistory(label, initial, procedure.id, 'procedure', location)
  }
  return (
    <div className="DetailProcedure">
      <TextH3
        textAlign="center"
        fontWeight="600"
        fontSize="1.8rem"
        color="var(--blue)"
      >
        Detalles de trámite
      </TextH3>
      <TextH3 fontSize="1.8rem" color="var(--blue)">
        Progreso del trámite
      </TextH3>
      <div className="DetailProcedure__process">
        <div
          className="DetailProcedure__linearBar"
          style={{
            background: `linear-gradient(90deg, var(--blue) ${progress}%, #c2c2c2 ${progress}%)`,
          }}
        ></div>
        <div className="DetailProcedure__circle">
          {validateArray(steps) &&
            steps.map((_, index) => (
              <div
                style={{
                  background:
                    procedure.step <= index ? '#c2c2c2' : 'var(--blue)',
                }}
                key={index}
              ></div>
            ))}
        </div>
      </div>
      <br />
      <SubTitle subTitle="Número de interno">{procedure.internCode}</SubTitle>
      <SubTitle subTitle="Compañía">{procedure.companyName}</SubTitle>
      <br />
      <br />
      <TableFilters
        params={`/field/fieldsbyprocedureId/${procedure.id}`}
        functionClick={() => {}}
        header={[
          { key: 'label', name: 'Etiqueta' },
          { key: 'initial', name: 'Valor', type: 'anchor' },
          { key: 'type', name: 'Tipo de dato' },
        ]}
        handleAddHistory={handleAddHistory}
      />
      <br />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal, openSnackbar })(
  ClosedProcedures,
)

import './style/Search.css'
import { BoxFlex } from '../boxes'
import { GlobalButton } from '../buttons'
import { validateArray } from '../../utils/Validate'
const Search = (props) => {
  const { options, onChange, onClick, handleClear, search } = props
  function onKeyPressed(e) {
    if (e.key === 'Enter') {
      onClick()
    } else if (e.key === 'Escape') {
      handleClear()
    }
  }
  return (
    <BoxFlex flexWrap="wrap">
      <div onKeyDown={onKeyPressed} tabIndex={0} className="searchContainer">
        <i className="fas fa-search"></i>
        <input
          type="text"
          name="search"
          value={search.search}
          className="searchContainer__input"
          placeholder="Buscar..."
          onChange={onChange}
          autoFocus
        />
        <select className="Search__select" onChange={onChange} name="field">
          {validateArray(options)
            ? options.map((option) => {
                return (
                  <option key={option.key} value={option.search || option.key}>
                    {option.name}
                  </option>
                )
              })
            : null}
        </select>
        <i onClick={handleClear} className="fas fa-times" id="times"></i>
      </div>
      <GlobalButton onClick={onClick} width="fit-content">
        Buscar <i className="fas fa-search"></i>
      </GlobalButton>
    </BoxFlex>
  )
}

export default Search

import * as Yup from "yup";
import { makeid } from "./ProcessData";
import { convertDate } from "./ConvertDate";
export const validateForm = {
  text: Yup.string().required("Campo requerido"),
  switch: Yup.boolean().typeError("Campo requerido"),
  checkbox: true,
  selectGet: Yup.string().required("Campo requerido"),
  textarea: Yup.string().required("Campo requerido"),
  number: Yup.number().required("Campo requerido"),
  file: Yup.mixed().required("Campo requerido.").nullable(),
  descriptionCheck: Yup.object({
    status: Yup.boolean().typeError("Campo requerido"),
    content: Yup.string().when("status", {
      is: false,
      then: Yup.string().required("Campo requerido"),
    }),
  }),
};
export const inputsClient = [
  {
    name: "RazonSocial",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Razón social",
  },
  {
    name: "Nit",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Número de Identificación Tributaria (NIT)",
  },
  {
    name: "LegalRepresentative",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Representante legal",
  },
];

export const inputsUser = [
  {
    name: "Username",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Usuario",
  },
  {
    name: "Email",
    initial: "",
    validate: Yup.string()
      .email("No parece un correo")
      .required("Campo requerido"),
    type: "text",
    label: "Correo electronico",
  },
  {
    name: "Password",
    initial: makeid(7),
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Contraseña",
  },
  {
    control: "getCheckbox",
    initial: "",
    validate: null,
    label: "Rol del usuario",
    name: "Rol",
    url: "/rol",
    keyOption: "label",
    value: "id",
  },
  /* {
    control: 'getSelect',
    initial: '',
    validate: Yup.string(),
    label: 'Compañía',
    name: 'CompanyId',
    url: `/company/${window.localStorage.getItem('id')}/userId`,
    keyOption: 'razonSocial',
    value: 'id',
    reference: 'Rol',
    condition: '3',
  }, */
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombres",
  },
  {
    name: "FatherLastName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Apellido paterno",
  },
  {
    name: "MotherLastName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Apellido materno",
  },
  {
    name: "Ci",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Carnet de identidad",
  },
  {
    name: "City",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Departamento",
    options: [
      { key: "Departamento:", value: "" },
      { key: "La Paz", value: "La Paz" },
      { key: "Santa Cruz", value: "Santa Cruz" },
      { key: "Chuquisaca", value: "Chuquisaca" },
      { key: "Cochabamba", value: "Cochabamba" },
      { key: "Oruro", value: "Oruro" },
      { key: "Potosí", value: "Potosí" },
      { key: "Tarija", value: "Tarija" },
      { key: "Beni", value: "Beni" },
      { key: "Pando", value: "Pando" },
    ],
  },
  {
    name: "CellphoneNumber",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Numero de celular",
  },
  {
    name: "PhoneNumber",
    initial: "",
    validate: Yup.string(),
    type: "number",
    label: "Numero de telefono",
  },
];

export const inputUpdateCompany = [
  {
    name: "RazonSocial",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Razón social",
  },
  {
    name: "Nit",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Número de Identificación Tributaria (NIT)",
  },
];
export const inputUpdateUser = [
  {
    name: "Username",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Usuario",
  },
  {
    name: "Email",
    initial: "",
    validate: Yup.string()
      .email("No parece un correo")
      .required("Campo requerido"),
    type: "text",
    label: "Correo electronico",
  },
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombres",
  },
  {
    name: "FatherLastName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Apellido paterno",
  },
  {
    name: "MotherLastName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Apellido materno",
  },
  {
    name: "CellphoneNumber",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Numero de celular",
  },
  {
    name: "City",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Departamento",
    options: [
      { key: "Departamento:", value: "" },
      { key: "La Paz", value: "La Paz" },
      { key: "Santa Cruz", value: "Santa Cruz" },
      { key: "Chuquisaca", value: "Chuquisaca" },
      { key: "Cochabamba", value: "Cochabamba" },
      { key: "Oruro", value: "Oruro" },
      { key: "Potosí", value: "Potosí" },
      { key: "Tarija", value: "Tarija" },
      { key: "Beni", value: "Beni" },
      { key: "Pando", value: "Pando" },
    ],
  },
  {
    control: "getCheckbox",
    initial: "",
    validate: null,
    label: "Rol del usuario",
    name: "Rol",
    url: "/rol",
    keyOption: "label",
    value: "id",
  },
];
export const inputUploadUser = [
  {
    name: "Type",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Descripción de archivo",
    options: [
      { key: "Seleccione el tipo de archivo", value: "" },
      { key: "Hoja de vida", value: "Hoja de vida" },
      { key: "Croquis domicilio", value: "Croquis domicilio" },
      { key: "Factura de Luz o Agua", value: "Factura de Luz o Agua" },
      { key: "Curriculum VItae", value: "Curriculum VItae" },
      { key: "CARNET DE IDENTIDAD", value: "CARNET DE IDENTIDAD" },
      { key: "CERTIFICADO FELCC", value: "CERTIFICADO FELCC" },
      { key: "CERTIFICADO FELCN", value: "CERTIFICADO FELCN" },
      { key: "CERTIFICADO REJAP", value: "CERTIFICADO REJAP" },
      {
        key: "LISTA DE VERIFICACION PARA LA INDUCCION DEL PERSONAL NUEVO",
        value: "LISTA DE VERIFICACION PARA LA INDUCCION DEL PERSONAL NUEVO",
      },
      {
        key: "CARTA DE PRESENTACION DE LA EMPRESA AL NUEVO PERSONAL",
        value: "CARTA DE PRESENTACION DE LA EMPRESA AL NUEVO PERSONAL",
      },
      {
        key: "PAUTAS DE ACTUACION PROFESIONAL",
        value: "PAUTAS DE ACTUACION PROFESIONAL",
      },
      {
        key: "Otro (Nuevo documento indeterminado)",
        value: "otro",
      },
    ],
  },
  {
    name: "TypeOther",
    initial: "",
    validate: Yup.string().when("Type", {
      is: "otro",
      then: Yup.string().required("Campo requerido."),
    }),
    type: "text",
    value: null,
    label: "Descripción",
    reference: "Type",
    condition: "otro",
  },
  {
    name: "File",
    control: "file",
    initial: null,
    validate: Yup.mixed().required("Campo requerido.").nullable(),
    label: "Archivo",
    type: "",
  },
];
export const inputLoadCompany = [
  {
    name: "Type",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Descripción de archivo",
    options: [
      {
        key: "Seleccione el tipo de archivo",
        value: "",
      },
      {
        key: "1. Solicitud de Cliente Nuevo",
        value: "1. Solicitud de Cliente Nuevo",
      },
      {
        key: "2. NIT Numero de Identificación Tributaria",
        value: "2. NIT Numero de Identificación Tributaria",
      },
      {
        key: "3. Verificación del NIT",
        value: "3. Verificación del NIT",
      },
      {
        key: "4. CI Carnet de Identidad del representante Legal",
        value: "4. CI Carnet de Identidad del representante Legal",
      },
      {
        key: "5. Verificación de Cedula de Identidad",
        value: "5. Verificación de Cedula de Identidad",
      },
      {
        key: "6. Verificación Lista Interpol",
        value: "6. Verificación Lista Interpol",
      },
      {
        key: "7. Verificación Lista Clinton",
        value: "7. Verificación Lista Clinton",
      },
      {
        key: "8. Poder Notarial Representante Legal",
        value: "8. Poder Notarial Representante Legal",
      },
      {
        key: "9. Certificación de IMPORTADOR / EXPORTADOR",
        value: "9. Certificación de IMPORTADOR / EXPORTADOR",
      },
      {
        key: "10. Verificación de certificado de IMPORTADOR / EXPORTADOR",
        value: "10. Verificación de certificado de IMPORTADOR / EXPORTADOR",
      },
      {
        key: "11. Certificación ISO 9001/2015",
        value: "11. Certificación ISO 9001/2015",
      },
      {
        key: "12. Certificación OEA, BASC u otra similar",
        value: "12. Certificación OEA, BASC u otra similar",
      },
      {
        key: "13. Declaración de Seguridad Cliente",
        value: "13. Declaración de Seguridad Cliente",
      },
      {
        key: "14. Contratos si Aplicase",
        value: "14. Contratos si Aplicase",
      },
      { key: "15. Registro FUNDEMPRESA", value: "15. Registro FUNDEMPRESA" },
      {
        key: "16. Verificación registro fundempresa",
        value: "16. Verificación registro fundempresa",
      },
      { key: "17. Correo Programa OEA", value: "16. Correo Programa OEA" },
      { key: "Otro", value: "otro" },
    ],
  },
  {
    name: "TypeOther",
    initial: "",
    validate: Yup.string().when("Type", {
      is: "otro",
      then: Yup.string().required("Campo requerido."),
    }),
    type: "text",
    label: "Descripción",
    reference: "Type",
    condition: "otro",
  },
  {
    name: "File",
    control: "file",
    initial: null,
    validate: Yup.mixed().required("Campo requerido.").nullable(),
    label: "Archivo",
    type: "",
  },
];
export const inputUploadPhoto = [
  {
    name: "File",
    control: "file",
    initial: null,
    validate: Yup.mixed().required("Campo requerido.").nullable(),
    label: "Foto de perfil",
    type: "",
  },
];
export const inputEditStep = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del paso",
  },
  {
    control: "getCheckbox",
    initial: "",
    validate: null,
    label: "Rol del usuario",
    name: "Rol",
    url: "/rol",
    keyOption: "label",
    value: "id",
  },
];
export const inputNewStep = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del paso",
  },
  {
    name: "Step",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Nro. del paso",
  },
  {
    control: "getCheckbox",
    initial: "",
    validate: null,
    label: "Rol del usuario",
    name: "Rol",
    url: "/rol",
    keyOption: "label",
    value: "id",
  },
];

export const inputAddUserCompany = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Usuario clientes",
    name: "client",
    url: "/rol/client",
    keyOption: "name",
    value: "id",
  },
];
export const inputLiquidator = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Liquidador",
    name: "user",
    url: "/rol/liquidator",
    keyOption: "name",
    value: "id",
  },
];
export const inputChannel = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Seleccione el canal",
    name: "user",
    url: "/rol/Color",
    keyOption: "name",
    value: "name",
  },
];
export const inputRegimen = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Tipo de despacho",
    name: "user",
    url: "/rol/Regimen",
    keyOption: "name",
    value: "name",
  },
];
export const inputDocument = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Seleccione el archivo",
    name: "FieldId",
    url: "/field/fileFields",
    keyOption: "label",
    value: "id",
  },
  {
    name: "File",
    control: "file",
    initial: null,
    validate: Yup.mixed().required("Campo requerido.").nullable(),
    label: "Archivo",
    type: "",
  },
];
export const inputDataset = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Seleccione el archivo",
    name: "FieldId",
    url: "/field",
    keyOption: "label",
    value: "id",
  },
  {
    name: "File",
    control: "file",
    initial: null,
    validate: Yup.mixed().required("Campo requerido.").nullable(),
    label: "Archivo",
    type: "",
  },
];
export const inputNewField = [
  {
    name: "Label",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Etiqueta",
  },
  {
    name: "Validate",
    initial: true,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Campo requerido",
  },
  {
    name: "Type",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Tipo",
    options: [
      { key: "Tipo de campo:", value: "" },
      { key: "Texto", value: "text" },
      { key: "Numero", value: "number" },
      { key: "Archivo", value: "file" },
      { key: "Botón", value: "getButton" },
      { key: "Lista desplegable", value: "selectGet" },
      { key: "Casilla de verificación", value: "checkbox" },
      {
        key: "Casilla de verificación con descripción",
        value: "descriptionCheck",
      },
      // {
      //   key: 'Casilla de verificación descriptiva',
      //   value: 'checkboxDescription',
      // },
      { key: "Botón de encendido/apagado", value: "switch" },
      { key: "Caja de texto multi-línea", value: "textarea" },
    ],
  },
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string()
      .when("Type", {
        is: "selectGet",
        then: Yup.string().required("Campo requerido."),
      })
      .when("Type", {
        is: "getButton",
        then: Yup.string().required("Campo requerido."),
      }),
    label: "Seleccione la lista",
    name: "Url",
    url: "/rol/Getter",
    keyOption: "name",
    value: "route",
    reference: "Type",
    condition: "selectGet,getButton",
  },
];
export const inputEditField = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre",
  },
  {
    name: "Initial",
    initial: "",
    validate: null,
    type: "text",
    label: "Valor inicial",
  },
  {
    name: "Validate",
    initial: true,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Campo requerido",
  },
  {
    name: "Type",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Tipo",
    options: [
      { key: "Tipo de campo:", value: "" },
      { key: "Texto", value: "text" },
      { key: "Numero", value: "number" },
      { key: "Archivo", value: "file" },
      { key: "Botón", value: "getButton" },
      { key: "Lista desplegable", value: "selectGet" },
      { key: "Casilla de verificación", value: "checkbox" },
      {
        key: "Casilla de verificación con descripción",
        value: "descriptionCheck",
      },
      // {
      //   key: 'Casilla de verificación descriptiva',
      //   value: 'checkboxDescription',
      // },
      { key: "Botón de encendido/apagado", value: "switch" },
      { key: "Caja de texto multi-línea", value: "textarea" },
      { key: "Fecha", value: "date" },
    ],
  },
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string()
      .when("Type", {
        is: "selectGet",
        then: Yup.string().required("Campo requerido."),
      })
      .when("Type", {
        is: "getButton",
        then: Yup.string().required("Campo requerido."),
      }),
    label: "Seleccione la lista",
    name: "Url",
    url: "/rol/Getter",
    keyOption: "name",
    value: "route",
    reference: "Type",
    condition: "selectGet,getButton",
  },
  {
    name: "Label",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Etiqueta",
  },
];
export const inputCompany = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido."),
    label: "Seleccione la compañía",
    name: "CompanyId",
    url: "/company/getallcompanies",
    keyOption: "razonSocial",
    value: "id",
  },
];
export const inputEditProcedure = [
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido."),
    label: "Seleccione la compañía",
    name: "CompanyId",
    url: "/company/getallcompanies",
    keyOption: "razonSocial",
    value: "id",
  },
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido."),
    label: "Seleccione el tipo de procedimiento",
    name: "ProcedureTypeId",
    url: "/proceduretype",
    keyOption: "name",
    value: "id",
  },
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido."),
    label: "Seleccione el paso",
    name: "Step",
    url: "/processstep/GetProcessStepsByProcedureTypeId",
    urlInput: "ProcedureTypeId",
    keyOption: "name",
    value: "step",
    reference: "ProcedureTypeId",
    condition: "*",
  },
  {
    name: "ValidateReference",
    initial: true,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Validar nro de referencia",
  },
];

export const documentForOffice = [
  {
    name: "name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del documento",
  },
  {
    name: "number",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nº de documento",
  },
  {
    name: "doc",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Estado del documento",
    options: [
      { key: "Tipo de documento:", value: "" },
      { key: "ORIGINAL", value: "ORIGINAL" },
      { key: "COPIA", value: "COPIA" },
      { key: "FOTOCOPIA SIMPLE", value: "FOTOCOPIA SIMPLE" },
      { key: "LEGALIZADO", value: "LEGALIZADO" },
    ],
  },
];
export const billingDocumentoOnFile = [
  {
    name: "NumberDocument",
    initial: "",
    validate: Yup.number().required("Campo requerido"),
    type: "text",
    label: "Número de documento",
  },
];
export const taxesToPay = [
  {
    name: "Amount",
    initial: "",
    validate: Yup.number().required("Campo requerido"),
    type: "text",
    label: "Monto",
  },
];
export const documentReception = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre",
  },
];
export const dateAndTime = [
  {
    name: "date",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "date",
    label: "Fecha y hora",
  },
];
export const inputGetFeedback = [
  {
    name: "Value",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "checkbox",
    type: "checkbox",
    label: "¿Qué tan satisfecho se encuentra actualmente con nuestro servicio?",
    options: [
      {
        value: "1",
        key: "🙁",
      },
      {
        value: "2",
        key: "😕",
      },
      {
        value: "3",
        key: "😑",
      },
      {
        value: "4",
        key: "🙂",
      },
      {
        value: "5",
        key: "😄",
      },
    ],
  },
  {
    name: "Text",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "textarea",
    control: "textarea",
    label: "¿Tiene alguna sugerencia que desea compartimos?",
  },
];

export const inputAddNotify = [
  {
    name: "Tittle",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Titulo de la notificación",
  },
  {
    name: "Content",
    initial: "",
    validate: null,
    type: "textarea",
    control: "textarea",
    label: "Contenido",
  },
];

export const inputAddLoan = [
  {
    name: "Reason",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Motivo del préstamo",
  },
  {
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido."),
    label: "Seleccione el usuario solicitante",
    name: "RequestingUserId",
    url: "/rol/folder_loan",
    keyOption: "name",
    value: "id",
  },
  {
    name: "TentativeDate",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "date",
    label: "Fecha tentativa de entrega",
  },
  {
    name: "Observation",
    initial: "",
    validate: null,
    type: "textarea",
    control: "textarea",
    label: "Observaciones",
  },
];
export const inputReturnLoan = [
  {
    name: "ReturnObservation",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "textarea",
    control: "textarea",
    label: "Observaciones de la devolución",
  },
];

export const inputContravention = [
  {
    name: "interCode",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Código de interno de regularización",
  },
  {
    name: "DimDexCode",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nro de DIM o DEX de regularización",
  },
];
export const inputSendEmail = [
  {
    name: "Day",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Día de envió",
    options: [
      { key: "Elige el día:", value: "" },
      { value: "1", key: "Lunes" },
      { value: "2", key: "Martes" },
      { value: "3", key: "Miércoles" },
      { value: "4", key: "Jueves" },
      { value: "5", key: "Viernes" },
      { value: "6", key: "Sábado" },
      { value: "7", key: "Domingo" },
    ],
  },
  {
    name: "Hour",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Hora",
    options: [
      { key: "Elige la hora:", value: "" },
      { key: "00", value: "00" },
      { key: "01", value: "01" },
      { key: "02", value: "02" },
      { key: "03", value: "03" },
      { key: "04", value: "04" },
      { key: "05", value: "05" },
      { key: "06", value: "06" },
      { key: "07", value: "07" },
      { key: "08", value: "08" },
      { key: "09", value: "09" },
      { key: "10", value: "10" },
      { key: "11", value: "11" },
      { key: "12", value: "12" },
      { key: "13", value: "13" },
      { key: "14", value: "14" },
      { key: "15", value: "15" },
      { key: "16", value: "16" },
      { key: "17", value: "17" },
      { key: "18", value: "18" },
      { key: "19", value: "19" },
      { key: "20", value: "20" },
      { key: "21", value: "21" },
      { key: "22", value: "22" },
      { key: "23", value: "23" },
    ],
  },
];
export const inputReportHistory = [
  {
    name: "Date",
    initial: new Date(),
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "datePicker",
    label: "Gestión",
    dateFormat: "yyyy",
    showYearPicker: true,
  },
  {
    control: "getSelect",
    initial: "",
    validate: null,
    label: "Seleccione la compañía",
    name: "IdCompany",
    url: "/company/getallcompanies",
    keyOption: "razonSocial",
    value: "id",
  },
  {
    name: "Type",
    initial: "",
    validate: null,
    control: "select",
    label: "Tipo de tramites",
    options: [
      { key: "Todos los tramites", value: "" },
      { key: "Importación", value: "importacion" },
      { key: "Exportación", value: "exportacion" },
    ],
  },
  {
    name: "SheetTime",
    initial: "true",
    validate: null,
    type: "checkbox",
    label: "Informe de tiempos",
  },
  {
    name: "SheetData",
    initial: "true",
    validate: null,
    type: "checkbox",
    label: "Informe de datos",
  },
];

import { useState } from 'react'
import { BoxFlex } from '../../components/boxes'
import { TableFilters } from '../../components/table'
import { headerField } from '../../utils/TableFields'
import { validateStatus } from '../../utils/Validate'
import { GlobalButton } from '../../components/buttons'
import { putAction } from '../../services/actions/AuthAction'
const ManageFields = (props) => {
  const { openSnackbar } = props
  const [menu, setmenu] = useState({
    view: '1',
  })
  async function handleDeleteField(values, reload) {
    await putAction(`/field/deletefield/${values.id}`, {}).then((res) => {
      if (validateStatus(res.status)) {
        openSnackbar('Se guardo exitosamente', true, true)
        reload()
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }
  async function handleRecoverField(values, reload) {
    await putAction(`/field/recoverfield/${values.id}`, {}).then((res) => {
      if (validateStatus(res.status)) {
        openSnackbar('Se guardo exitosamente', true, true)
        reload()
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }
  return (
    <div>
      <BoxFlex>
        <GlobalButton
          onClick={() => setmenu({ view: '1' })}
          background={menu.view === '1' ? '#e2e4f3' : 'transparent'}
          color="#052691"
        >
          Listado de campos
        </GlobalButton>
        <GlobalButton
          onClick={() => setmenu({ view: '2' })}
          background={menu.view === '2' ? '#e2e4f3' : 'transparent'}
          color="#052691"
        >
          Listado de campos eliminados
        </GlobalButton>
      </BoxFlex>
      <br />
      {menu.view === '1' ? (
        <TableFilters
          params="/field"
          functionClick={() => {}}
          header={[
            ...headerField,
            {
              key: handleDeleteField,
              name: 'Acciones',
              textBtn: 'Eliminar',
              type: 'action',
            },
          ]}
        />
      ) : (
        <div className="">
          <TableFilters
            params="/field/deletedfields"
            functionClick={() => {}}
            header={[
              ...headerField,
              {
                key: handleRecoverField,
                name: 'Acciones',
                textBtn: 'Recuperar',
                type: 'action',
              },
            ]}
          />
        </div>
      )}
    </div>
  )
}

export default ManageFields

import "./Style/Sidebar.css";
import React, { useState, useEffect } from "react";
import { TextH2 } from "../../components/Text";
import { BoxFlex } from "../../components/boxes";
import Loading from "../../components/animation/Loading";
import { useAuthAxios } from "../../hook";
import { validateArray, validateRol } from "../../utils/Validate";
import { rolConstant } from "../../utils/Constant";
import { NavLink, withRouter } from "react-router-dom";
// import { HubConnectionBuilder } from '@microsoft/signalr'
// import { fetchURL } from '../../config.json'
// import { postAction } from '../../services/actions/AuthAction'

const menuRegister = [
  {
    id: 1,
    name: "Registró",
    path: "register",
    submenu: [
      {
        id: 1,
        name: "Listar usuarios",
      },
      {
        id: 2,
        name: "Listar compañías",
      },
    ],
  },
];

const Sidebar = (props) => {
  const {
    sesionUser: { rols, companys },
    view,
    setview,
    maxWidth,
    history,
    procedureSocket,
    setProcedureSocket,
  } = props;
  function handleOnClick() {
    if (maxWidth) {
      setview(false);
    }
  }

  return (
    <>
      <div
        style={{ display: view && maxWidth ? "block" : "none" }}
        onClick={handleOnClick}
        className="Sidebar__back"
      ></div>
      <div style={{ display: view ? "block" : "none" }} className="Sidebar">
        <div className="Sidebar__nav">
          {validateRol(rols, ["client"]) ? (
            <CustomerFlow
              listCompany={companys}
              history={history}
              status={false}
              handleOnClick={handleOnClick}
            />
          ) : null}
          {rols.map((menu, index) => {
            switch (menu.name) {
              case "quality":
                return (
                  <MenuRegister
                    menu={menu}
                    key={menu.id}
                    history={history}
                    status={index === 0}
                    menuList={menuRegister}
                    handleOnClick={handleOnClick}
                  />
                );
              case "userView":
                return (
                  <React.Fragment key={menu.id}>
                    <MenuDocuments
                      history={history}
                      status={index === 0}
                      title="Ver procedimientos"
                      menu={{
                        id: "0",
                        name: "view-dataset",
                        path: "view-dataset",
                      }}
                      handleOnClick={handleOnClick}
                    />
                  </React.Fragment>
                );
              case "admin":
                return (
                  <React.Fragment key={menu.id}>
                    <MenuDocuments
                      history={history}
                      status={index === 0}
                      title="Editar procedimientos"
                      menu={{
                        id: "0",
                        name: "edit-dataset",
                        path: "edit-dataset",
                      }}
                      handleOnClick={handleOnClick}
                    />
                    <MenuDocuments
                      history={history}
                      status={false}
                      title="Procedimientos terminados"
                      menu={{
                        id: "0",
                        name: "closed-procedures",
                        path: "closed-procedures",
                      }}
                      handleOnClick={handleOnClick}
                    />
                    <MenuDocuments
                      history={history}
                      status={false}
                      title="Historial de procedimientos"
                      menu={{
                        id: "0",
                        name: "procedure-history",
                        path: "procedure-history",
                      }}
                      handleOnClick={handleOnClick}
                    />
                    <MenuRegister
                      nameMenu="id"
                      menuList={[
                        {
                          id: 1,
                          name: "Listado de préstamos (General)",
                          path: "procedures-loan/all",
                        },
                      ]}
                      history={history}
                      status={false}
                      handleOnClick={handleOnClick}
                    />
                  </React.Fragment>
                );
              case "Systems":
                return (
                  <ManageFlows
                    history={history}
                    status={index === 0}
                    key={menu.id}
                    menu={menu}
                    handleOnClick={handleOnClick}
                  />
                );
              case "client":
                return null;
              case "management":
                return (
                  <MenuRegister
                    nameMenu="id"
                    key={menu.id}
                    menuList={[
                      {
                        id: 1,
                        name: "Gerencia",
                        path: "management",
                      },
                    ]}
                    status={index === 0}
                    history={history}
                    handleOnClick={handleOnClick}
                  />
                );
              case "completeDocuments":
                return (
                  <MenuDocuments
                    history={history}
                    key={menu.id}
                    status={index === 0}
                    title="Completar documentos"
                    menu={{ id: "0", name: "documents", path: "documents" }}
                    handleOnClick={handleOnClick}
                  />
                );
              case "folder_loan":
                return (
                  <MenuRegister
                    nameMenu="id"
                    menuList={[
                      {
                        id: 1,
                        name: "Mi lista de prestamos",
                        path: "procedures-loan/request",
                      },
                    ]}
                    key={menu.id}
                    history={history}
                    status={index === 0}
                    handleOnClick={handleOnClick}
                  />
                );
              case "contravention_stopped":
                return (
                  <MenuRegister
                    nameMenu="id"
                    menuList={[
                      {
                        id: 1,
                        name: "Trámites en abandono Importación",
                        path: "processes-stopped/all",
                      },
                      {
                        id: 2,
                        name: "Regularización de exportación",
                        path: "procedures-contravention/all",
                      },
                      {
                        id: 3,
                        name: "Regularización de anticipados",
                        path: "procedures-regularize",
                      },
                      {
                        id: 4,
                        name: "Usuarios habilitados",
                        path: "enabled-users",
                      },
                    ]}
                    key={menu.id}
                    history={history}
                    status={index === 0}
                    handleOnClick={handleOnClick}
                  />
                );
              case "notifications":
                return (
                  <MenuRegister
                    nameMenu="id"
                    menuList={[
                      {
                        id: 1,
                        name: "Notificaciones enviadas",
                        path: "notifications-sent",
                      },
                      {
                        id: 2,
                        name: "Respuesta de las encuestas",
                        path: "survey-response",
                      },
                      {
                        id: 3,
                        name: "Historial de la encuesta",
                        path: "survey-history",
                      },
                    ]}
                    key={menu.id}
                    history={history}
                    status={index === 0}
                    handleOnClick={handleOnClick}
                  />
                );
              case "download-history":
                return (
                  <MenuRegister
                    nameMenu="id"
                    menuList={[
                      {
                        id: 1,
                        name: "Historial de descarga",
                        path: "download-history",
                      },
                    ]}
                    key={menu.id}
                    history={history}
                    status={index === 0}
                    handleOnClick={handleOnClick}
                  />
                );
              default:
                return (
                  <MenuProcedureType
                    key={menu.id}
                    menu={menu}
                    history={history}
                    status={index === 0}
                    handleOnClick={handleOnClick}
                    procedureSocket={procedureSocket}
                    setProcedureSocket={setProcedureSocket}
                  />
                );
            }
          })}
          {validateRol(rols, ["liquidator"]) && (
            <MenuRegister
              nameMenu="id"
              menuList={[
                {
                  id: 1,
                  name: "Trámites en abandono (Importación)",
                  path: "processes-stopped/only",
                },
                {
                  id: 2,
                  name: "Regularización de exportaciones",
                  path: "procedures-contravention/only",
                },
              ]}
              history={history}
              handleOnClick={handleOnClick}
            />
          )}
          {validateRol(rols, ["files"]) && (
            <MenuRegister
              history={history}
              menuList={[
                {
                  id: 1,
                  name: "Préstamo de carpetas",
                  path: "loan",
                  submenu: [
                    {
                      id: 1,
                      name: "Trámites terminados",
                    },
                    {
                      id: 2,
                      name: "Carpetas prestadas",
                    },
                  ],
                },
              ]}
              handleOnClick={handleOnClick}
            />
          )}
          {validateRol(rols, [
            "reception",
            "senasag_procedure",
            "liquidator",
            "revision_procedure",
            "accounting",
            "customs_manager",
            "billing",
            "files",
          ]) && (
            <MenuRegister
              nameMenu="id"
              menuList={[
                {
                  id: 1,
                  name: "Historial",
                  path: "my-process",
                },
              ]}
              history={history}
              handleOnClick={handleOnClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

const CustomerFlow = ({ handleOnClick, history, status, listCompany }) => {
  const [company, setcompany] = useState(0);
  function handleInput(e) {
    const { value } = e.target;
    setcompany(value);
    history.push(`/client-resume/${listCompany[value].id}`);
  }

  return (
    <>
      {validateArray(listCompany) ? (
        <>
          {listCompany.length > 1 && (
            <>
              <div className="CustomerFlow__header">
                <select
                  className="Search__select"
                  style={{ width: "100%" }}
                  onChange={handleInput}
                  name="Company"
                >
                  {listCompany.map((option, index) => {
                    return (
                      <option key={index} value={index}>
                        {option.razonSocial}
                      </option>
                    );
                  })}
                </select>
                <img
                  style={{
                    width: "100%",
                    border: "1px solid var(--gray)",
                    borderRadius: "10px",
                    marginTop: "5px",
                  }}
                  src={listCompany[company].logoUrl}
                  alt="logotipo"
                />
              </div>
              <br />
            </>
          )}
          <MenuRegister
            nameMenu="id"
            menuList={[
              {
                id: 1,
                name: "Resumen",
                path: `client-resume/${listCompany[company].id}`,
              },
              {
                id: 2,
                name: "Mis trámites",
                path: `list-procedure/${listCompany[company].id}`,
              },
              {
                id: 3,
                name: "Trámites observados",
                path: `procedures-observed/${listCompany[company].id}`,
              },
            ]}
            status={status}
            history={history}
            handleOnClick={handleOnClick}
          />
        </>
      ) : null}
    </>
  );
};

const ManageFlows = ({ handleOnClick, history, status }) => {
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: "/proceduretype",
  });

  if (loading) {
    return <Loading />;
  } else {
    return (
      <MenuRegister
        menuList={[
          {
            id: 1,
            name: "Gestionar formularios",
            path: "manage-forms",
            submenu: response || [],
          },
        ]}
        status={status}
        history={history}
        handleOnClick={handleOnClick}
      />
    );
  }
};

const MenuDocuments = (props) => {
  const { menu, status, title, handleOnClick, history } = props;
  const [open, setopen] = useState(status);
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: "proceduretype",
  });
  if (loading) {
    return <Loading />;
  } else {
    return validateArray(response) ? (
      <div className="Sidebar__item">
        <NavLink
          to={`/${menu.name}`}
          className="Sidebar__link"
          activeClassName="Sidebar__linkAc"
          onClick={() => setopen(!open)}
        >
          <TextH2 fontWeight="600" margin="0 5px" color="var(--blue)">
            {title}
          </TextH2>
          <i className={`fas fa-chevron-${open ? "up" : "down"}`}></i>
        </NavLink>
        {open && (
          <div className="Sidebar__subMenu">
            {validateArray(response) &&
              response.map((procedureType, index) => (
                <SubItem
                  key={index}
                  menu={menu}
                  history={history}
                  subItem={procedureType}
                  open={open && index === 0}
                  handleOnClick={handleOnClick}
                />
              ))}
          </div>
        )}
      </div>
    ) : null;
  }
};

const MenuProcedureType = (props) => {
  const { menu, status, handleOnClick } = props;
  const [open, setopen] = useState(status);

  const response = [
    {
      id: 2,
      name: "Importación",
    },
    {
      id: 1,
      name: "Exportación",
    },
  ];
  // (2, 3)import - (1)export
  return validateArray(response) ? (
    <div className="Sidebar__item">
      <NavLink
        to={`/${menu.name}`}
        className="Sidebar__link"
        activeClassName="Sidebar__linkAc"
        onClick={() => setopen(!open)}
      >
        <TextH2 fontWeight="600" margin="0 5px" color="var(--blue)">
          {rolConstant[menu.name] || menu.name}
        </TextH2>
        <i className={`fas fa-chevron-${open ? "up" : "down"}`}></i>
      </NavLink>
      {open && (
        <div className="Sidebar__subMenu">
          {validateArray(response) &&
            response.map((procedureType, index) => (
              <MenuItem
                {...props}
                menu={{ ...menu, path: menu.name }}
                procedureType={procedureType}
                key={procedureType.id}
                status={index === 0 && status}
                handleOnClick={handleOnClick}
              />
            ))}
        </div>
      )}
    </div>
  ) : null;
};

const MenuItem = (props) => {
  const {
    menu,
    status,
    procedureType,
    handleOnClick,
    history,
    procedureSocket,
    setProcedureSocket,
  } = props;
  const [open, setopen] = useState(status);
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/processstep/GetProcessStepsByRolIdProcedureTypeId/${menu.id}/ProcedureTypeId/${procedureType.id}`,
  });
  if (loading) {
    return <Loading />;
  } else {
    return validateArray(response) ? (
      <div className="Sidebar__item">
        <TextH2
          onClick={() => setopen(!open)}
          fontWeight={open ? "600" : "500"}
          margin="5px 10px"
          color="var(--blue)"
        >
          <BoxFlex justifyContent="space-between">
            {procedureType.name}
            <i className={`fas fa-chevron-${open ? "up" : "down"}`}></i>
          </BoxFlex>
        </TextH2>
        {open && (
          <div className="Sidebar__subMenu">
            {validateArray(response) &&
              response
                .sort((a, b) => a.step - b.step)
                .map((subItem, index) => (
                  <SubItem
                    procedureSocket={procedureSocket}
                    setProcedureSocket={setProcedureSocket}
                    history={history}
                    open={status && index === 0}
                    menu={{ ...menu, path: menu.name }}
                    subItem={{ ...subItem }}
                    key={subItem.id}
                    tid={procedureType.id}
                    handleOnClick={handleOnClick}
                    reload={reload}
                  />
                ))}
          </div>
        )}
      </div>
    ) : null;
  }
};

const SubItem = (props) => {
  const {
    menu,
    subItem,
    tid,
    nameMenu,
    handleOnClick,
    history = () => {},
    open = false,
    procedureSocket = null,
    setProcedureSocket,
    reload,
  } = props;

  useEffect(() => {
    if (history.location.pathname === "/") {
      if (open) {
        history.push(
          tid
            ? `/type/${tid}/process/${subItem.step}/${subItem.name}`
            : `/${menu.path}/${subItem.id}`
        );
      }
    }
  }, []);
  useEffect(() => {
    if (
      procedureSocket &&
      procedureSocket.reload &&
      procedureSocket.step === subItem.step &&
      (procedureSocket.procedureType === tid ||
        (procedureSocket.procedureType === 3 && tid === 2))
    ) {
      reload();
      setProcedureSocket((s) => ({
        ...s,
        reload: false,
      }));
    }
  }, [procedureSocket]);

  return (
    <NavLink
      to={
        tid
          ? `/type/${tid}/process/${subItem.step}/${subItem.name}`
          : `/${menu.path}/${subItem.id}`
      }
      onClick={handleOnClick}
      className="Sidebar__subItem"
      activeClassName="Sidebar__subLinkAc"
    >
      <TextH2 margin="0 5px" color="" fontWeight="600">
        {nameMenu ? subItem[nameMenu] : subItem.name}{" "}
        {subItem.pending ? (
          <span className="ContentPending">{subItem.pending}</span>
        ) : null}
      </TextH2>
    </NavLink>
  );
};

// const ContentPending = (props) => {
//   const { pending, reload, tid, subItem } = props
//   useEffect(() => {
//     const connection = new HubConnectionBuilder()
//       .withUrl(`${fetchURL}/notificationHub`)
//       .withAutomaticReconnect()
//       .build()
//     const connect = connection.connection
//     connection
//       .start()
//       .then(() => {
//         connection.on('ListTypeProcedure', (step, procedureTypeId) => {
//           // (2, 3)import - (1)export
//           if (
//             step === subItem.step &&
//             (procedureTypeId === tid || (procedureTypeId === 3 && tid === 2))
//           ) {
//             reload()
//           }
//         })
//         postAction(
//           `/auth/SocketConnection/${connect.connectionId}`,
//         ).then(() => {})
//       })
//       .catch((e) => console.log('Connection failed: ', e))
//   }, [])
//   if (pending === 0) {
//     return null
//   } else {
//     return <span className="ContentPending">{pending}</span>
//   }
// }

const MenuRegister = ({
  menuList,
  nameMenu,
  handleOnClick,
  history,
  status,
}) => {
  const [open, setopen] = useState(status);
  function handleSetOpen(menu) {
    setopen(!open);
    if (!validateArray(menu.submenu)) {
      handleOnClick();
    }
  }

  useEffect(() => {
    if (history.location.pathname === "/") {
      if (
        status &&
        validateArray(menuList) &&
        !validateArray(menuList[0].submenu)
      ) {
        history.push(`/${menuList[0].path}`);
      }
    }
  }, []);

  return menuList.map((menu) => (
    <React.Fragment key={menu.id}>
      <NavLink
        to={`/${menu.path}`}
        className="Sidebar__link"
        activeClassName="Sidebar__linkAc"
        onClick={() => handleSetOpen(menu)}
      >
        <TextH2 fontWeight="600" margin="0 5px" color="var(--blue)">
          {menu.name}
        </TextH2>
        {validateArray(menu.submenu) && (
          <i className={`fas fa-chevron-${open ? "up" : "down"}`}></i>
        )}
      </NavLink>
      {open && (
        <div className="Sidebar__subMenu">
          {validateArray(menu.submenu) &&
            menu.submenu.map((subItem, index) => (
              <SubItem
                open={open && index === 0}
                menu={menu}
                key={subItem.id}
                history={history}
                subItem={subItem}
                nameMenu={nameMenu}
                handleOnClick={handleOnClick}
              />
            ))}
        </div>
      )}
    </React.Fragment>
  ));
};
export default withRouter(Sidebar);

import { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function CheckboxDescription(props) {
  const { label, name, upload, initial, ...rest } = props
  const [option, setoption] = useState(
    initial ? (initial === 'true' ? true : false) : '',
  )
  function handleOption(e) {
    const { name: nameOption } = e.target
    upload((a) => ({
      ...a,
      [name]: nameOption === 'RadioCheckTrue' ? true : '',
    }))
    setoption(nameOption === 'RadioCheckTrue' ? true : false)
  }
  return (
    <div
      style={{
        border: '1px solid #b1b1b1',
        padding: '10px 0 0 10px',
        borderRadius: '10px',
        marginBottom: '5px',
        width: '96%',
      }}
      className="Textarea"
    >
      <label className="Textarea__label" htmlFor="cambiando">
        {label}
      </label>
      <div className="RadioButtons">
        <div className="RadioButtons__itemContainer">
          <div className="RadioButtons__item">
            <input
              type="radio"
              name="RadioCheckTrue"
              id="RadioCheckTrue"
              onChange={handleOption}
              checked={option === true}
            />
            <label htmlFor="RadioCheckTrue">Si</label>
          </div>
          <div className="RadioButtons__item">
            <input
              type="radio"
              name="RadioCheckFalse"
              id="RadioCheckFalse"
              onChange={handleOption}
              checked={option === false}
            />
            <label htmlFor="RadioCheckFalse">No</label>
          </div>
        </div>
      </div>
      {option === false && (
        <>
          <label className="Textarea__label" htmlFor="cambiando">
            Descripción
          </label>
          <Field
            as="textarea"
            className="Textarea__input"
            id={name}
            name={name}
            {...rest}
          />
        </>
      )}
      <ErrorMessage component={TextError} name={name} />
      <br />
      <br />
    </div>
  )
}

export default CheckboxDescription

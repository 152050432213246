import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { validateStatus } from '../../utils/Validate'
import { postAction } from '../../services/actions/AuthAction'
const urlPostAction = {
  'rol/liquidator': '/dataset/AsignLiquiador',
  'rol/Regimen': '/dataset/asignregimen',
  'rol/Color': '/dataset/asignchannel',
}
const FormPutDef = (props) => {
  const [load, setload] = useState(false)
  const {
    onCancel,
    reload,
    procedure,
    openSnackbar,
    url,
    title,
    inputsJson,
  } = props
  async function onSubmit(values) {
    setload(true)
    try {
      await postAction(urlPostAction[url], [
        {
          Value: values.user,
          ProcedureId: procedure.id,
          // FieldId: fieldId,
        },
      ]).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          onCancel()
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  return (
    <FormDefault
      load={load}
      title={title}
      textBtn="Guardar"
      InputsJson={inputsJson}
      data={{}}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default FormPutDef

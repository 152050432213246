import { TextH2, TextH3 } from '../../components/Text'
import { TableFilters } from '../../components/table'
import { FormRegularization } from '../../containers/proceduresContravention'
import { changeModal, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'

const ProceduresContravention = (props) => {
  const {
    match: { params },
    changeModal,
  } = props

  function handleClick(procedure, reload) {
    changeModal(
      true,
      <FormRegularization
        {...props}
        procedure={{ ...procedure, id: procedure.idProcedure }}
        reload={reload}
      />,
      false,
      '500px',
    )
  }

  function handleDay(item, name) {
    const days = (item.ampliacion ? 180 * 2 : 180) - parseInt(item[name])
    return (
      <TextH3
        background={
          parseInt(days) > 30
            ? 'var(--green)'
            : parseInt(days) > 20
            ? 'var(--green)'
            : 'var(--red)'
        }
        margin="0"
        padding="2px 10px 5px"
        borderRadius="15px"
        width="fit-content"
        color="var(--white)"
      >
        {days} / {item.ampliacion ? 180 * 2 : 180}
      </TextH3>
    )
  }

  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Regularización de exportación
      </TextH2>
      <br />
      <TableFilters
        params="/procedure/extendedProcedure"
        functionClick={handleClick}
        addUrl={
          params.type === 'only'
            ? `userId=${window.localStorage.getItem('id')}`
            : ''
        }
        header={[
          { key: 'internCode', name: 'Número de interno' },
          { key: 'company', name: 'Compañía', type: 'text' },
          { key: 'billNumber', name: 'Número de factura' },
          { key: 'orderNumber', name: 'Número de pedido' },
          { key: 'dimDexNumber', name: 'Número de DEX' },
          {
            key: 'assignedLiquidator',
            name: 'Liquidador asignado',
            type: 'text',
          },
          {
            key: 'chanelDate',
            name: 'Fecha de la asignación del canal',
            type: 'text',
          },
          {
            key: 'elapsedDays',
            name: 'Días para regularizar',
            type: 'validate',
            validate: handleDay,
          },
          {
            key: 'chanelDateAdd',
            name: 'Fecha de vencimiento',
            type: 'text',
          },
          {
            key: 'ampliacion',
            name: 'Estado',
            type: 'textBoolean',
            translate: {
              true: 'Con ampliación',
              false: 'Sin ampliación',
            },
            color: {},
          },
        ]}
        filterBoxes={[
          {
            type: 'select',
            label: 'Sin regularizar',
            name: 'Regularizacion',
            options: [{ key: 'Regularizados', value: 'true' }],
          },
          {
            type: 'select',
            label: 'Por ampliación',
            name: 'Ampliacion',
            options: [
              { key: 'Con ampliación', value: 'true' },
              { key: 'Sin ampliación', value: 'false' },
            ],
          },
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </>
  )
}

export default connect(null, { changeModal, openSnackbar })(
  ProceduresContravention,
)

import GlobalButton from './GlobalButton'
import './style/ActionButton.css'
const ActionButton = ({
  onClick,
  onCancel,
  textBtn,
  textCancel = 'Cancelar',
  type = 'button',
  disabled = false,
  alertBoolean = false,
}) => {
  return (
    <div className="ActionButton">
      <GlobalButton background="var(--white)" onClick={onCancel} type="button">
        {textCancel}
      </GlobalButton>
      <GlobalButton
        onClick={onClick}
        type={type}
        disabled={disabled}
        alertBoolean={alertBoolean}
      >
        {textBtn}
      </GlobalButton>
    </div>
  )
}

export default ActionButton

import { validateArray } from '../../utils/Validate'
import { convertDate2 } from '../../utils/ConvertDate'
import { useState, useEffect } from 'react'
import { RangeDate } from '../../components/inputs'
import { TextH3 } from '../../components/Text'
import { BoxFlex } from '../../components/boxes'
import { useAuthAxios } from '../../hook'
import './FilterBoxes.css'
import { Loading } from '../animation'
const FilterBoxes = (props) => {
  const { filterBoxes, setfilters, seturl } = props
  const [inp, setinp] = useState(handleInital(filterBoxes))
  function handleInital(list) {
    let aux = {}
    if (validateArray(list)) {
      list.forEach((item) => {
        if (item.initial) {
          aux = { ...aux, [item.name]: item.initial }
        }
      })
    }
    return aux
  }
  useEffect(() => {
    function generateJson(JsonData) {
      let aux = ''
      if (JsonData) {
        for (let key in JsonData) {
          if (JsonData[key]) {
            aux = `${aux}&${
              key === 'StartDate'
                ? 'initial'
                : key === 'EndDate'
                ? 'final'
                : key
            }=${
              convertDate2(
                JsonData[key],
                'YYYY-MM-DD',
                key === 'EndDate' ? 'T23:59:59' : 'T00:00:00',
              ) || JsonData[key]
            }`
          }
        }
      }
      return aux
    }
    setfilters(generateJson(inp))
    seturl((a) => ({ ...a, PageNumber: 1 }))
  }, [inp])

  return (
    <div
      className="FilterBoxes"
      style={{ display: 'flex', gap: '10px', marginTop: '5px' }}
    >
      {validateArray(filterBoxes) && (
        <BoxFlex flexWrap="wrap">
          <TextH3 margin="0">Filtrar por:</TextH3>
          {filterBoxes.map((filter, index) => (
            <Filter key={index} filter={filter} setinp={setinp} inp={inp} />
          ))}
        </BoxFlex>
      )}
    </div>
  )
}

const Filter = (props) => {
  const { filter, setinp, inp } = props
  function handleInput(e) {
    const { name, value } = e.target
    setinp((a) => ({
      ...a,
      [name]: value,
    }))
  }
  function handleDateRange(update) {
    setinp((a) => ({
      ...a,
      StartDate: update[0],
      EndDate: update[1],
    }))
  }
  switch (filter.type) {
    case 'text':
      return (
        <div className="Filter__input">
          <label htmlFor="">{filter.label}</label>
          <br />
          <input
            placeholder={filter.label}
            name={filter.name}
            onChange={handleInput}
            type="text"
          />
        </div>
      )
    case 'select':
      return validateArray(filter.options) ? (
        <select
          className="Search__select"
          onChange={handleInput}
          name={filter.name}
          value={inp[filter.name]}
          style={{
            backgroundColor: inp[filter.name] ? '#a6aef0' : '#e2e4f3',
          }}
        >
          <option key="0" value="">
            {filter.label}
          </option>
          {filter.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.key}
              </option>
            )
          })}
        </select>
      ) : null
    case 'rangeDate':
      return (
        <RangeDate
          filter={filter}
          dateRange={[
            inp.StartDate ? inp.StartDate : null,
            inp.EndDate ? inp.EndDate : null,
          ]}
          setDateRange={handleDateRange}
        />
      )
    case 'getSelect':
      return <FilterGetSelect {...props} />

    default:
      return null
  }
}

const FilterGetSelect = (props) => {
  const { filter, setinp, inp } = props
  const { response, loading } = useAuthAxios({
    method: 'GET',
    url: filter.url,
  })
  function onChange(e) {
    const { name, value } = e.target
    setinp((a) => ({
      ...a,
      [name]: value,
    }))
  }
  if (loading) {
    return <Loading />
  } else {
    return validateArray(response) ? (
      <select
        className="Search__select"
        onChange={onChange}
        name={filter.name}
        style={{
          backgroundColor: inp[filter.name] ? '#a6aef0' : '#e2e4f3',
        }}
        value={inp[filter.name]}
      >
        <option key={0} value="">
          {filter.label}
        </option>

        {response.map((option, index) => {
          return (
            <option
              key={index + 1}
              value={option.key || option[filter.urlName]}
            >
              {option.label || option[filter.urlLabel]}
            </option>
          )
        })}
      </select>
    ) : null
  }
}
export default FilterBoxes

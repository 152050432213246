import './Home.css'
// import { TextH2 } from '../../components/Text'
const Home = () => {
  return (
    <div className="Home">
      {/* <TextH2 fontSize="2rem" color="var(--blue)">
        Usuarios
      </TextH2> */}
    </div>
  )
}

export default Home

import { TableFilters } from '../../components/table'
import { GenerateRoute } from '../../containers/procedure'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'
import { TextH2 } from '../../components/Text'

const MyProcedure = (props) => {
  const { changeModal } = props

  function handleClick(procedure) {
    changeModal(
      true,
      <GenerateRoute
        {...props}
        procedure={{ ...procedure, id: procedure.procedureId }}
        editBoolean={false}
      />,
      true,
      '900px',
    )
  }

  return (
    <div>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Historial de mis trámites completados
      </TextH2>
      <br />
      <TableFilters
        params={`/procedure/user`}
        functionClick={handleClick}
        header={[
          {
            key: 'internCode',
            name: 'Número de interno',
            type: 'textAction',
            action: handleClick,
          },
          { key: 'billNumber', name: 'Número de factura' },
          { key: 'orderNumber', name: 'Número de pedido' },
          { key: 'dimNumber', name: 'Número de DIM o DEX' },
          { key: 'companyName', name: 'Compañía', type: 'text' },
          { key: 'assignedLiquidator', name: 'Liquidador' },
          { key: 'assignedGestor', name: 'Gestor' },
          { key: 'stepName', name: 'Paso actual', type: 'text' },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'companyId',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
        ]}
      />
    </div>
  )
}

export default connect(null, { changeModal })(MyProcedure)

import "./Style/Style.css";
import "./Style/Header.css";
import { useState, useRef, useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Link } from "react-router-dom";
import { TextH3, TextH2 } from "../../components/Text";
import { BoxFlex, BoxFixed } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { GlobalButton } from "../../components/buttons";
import { ModalConfirmation } from "../../components/modal";
import { validateArray, validateStatus } from "../../utils/Validate";
import { urlAvatar } from "../../utils/Constant";
import { dateCompare, diffDate } from "../../utils/ConvertDate";
import { putAction, postAction } from "../../services/actions/AuthAction";
import { useAuthAxios } from "../../hook";
import fetchURL from "../../config.json";
import { addDownloadHistory } from "../../services/actions/AuthAction";

const Header = (props) => {
  const {
    closeSession,
    history,
    view,
    setview,
    sesionUser,
    changeModal,
    openSnackbar,
    loading,
    response,
    rolClient,
    reload,
  } = props;
  const [bars, setbars] = useState(false);
  const [menuUser, setmenuUser] = useState(false);
  function handleClose() {
    closeSession();
    localStorage.removeItem("token_seguridad");
    localStorage.removeItem("id");
    history.push("/auth/login");
    setmenuUser(!menuUser);
  }
  return (
    <>
      <div className="Header">
        <div
          onMouseEnter={() => setbars(true)}
          onMouseLeave={() => setbars(false)}
          className="Header__navItem Header__icon"
        >
          {bars ? (
            <i
              onClick={() => setview(!view)}
              className={`fas fa-angle-double-${view ? "left" : "right"}`}
            ></i>
          ) : (
            <i onClick={() => setview(!view)} className="fas fa-bars"></i>
          )}
        </div>
        <Link className="Header__navItem" to="/">
          <img
            alt="logo"
            src="https://res.cloudinary.com/sistemavilaseca/image/upload/h_80,q_auto/v1663777128/Assets/logotipo-png_qgoq3w.png"
          />
        </Link>
        {sesionUser.authenticate && (
          <BoxFlex gap="25px">
            <NotificationContain
              openSnackbar={openSnackbar}
              changeModal={changeModal}
              history={history}
            />
            <UserMenu
              user={sesionUser.user}
              handleClose={handleClose}
              menuUser={menuUser}
              setmenuUser={setmenuUser}
            />
          </BoxFlex>
        )}
      </div>
      {!loading && !response && rolClient ? (
        <SurveyLink reload={reload} locationUser={sesionUser.location} />
      ) : null}
    </>
  );
};

const SurveyLink = ({ reload, locationUser }) => {
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: "/users/preferenceUrl",
  });
  async function submitPreference() {
    await putAction(`/users/preferencesForPoll/update`, {
      value: true,
    }).then((res) => {
      if (validateStatus(res.status)) {
        reload();
      }
    });
    await addDownloadHistory(
      "Enlace de encuesta",
      response.url,
      window.localStorage.getItem("id"),
      "poll",
      locationUser
    );
  }
  if (!loading && response !== undefined) {
    return (
      <div className="Header__link">
        <a
          href={response.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "rgb(255, 119, 29)" }}
          onClick={submitPreference}
        >
          <TextH3
            margin="0"
            textAlign="center"
            color="rgb(255, 119, 29)"
            padding="10px 0"
            fontSize="1.8rem"
          >
            Ayúdanos a mejorar nuestro servicio respondiendo la siguiente
            encuesta
          </TextH3>
        </a>
      </div>
    );
  } else {
    return null;
  }
};

const NotificationContain = (props) => {
  const [filters, setfilters] = useState({
    page: 1,
    filter: "",
  });
  const { response, loading, reload, headers } = useAuthAxios({
    method: "GET",
    url: `/Notification?PageNumber=${filters.page}${
      filters.filter ? `&type=${filters.filter}` : ""
    }`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [filters]);

  return (
    <HeaderNotify
      {...props}
      response={response}
      loading={loading}
      reload={reload}
      headers={headers}
      filters={filters}
      setfilters={setfilters}
    />
  );
};

const HeaderNotify = (props) => {
  const {
    response = [],
    changeModal,
    openSnackbar,
    loading,
    history,
    reload,
    headers,
    filters,
    setfilters,
  } = props;
  const [modal, setmodal] = useState(false);
  const [notifications, setNotifications] = useState(response);

  const latestNotify = useRef(null);
  latestNotify.current = notifications;

  useEffect(() => {
    setNotifications(response);
  }, [response]);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${fetchURL}/notificationHub`)
      .withAutomaticReconnect()
      .build();
    const connect = connection.connection;
    connection
      .start()
      .then(() => {
        connection.on("ReceiveNotification", () => {
          reload();
          setfilters({ page: 1, filter: "" });
        });
        postAction(`/auth/SocketConnection/${connect.connectionId}`).then(
          () => {}
        );
      })
      .catch((e) => console.log("Connection failed: ", e));
  }, []);

  const NotifySize = notifications.filter(
    (notification) =>
      notification.publicationDate &&
      dateCompare(
        notification.publicationDate,
        {
          parteRegulation: 15,
          parteRegulationAnticipada: 10,
          temporaryExport: 30,
          Loans: 15,
        }[notification.type]
      )
  );

  const FilterText = ({ name, label }) => {
    return (
      <TextH3
        padding="2px 8px 2px"
        borderRadius="20px"
        width="fit-content"
        background="var(--blue)"
        color="var(--white)"
        margin="0"
        cursor="pointer"
        onClick={() => setfilters({ page: 1, filter: name })}
        opacity={filters.filter === name ? "1" : "0.3"}
      >
        {label}
      </TextH3>
    );
  };
  const NotifyFooter = ({ pagination }) => {
    return (
      <BoxFlex gap="10px" justifyContent="flex-start" margin="5px 0 0">
        <TextH3 margin="0" fontSize="1.4rem">
          Pagina {pagination.currentPage} de{" "}
          {pagination.totalPages ? pagination.totalPages : 1}
        </TextH3>
        <div className=""></div>
        <GlobalButton
          width="fit-content"
          padding="0 10px"
          onClick={() => setfilters((a) => ({ ...a, page: a.page - 1 }))}
          disabled={filters.page <= 1 ? true : false}
        >
          <i className="fas fa-chevron-left"></i>
        </GlobalButton>
        <GlobalButton
          width="fit-content"
          padding="0 10px"
          onClick={() => setfilters((a) => ({ ...a, page: a.page + 1 }))}
          disabled={pagination.totalPages <= filters.page ? true : false}
        >
          <i className="fas fa-chevron-right"></i>
        </GlobalButton>
      </BoxFlex>
    );
  };

  function handleRemoveAll() {
    changeModal(
      true,
      <ModalConfirmation
        url={`/files/`}
        textBtn="Eliminar"
        textSuccess="Se elimino el archivo"
        openSnackbar={openSnackbar}
        onCancel={() => changeModal(false, null)}
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres eliminar todas las notificaciones de tu
          bandeja de entrada?
        </TextH2>
      </ModalConfirmation>,
      false
    );
  }

  return (
    <div className="HeaderNotify">
      <div className="HeaderNotify__icon" onClick={() => setmodal(!modal)}>
        <TextH2 color="var(--gray)" fontSize="2.2rem" margin="0">
          <i className="fas fa-bell"></i>
        </TextH2>
        {validateArray(NotifySize) &&
          (headers.pagination && JSON.parse(headers.pagination) ? (
            <TextH3 fontSize="1rem" color="var(--white)" margin="0">
              {JSON.parse(headers.pagination).totalItems}
            </TextH3>
          ) : (
            <div className=""></div>
          ))}
      </div>
      {modal && (
        <>
          <BoxFixed onClose={() => setmodal(!modal)} />
          <div className="HeaderNotify__main">
            <BoxFlex flexWrap="wrap" justifyContent="space-between">
              <TextH2
                color="var(--gray)"
                fontWeight="600"
                fontSize="2rem"
                margin="0"
              >
                Notificaciones
              </TextH2>
              {/* <GlobalButton
                width="fit-content"
                padding="0 8px"
                background="#c93f3f1a"
                color="var(--red)"
                onClick={handleRemoveAll}
              >
                <i className="fas fa-trash"></i>
              </GlobalButton> */}
            </BoxFlex>
            <BoxFlex flexWrap="wrap" marginTop="5px">
              <FilterText label="Todos" name="" />
              <FilterText label="Abandono" name="parteRegulation" />
              <FilterText label="Reg. exp" name="temporaryExport" />
              <FilterText
                label="Reg. anticipados"
                name="parteRegulationAnticipada"
              />
              <FilterText label="Préstamos" name="Loans" />
            </BoxFlex>
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="HeaderNotify__list">
                  {validateArray(notifications) ? (
                    notifications.map((item) => {
                      if (
                        item.publicationDate &&
                        dateCompare(
                          item.publicationDate,
                          {
                            parteRegulation: 15,
                            parteRegulationAnticipada: 10,
                            temporaryExport: 30,
                            Loans: 15,
                          }[item.type]
                        )
                      ) {
                        return (
                          <Notify
                            item={item}
                            key={item.id}
                            modal={modal}
                            reload={reload}
                            history={history}
                            setmodal={setmodal}
                            changeModal={changeModal}
                          />
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    // .reverse()
                    <TextH3 color="var(--gray)" margin="0">
                      No tienes notificaciones
                    </TextH3>
                  )}
                  <NotifyFooter
                    pagination={
                      headers.pagination ? JSON.parse(headers.pagination) : null
                    }
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const Notify = (props) => {
  const { item, changeModal, reload, history, modal, setmodal } = props;
  const { response, loading } = useAuthAxios({
    method: "GET",
    url: `/notification/description?notificationId=${item.id}`,
  });
  function handleViewNotify(notify) {
    handlePutView(notify);
    changeModal(
      true,
      <>
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          {notify.tittle}
        </TextH2>
        <TextH3 color="var(--gray)" margin="0">
          {notify.content}
        </TextH3>
      </>,
      true,
      "400px"
    );
  }

  async function handlePutView(notify) {
    await putAction(`/notification/${notify.id}`, {}).then((res) => {
      if (validateStatus(res.status)) {
        reload();
      }
    });
  }
  function handleNewProcedure(item) {
    handlePutView(item);
    history.push(`/type/${item.procedureTypeId}/process/${item.step}`);
    setmodal(!modal);
  }

  function AnchorValidate(props) {
    const { anchor = false, children, href = "#", onClick, style } = props;

    if (anchor) {
      return (
        <div style={{ display: "flex" }}>
          <a
            onClick={onClick}
            style={{ ...style, textDecoration: "none" }}
            href={href}
          >
            {children}
          </a>
        </div>
      );
    } else {
      return (
        <div onClick={onClick} style={style}>
          {children}
        </div>
      );
    }
  }

  if (loading || response === undefined) {
    return null;
  } else {
    return (
      <AnchorValidate
        anchor={
          item.type === "Loans" ||
          item.type === "temporaryExport" ||
          item.type === "parteRegulation" ||
          item.type === "parteRegulationAnticipada"
        }
        href={
          {
            temporaryExport: "/procedures-contravention",
            Loans: "/procedures-loan/request",
            parteRegulation: "/processes-stopped/only",
            parteRegulationAnticipada: "/processes-stopped/only",
          }[item.type]
        }
        style={{
          background:
            item.type === "temporaryExport" || item.type === "Loans"
              ? "rgba(201, 63, 63, 0.1)"
              : item.isView
              ? "var(--white)"
              : "rgb(226, 228, 243)",
          borderRadius: "5px",
          margin: "5px 0",
          padding: "4px 5px 5px",
          cursor: "pointer",
        }}
        onClick={
          {
            Notification: () => handleViewNotify(item),
            NewProcess: () => handleNewProcedure(item),
            temporaryExport: () => setmodal(!modal),
            Loans: () => setmodal(!modal),
          }[item.type]
        }
      >
        <TextH3
          color={
            item.type === "temporaryExport" ||
            item.type === "Loans" ||
            item.type === "parteRegulation" ||
            item.type === "parteRegulationAnticipada"
              ? "var(--red)"
              : "var(--blue)"
          }
          margin="0"
        >
          {
            {
              Notification: `Notificación: ${item.tittle}`,
              NewProcess: `${response.userName} te asignó el interno ${response.internCode}`,
              temporaryExport: `${
                response.internCode ? response.internCode + "," : ""
              } te quedan ${diffDate(
                item.publicationDate
              )} días para regularizar el trámite`,
              parteRegulation: `${
                response.internCode ? response.internCode + "," : ""
              } te quedan ${diffDate(
                item.publicationDate
              )} días para regularizar el trámite`,
              parteRegulationAnticipada: `${
                response.internCode ? response.internCode + "," : ""
              } te quedan ${diffDate(
                item.publicationDate
              )} días para regularizar el trámite`,
              Loans: `${
                response.internCode ? response.internCode + "," : ""
              } te quedan ${diffDate(
                item.publicationDate
              )} días para la fecha limite del préstamo`,
            }[item.type]
          }
        </TextH3>
      </AnchorValidate>
    );
  }
};

const UserMenu = ({ user, handleClose, menuUser, setmenuUser }) => {
  return (
    <div className="UserMenu">
      <div className="UserMenu__contain" onClick={() => setmenuUser(!menuUser)}>
        <TextH3 color="var(--gray)" margin="5px 5px 8px">
          {user.name} {user.fatherLastName} {user.motherLastName}
        </TextH3>
        <div
          style={{
            width: "26px",
            height: "26px",
            border: "1px solid var(--gray)",
            borderRadius: "50%",
            marginRight: "2px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url("${
              user.photoUrl ? user.photoUrl : urlAvatar
            }")`,
          }}
        ></div>
      </div>
      {menuUser && (
        <>
          <div className="UserMenu__card">
            <Link
              style={{ textDecoration: "none" }}
              onClick={() => setmenuUser(!menuUser)}
              to="/account"
            >
              <TextH3
                color="var(--gray)"
                fontSize="1.8rem"
                margin="5px 5px 8px"
              >
                <span>Mi perfil</span> <i className="fas fa-user-cog"></i>
              </TextH3>
            </Link>
            <TextH3
              onClick={handleClose}
              fontSize="1.8rem"
              color="var(--gray)"
              margin="5px 5px 8px"
            >
              <span style={{ minWidth: "fit-content" }}>Cerrar sesión</span>{" "}
              <i className="fas fa-door-open"></i>
            </TextH3>
          </div>
          <BoxFixed onClose={() => setmenuUser(!menuUser)} />
        </>
      )}
    </div>
  );
};
export default Header;

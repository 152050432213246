import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { validateStatus } from '../../utils/Validate'
import { inputCompany } from '../../utils/FormFields'
import { putAction } from '../../services/actions/AuthAction'
const FormCompany = (props) => {
  const [load, setload] = useState(false)
  const { reload, procedure, openSnackbar, title, changeModal, tid } = props
  async function onSubmit(values) {
    setload(true)
    try {
      await putAction('/procedure', {
        Id: procedure.id,
        CompanyId: values.CompanyId,
        ProcedureTypeId: tid,
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          changeModal(false, null)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }

  return (
    <FormDefault
      load={load}
      title={title}
      textBtn="Guardar"
      InputsJson={inputCompany}
      data={{}}
      onSubmit={onSubmit}
      onCancel={() => changeModal(false, null)}
    />
  )
}

export default FormCompany

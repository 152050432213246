import HttpClient from '../HttpClient'
export const getAction = (url) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}
export const postAction = (url, body = {}) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}
export const putAction = (url, body = {}) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}
export const deleteAction = (url, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.delete(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}

export const addDownloadHistory = async (document, url, id, type, location) => {
  try {
    await postAction('/files/DownloadHistory', {
      NameDocument: document,
      UrlRoute: url,
      referenceId: id,
      type: type,
      Location: location,
    })
  } catch (e) {
    console.log(e)
  }
}

import { useEffect } from "react";
import { useAuthAxios } from "../../hook";
import { TextH3 } from "../../components/Text";
import { CardBox } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { GraphicsContainer } from "../../components/graphics";
import { Link } from "react-router-dom";

const ProceduresObserved = ({ cid, year, history }) => {
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/clientStats/companies/${cid}/perObservation?internCode=${year}`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [year]);

  const TextLabel = ({ title, children, to }) => {
    return (
      <Link style={{ textDecoration: "none" }} to={to}>
        <TextH3 fontWeight="500" textAlign="start" color="var(--white)">
          {title}
          <br />
          <span style={{ fontSize: "2.2rem" }}>{children}</span>
        </TextH3>
      </Link>
    );
  };

  return (
    <CardBox
      headerComponent={
        !loading && (
          <>
            <TextLabel
              to={`/procedures-observed/${cid}`}
              title="Total de Trámites"
            >
              {response.total}
            </TextLabel>
            <TextLabel to={`/procedures-observed/${cid}`} title="Observados">
              {response.observed}
            </TextLabel>
            <TextLabel
              to={`/procedures-observed/${cid}`}
              title="Sin observación"
            >
              {response.notObserved}
            </TextLabel>
          </>
        )
      }
      component={
        loading ? (
          <Loading />
        ) : (
          <>
            <TextH3
              textTransform="uppercase"
              fontWeight="700"
              textAlign="center"
              color="#465ffd"
            >
              Trámites observados
            </TextH3>
            <GraphicsContainer
              size="small"
              type="doughnut"
              onClick={() => history.push(`/procedures-observed/${cid}`)}
              data={[
                {
                  label: "Observación",
                  color: "#F4E206",
                  data: response.observed,
                  percentage: Math.ceil(
                    (100 * response.observed) / response.total
                  ),
                },
                {
                  label: "Sin observación",
                  color: "#7889FD",
                  data: response.notObserved,
                  percentage: Math.ceil(
                    (100 * response.notObserved) / response.total
                  ),
                },
              ]}
            />
          </>
        )
      }
    />
  );
};

export default ProceduresObserved;

import { useState } from 'react'
import FileUpload from './FileUpload'
import FormUpdateUser from './FormUpdateUser'
import FormUploadImg from './FormUploadImg'
import ListNotification from './ListNotification'
import { useAuthAxios } from '../../hook'
import { validateArray, validateStatus } from '../../utils/Validate'
import { rolConstant } from '../../utils/Constant'
import { GlobalButton, ActionButton } from '../../components/buttons'
import Loading from '../../components/animation/Loading'
import { BoxFlex, ToolTip } from '../../components/boxes'
import { getAction } from '../../services/actions/AuthAction'
import { TextH2, TextH3 } from '../../components/Text'
import { RenderModal, ModalConfirmation } from '../../components/modal'
import {
  putAction,
  addDownloadHistory,
} from '../../services/actions/AuthAction'

const UserDetail = (props) => {
  const {
    openSnackbar,
    user,
    changeModal,
    handleReload,
    sesionUser: { location },
  } = props
  const [modal, setmodal] = useState(false)
  const [load, setload] = useState(false)
  const { response, loading, reload } = useAuthAxios({
    method: 'GET',
    url: `/users/${user.id}/GetUserId`,
  })
  function handleModal() {
    setmodal(!modal)
  }
  function handleDetailPdf() {
    setload(true)
    getAction(`/users/${user.id}/UserDateInPDF`)
      .then((response) => {
        setload(false)
        if (validateStatus(response.status)) {
          window.open(response.data, '_blank')
        }
      })
      .catch((e) => console.log(e))
  }
  function handleUploadImg() {
    changeModal(
      true,
      <FormUploadImg
        {...props}
        url={`/users/${user.id}/UserPhoto`}
        name={user.username}
        title="Cambiar foto de perfil"
        onCancel={() => changeModal(true, <UserDetail {...props} />)}
      />,
      false,
    )
  }
  function handleUpdateUser() {
    changeModal(
      true,
      <FormUpdateUser
        {...props}
        reload={handleReload}
        user={response.userToReturn}
        rols={response.rolsUserToReturn}
        onCancel={() => changeModal(true, <UserDetail {...props} />, true)}
      />,
      false,
    )
  }
  function handleNotification() {
    changeModal(
      true,
      <ListNotification
        {...props}
        title={`Notificaciones de ${response.userToReturn.name} ${response.userToReturn.fatherLastName} ${response.userToReturn.motherLastName}`}
        titleForm={`Enviar notificación a ${response.userToReturn.name} ${response.userToReturn.fatherLastName} ${response.userToReturn.motherLastName}`}
        users={[{ ...response.userToReturn }]}
        onCancel={() => changeModal(true, <UserDetail {...props} />, true)}
      />,
      false,
    )
  }

  function handleEnable() {
    changeModal(
      true,
      <>
        <TextH2
          color="var(--blue)"
          fontSize="2rem"
          fontWeight="500"
          textAlign="center"
        >
          Estas seguro que quieres{' '}
          {response?.userToReturn?.status === 'Activo'
            ? 'deshabilitar'
            : 'habilitar'}{' '}
          al usuario:
        </TextH2>
        <TextH3
          textAlign="center"
          fontSize="1.8rem"
          // fontWeight="600"
          color="var(--gray)"
        >
          {response.userToReturn.name} {response.userToReturn.fatherLastName}{' '}
          {response.userToReturn.motherLastName}
        </TextH3>
        <ActionButton
          onClick={handleEnableUser}
          onCancel={() => changeModal(true, <UserDetail {...props} />, true)}
          textBtn={
            response?.userToReturn?.status === 'Activo'
              ? 'Deshabilitar'
              : 'Habilitar'
          }
        />
      </>,
    )
  }
  async function handleEnableUser() {
    try {
      setload(true)
      await putAction(`/users/Activate/${response.userToReturn.id}`, {}).then(
        (res) => {
          setload(false)
          if (validateStatus(res.status)) {
            openSnackbar('Se guardo exitosamente', true, true)
            handleReload()
            changeModal(true, <UserDetail {...props} />, true)
          } else {
            openSnackbar('No se pudo guardar', true, false)
          }
        },
      )
    } catch (e) {
      setload(false)
    }
  }

  function handleDeleteImg(fileId) {
    changeModal(
      true,
      <ModalConfirmation
        url={`/files/${fileId}`}
        textBtn="Eliminar"
        textSuccess="Se elimino el archivo"
        openSnackbar={openSnackbar}
        onCancel={() => changeModal(true, <UserDetail {...props} />, true)}
      >
        <TextH2 textAlign="center" fontSize="2rem" color="var(--blue)">
          ¿Estas seguro que quieres eliminar este archivo?
        </TextH2>
      </ModalConfirmation>,
      false,
    )
  }
  const TextData = ({ children, title }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #cccccc',
        borderRadius: '5px',
        margin: '5px 0',
        padding: '5px 5px 10px',
      }}
    >
      <TextH2 margin="0">{title}:</TextH2>
      <TextH3 margin="0 auto" justifySelf="center" color="var(--gray)">
        {children}
      </TextH3>
    </div>
  )

  return (
    <>
      <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
        <TextH2 fontSize="2rem" fontWeight="600" textAlign="center">
          Detalles del usuario
        </TextH2>
        {load ? (
          <Loading />
        ) : (
          <BoxFlex justifyContent="flex-end">
            <ToolTip content="Exportar en PDF">
              <div>
                <GlobalButton
                  fontSize="2rem"
                  onClick={handleDetailPdf}
                  width="fit-content"
                >
                  <i className="fas fa-file-pdf"></i>
                </GlobalButton>
              </div>
            </ToolTip>
            <ToolTip content="Enviar notificación">
              <div>
                <GlobalButton
                  fontSize="2rem"
                  onClick={handleNotification}
                  width="fit-content"
                >
                  <i className="fas fa-bullhorn"></i>
                </GlobalButton>
              </div>
            </ToolTip>
            <GlobalButton
              background="#052691"
              color="#e2e4f3"
              onClick={handleUpdateUser}
              width="fit-content"
            >
              Editar usuario y roles
            </GlobalButton>
          </BoxFlex>
        )}
      </BoxFlex>
      <br />
      {loading ? null : (
        <>
          <TextData title="Foto de perfil">
            <img
              style={{
                height: '60px',
                border: '1px solid var(--gray)',
                borderRadius: '10px',
              }}
              src={
                response.userToReturn.photoUrl
                  ? response.userToReturn.photoUrl
                  : 'https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614259401/user_h6o64n.jpg'
              }
              alt="avatar"
            />
          </TextData>
          <TextData title="Nombres">{response.userToReturn.name}</TextData>
          <TextData title="Apellidos">
            {response.userToReturn.fatherLastName}{' '}
            {response.userToReturn.motherLastName}
          </TextData>
          <TextData title="Correo">{response.userToReturn.email}</TextData>
          <TextData title="Nro. de celular">
            {response.userToReturn.cellphoneNumber}
          </TextData>
          {response.userToReturn.phoneNumber && (
            <TextData title="Nro. de telefono">
              {response.userToReturn.phoneNumber}
            </TextData>
          )}
          <TextData title="Ciudad">{response.userToReturn.city}</TextData>
          <TextData title="Estado">{response.userToReturn.status}</TextData>
          <BoxFlex justifyContent="space-between">
            <TextH2 fontSize="1.8rem" fontWeight="600" textAlign="center">
              Roles del usuario
            </TextH2>
            <GlobalButton onClick={handleEnable} width="fit-content">
              {response.userToReturn.status === 'Activo'
                ? 'Deshabilitar usuario'
                : 'Habilitar usuario'}
            </GlobalButton>
          </BoxFlex>
          <BoxFlex marginTop="5px" flexWrap="wrap">
            {validateArray(response.rolsUserToReturn) ? (
              response.rolsUserToReturn.map((rol) => (
                <TextH3
                  background="#ebebeb8b"
                  padding="5px"
                  borderRadius="5px"
                  margin="0"
                  color="var(--blue)"
                  key={rol.id}
                >
                  {rolConstant[rol.name] || rol.name}
                </TextH3>
              ))
            ) : (
              <TextH3>El usuario no tiene roles</TextH3>
            )}
          </BoxFlex>
          <br />
          <BoxFlex flexWrap="wrap-reverse" justifyContent="space-between">
            <TextH2 fontSize="1.8rem" fontWeight="600" textAlign="center">
              Archivos del usuario
            </TextH2>
            <div>
              <GlobalButton
                margin="0 5px 0"
                onClick={handleUploadImg}
                width="fit-content"
              >
                Cambiar foto de perfil
              </GlobalButton>
              <GlobalButton
                background="#052691"
                color="#e2e4f3"
                onClick={handleModal}
                width="fit-content"
              >
                Subir archivo
              </GlobalButton>
            </div>
          </BoxFlex>
          <br />
          <BoxFlex flexWrap="wrap" gap="10px 20px">
            {validateArray(response.fileListToReturn) ? (
              response.fileListToReturn.map((item) => (
                <BoxFlex key={item.id}>
                  <TextH3 color="var(--blue)" margin="0 0 5px">
                    <a
                      onClick={() =>
                        addDownloadHistory(
                          item.name,
                          item.url,
                          user.id,
                          'user',
                          location,
                        )
                      }
                      style={{ textDecoration: 'none' }}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  </TextH3>
                  <GlobalButton
                    height="20px"
                    lineHeight="10px"
                    fontSize="1.4rem"
                    onClick={() => handleDeleteImg(item.fileId)}
                    width="fit-content"
                  >
                    <i className="fas fa-times"></i>
                  </GlobalButton>
                </BoxFlex>
              ))
            ) : (
              <TextH3>No se subio archivos</TextH3>
            )}
          </BoxFlex>
        </>
      )}
      {modal && (
        <RenderModal onClose={handleModal} width="500px" closeOutside>
          <FileUpload
            handleModal={handleModal}
            modal={modal}
            openSnackbar={openSnackbar}
            reload={reload}
            id={user.id}
            type="UserId"
          />
        </RenderModal>
      )}
    </>
  )
}

export default UserDetail

import { TextH3 } from "../Text";
import { GlobalButton } from "../buttons";
import { BoxFlex, ToolTip } from "../boxes";
import { convertDate } from "../../utils/ConvertDate";
import { validateArray } from "../../utils/Validate";
const DataType = ({ a, head, reload, handleAddHistory, filters }) => {
  switch (a.type) {
    case "action":
      return (
        <GlobalButton
          fontSize="1.4rem"
          fontWeight="500"
          background="#e2e4f3"
          color="var(--blue)"
          onClick={() => a.key(head, reload)}
          width="auto"
          border="1px solid var(--blue)"
        >
          {a.textBtn}
        </GlobalButton>
      );
    case "anchor":
      const text = head[a.key] + "";
      if (text.includes("https")) {
        return (
          <TextH3 margin="0" color="var(--blue)">
            {head[a.key] ? (
              <a
                onClick={() => handleAddHistory(head.label, head[a.key])}
                href={head[a.key]}
                target="_blank"
                rel="noopener noreferrer"
              >
                Enlace del documento
              </a>
            ) : (
              "--"
            )}
          </TextH3>
        );
      } else {
        return <TextH3 margin="0">{head[a.key] ? head[a.key] : "--"}</TextH3>;
      }
    case "textAction":
      return (
        <TextH3
          cursor="pointer"
          margin="0"
          fontWeight="600"
          color="var(--blue)"
          onClick={() => a.action(head, reload)}
        >
          {head[a.key] ? head[a.key] : "--"}
        </TextH3>
      );
    case "textTranslated":
      return (
        <TextH3 margin="0">
          {a.translate[head[a.key]] || head[a.key] + ""}
          {/* {head[a.key] ? a.translate[head[a.key]] || head[a.key] : '--'} */}
        </TextH3>
      );
    case "textBoolean":
      if (
        head[a.key] === true ||
        head[a.key] === false ||
        head[a.key] === "true" ||
        head[a.key] === "false"
      ) {
        return (
          <TextH3
            background={a.color[head[a.key]] || "var(--gray)"}
            margin="0"
            padding="2px 10px 5px"
            borderRadius="15px"
            width="fit-content"
            color="var(--white)"
          >
            {a.translate[head[a.key]] || head[a.key]}
          </TextH3>
        );
      } else {
        return (
          <TextH3 margin="0">{a.textDefault ? a.textDefault : "--"}</TextH3>
        );
      }

    case "validate":
      return <TextH3 margin="0">{a.validate(head, a.key, filters)}</TextH3>;
    case "date":
      return (
        <TextH3 margin="0">
          {head[a.key] && head[a.key] !== "0001-01-01T00:00:00"
            ? convertDate(head[a.key], "LLLL")
            : "--"}
        </TextH3>
      );
    case "dateShort":
      return (
        <TextH3 margin="0">
          {head[a.key] && head[a.key] !== "0001-01-01T00:00:00"
            ? convertDate(head[a.key], "lll")
            : "--"}
        </TextH3>
      );
    case "listActions":
      return (
        <BoxFlex flexWrap="wrap">
          {validateArray(a.listBtn) &&
            a.listBtn.map((item, index) =>
              item ? (
                <ToolTip key={index} content={item.label}>
                  <div>
                    <GlobalButton
                      border="1px solid #0526911a"
                      onClick={() => item.action(head, reload)}
                    >
                      {item.children}
                    </GlobalButton>
                  </div>
                </ToolTip>
              ) : null
            )}
        </BoxFlex>
      );
    default:
      return (
        <TextH3 margin="0">
          {head[a.key] && head[a.key] !== "null" ? head[a.key] : "--"}
        </TextH3>
      );
  }
};

export default DataType;

import styled from "styled-components";
const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  .RenderModal__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    background-color: white;
    text-decoration: none;
    border: none;
    cursor: pointer;
    z-index: 2;
    color: var(--red);
    font-size: 1.6rem;
  }
  .RenderModal__container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .RenderModal__main {
    position: relative;
    z-index: 1;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    width: ${(props) => props.width};
    min-height: 50vh;
  }
  .RenderModal__background {
    background: ${(props) => props.background};
    height: ${(props) => props.height};
    padding: 10px 20px;
    border-radius: 10px;
  }
  .RenderModal__main::-webkit-scrollbar {
    width: 1rem;
  }
  .RenderModal__main::-webkit-scrollbar-track {
    margin: 10px 0;
  }
  .RenderModal__main::-webkit-scrollbar-thumb {
    background: var(--blue);
    border-radius: 25px;
  }
  .RenderModal__close {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 0;
  }
  @media (max-width: ${(props) => props.width}) {
    .RenderModal__main {
      width: calc(100% - 20px);
      max-height: calc(100vh - 10px);
    }
    .RenderModal__close-button {
      border: 1px solid var(--black);
    }
  }
`;
const RenderModal = (props) => {
  const {
    onClose,
    children,
    closeOutside = false,
    background = "var(--white)",
    width = "900px",
    height,
  } = props;

  return (
    <ModalStyle background={background} width={width} height={height}>
      <button className="RenderModal__close-button" onClick={onClose}>
        <i className="fas fa-times"></i>
      </button>
      <div className="RenderModal__container">
        {closeOutside && (
          <div className="RenderModal__close" onClick={onClose}></div>
        )}
        <div className="RenderModal__main">
          <div className="RenderModal__background">{children}</div>
        </div>
      </div>
    </ModalStyle>
  );
};

export default RenderModal;

import { TextH2 } from '../../components/Text'
import { TableFilters } from '../../components/table'

const DownloadHistory = () => {
  function handleReference(value, header) {
    if (value.type === 'procedure') {
      return value[header]
    } else if (value.type === 'poll') {
      return value['name']
    }
    return value['nameCompany']
  }
  return (
    <>
      <TextH2 fontSize="2rem" color="var(--blue)">
        Lista de historial de descargas
      </TextH2>
      <br />
      <TableFilters
        params="/files/DownloadHistoryList"
        paramsDownload="/files/DownloadHistory/Xlsx"
        functionClick={() => {}}
        addQuery={[
          { key: 'user', name: 'Usuario' },
          { key: 'internNumber', name: 'Número de interno' },
          { key: 'nameDocument', name: 'Nombre del documento' },
        ]}
        header={[
          {
            key: 'internNumber',
            name: 'Referencia',
            type: 'validate',
            validate: handleReference,
          },
          { key: 'name', name: 'Usuario', type: 'text' },
          { key: 'nameDocument', name: 'Nombre del documento', type: 'text' },
          { key: 'razonSocial', name: 'Compañía', type: 'text' },
          {
            key: 'type',
            name: 'Tipo',
            type: 'textTranslated',
            translate: {
              company: 'Compañía',
              user: 'Usuario',
              procedure: 'Procedimiento',
              poll: 'Enlace de encuesta',
            },
          },
          { key: 'creationDate', name: 'Fecha', type: 'dateShort' },
          { key: 'location', name: 'Ubicación', type: 'text' },
        ]}
        filterBoxes={[
          {
            type: 'getSelect',
            label: 'Todos los roles',
            name: 'rolId',
            url: '/rol',
            urlName: 'id',
            urlLabel: 'label',
          },
          {
            type: 'select',
            label: 'Todos los tipos',
            name: 'type',
            options: [
              { key: 'Procedimiento', value: 'procedure' },
              { key: 'Usuario', value: 'user' },
              { key: 'Compañía', value: 'company' },
              { key: 'Enlace de encuesta', value: 'poll' },
            ],
          },
          {
            type: 'select',
            label: 'Filtrar por regional de oficina',
            name: 'CityUser',
            options: [
              { key: 'La Paz', value: 'La Paz' },
              { key: 'Santa Cruz', value: 'Santa Cruz' },
              { key: 'Chuquisaca', value: 'Chuquisaca' },
              { key: 'Cochabamba', value: 'Cochabamba' },
              { key: 'Oruro', value: 'Oruro' },
              { key: 'Potosí', value: 'Potosí' },
              { key: 'Tarija', value: 'Tarija' },
              { key: 'Beni', value: 'Beni' },
              { key: 'Pando', value: 'Pando' },
            ],
          },
          {
            type: 'getSelect',
            label: 'Todas las compañías',
            name: 'IdCompany',
            url: '/company/getallcompanies',
            urlName: 'id',
            urlLabel: 'razonSocial',
          },
          {
            type: 'rangeDate',
            label: 'Rango de fecha',
            name: 'InitialDate',
          },
        ]}
      />
    </>
  )
}

export default DownloadHistory

import { useEffect } from "react";
import { TextH2, TextH3 } from "../../components/Text";
import { useAuthAxios } from "../../hook";
import { BoxFlex } from "../../components/boxes";
import { validateArray } from "../../utils/Validate";
import { Loading } from "../../components/animation";
import GraphicsFilters from "./GraphicsFilters";
import ProcedureInProcess from "./ProcedureInProcess";
import ProcedureClosedStep from "./ProcedureClosedStep";
import "./ManagementContainer.css";
const ManagementContainer = (props) => {
  const { handleDate, filters, changeModal, city } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: "/proceduretype",
  });

  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [filters]);

  return (
    <div className="ManagementContainer">
      <BoxFlex className="ManagementContainer__header">
        {loading ? (
          <Loading />
        ) : (
          validateArray(response) &&
          response.map((item, index) => (
            <ContetCardIndicators
              key={index}
              item={item}
              filters={filters}
              getFilters={handleDate}
              city={city}
            />
          ))
        )}
      </BoxFlex>
      <ProcedureInProcess
        filters={filters}
        handleDate={handleDate}
        changeModal={changeModal}
      />
      <br />
      <ProcedureClosedStep
        filters={filters}
        handleDate={handleDate}
        changeModal={changeModal}
      />
      <GraphicsFilters
        type="barColumn"
        url="procedureStats/perCustoms?"
        filters={filters}
        getFilters={handleDate}
        typeData="list"
        title="Trámites validados por aduanas"
        name="name"
        label="name"
        params={[
          {
            type: "getSelect",
            label: "Todas las compañías",
            name: "companyId",
            url: "/company/getallcompanies",
            urlName: "id",
            urlLabel: "razonSocial",
          },
          {
            type: "select",
            label: "Departamento",
            name: "Department",
            options: [
              { key: "La Paz", value: "La Paz" },
              { key: "Santa Cruz", value: "Santa Cruz" },
              { key: "Chuquisaca", value: "Chuquisaca" },
              { key: "Cochabamba", value: "Cochabamba" },
              { key: "Oruro", value: "Oruro" },
              { key: "Potosí", value: "Potosí" },
              { key: "Tarija", value: "Tarija" },
              { key: "Beni", value: "Beni" },
              { key: "Pando", value: "Pando" },
            ],
          },
        ]}
      />
      <GraphicsFilters
        type="barColumn"
        url="/procedureStats/perCompany?"
        filters={filters}
        getFilters={handleDate}
        typeData="list"
        title="Trámites por compañía validados"
        name="razonSocial"
        label="razonSocial"
      />
    </div>
  );
};

const ContetCardIndicators = (props) => {
  const { item, getFilters, filters, city } = props;
  const { response, loading, reload } = useAuthAxios({
    method: "GET",
    url: `/procedureStats/for-cards?procedureTypeId=${item.id}&${getFilters()}${
      city ? `&CityUser=${city}` : ""
    }`,
  });

  useEffect(() => {
    if (!loading) {
      if (filters !== undefined && response !== undefined && city !== "") {
        reload();
      }
    }
  }, [filters, city]);

  return (
    <div className="ContetCardIndicators">
      <CardIndicators
        title={loading ? <Loading /> : response.amount}
        subTitle={
          <>
            {item.name} <br /> Trámites aperturados
          </>
        }
      />
    </div>
  );
};

const CardIndicators = ({ title, subTitle }) => {
  return (
    <div className="CardIndicators">
      <TextH2 fontSize="2.5rem" color="var(--blue)">
        {title}
      </TextH2>
      <TextH3>{subTitle}</TextH3>
    </div>
  );
};

export default ManagementContainer;

const BoxFlex = (props) => {
  const {
    children,
    alignItems = 'center',
    justifyContent,
    gap = '5px',
    flexWrap = 'nowrap',
    ...rest
  } = props
  return (
    <div
      className="BoxFlex"
      style={{
        display: 'flex',
        alignItems: alignItems,
        justifyContent: justifyContent,
        gap: gap,
        flexWrap: flexWrap,
        ...rest,
      }}
    >
      {children}
    </div>
  )
}

export default BoxFlex

import { TextH3 } from '../Text'
import './CardBox.css'
const CardBox = ({
  title,
  vertical = false,
  component,
  headerComponent,
  width = '100%',
  justifyContent = 'auto',
}) => {
  return (
    <div className="CardBorder" style={{ width: width }}>
      <div className="CardBorder__label">
        <div
          style={{
            writingMode: vertical ? 'vertical-rl' : 'horizontal-tb',
            transform: vertical ? 'rotate(180deg)' : 'none',
            maxHeight: 'min-content',
            padding: '10px 20px',
            textAlign: 'center',
          }}
        >
          <TextH3 fontWeight="600" fontSize="2rem" color="var(--white)">
            {title}
          </TextH3>
          {headerComponent}
        </div>
      </div>
      <div
        className="CardBorder__main"
        style={{ justifyContent: justifyContent }}
      >
        {component}
      </div>
    </div>
  )
}
export default CardBox

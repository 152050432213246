import { useState } from 'react'
import { FormDefault } from '../../components/forms'
import { validateStatus } from '../../utils/Validate'
import {
  capitalizeJson,
  jsonToArray,
  arrayToJson,
} from '../../utils/ProcessData'
import { inputUpdateUser } from '../../utils/FormFields'
import { putAction } from '../../services/actions/AuthAction'
const FormUpdateUser = (props) => {
  const [load, setload] = useState(false)
  const { onCancel, reload, user, rols, openSnackbar } = props
  async function onSubmit(values) {
    setload(true)
    try {
      await putAction(`/users/${user.id}/UpdateUser`, {
        ...values,
        Rol: arrayToJson(values.Rol),
      }).then((res) => {
        setload(false)
        if (validateStatus(res.status)) {
          openSnackbar('Se guardo exitosamente', true, true)
          onCancel()
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(false)
      console.log(e)
    }
  }
  return (
    <FormDefault
      load={load}
      title="Editar usuario"
      textBtn="Guardar"
      InputsJson={inputUpdateUser}
      data={capitalizeJson({ ...user, Rol: jsonToArray(rols) })}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}

export default FormUpdateUser

import { validateStatus } from '../../utils/Validate'
import { postAction, putAction } from './AuthAction'

export async function readDim(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial && element.validate) {
    const resultConvertDIM = await putAction(
      `/files/convertPdfToCsv/${procedure.id}`,
      {
        name: element.name,
      },
    ).then(async (res) => {
      if (validateStatus(res.status)) {
        const resultDataDIM = await postAction(
          `/files/DataDim/${procedure.id}`,
          {
            name: element.name,
          },
        ).then(async (resData) => {
          if (validateStatus(resData.status)) {
            const resultReadH = await postAction(
              `/procedure/readH/${procedure.id}`,
            ).then((resH) => {
              return {
                readStatus: resH.status,
                readData: resH.data
                  ? resH.data
                  : `Error al leer los documentos "${element.name}"`,
                validate: element.validate,
              }
            })
            return resultReadH
          } else {
            return {
              readStatus: resData.status,
              readData: resData.data
                ? resData.data
                : `Error al leer "${element.name}"`,
              validate: element.validate,
            }
          }
        })
        return resultDataDIM
      } else {
        return {
          readStatus: res.status,
          readData: res.data ? res.data : `Error al leer "${element.name}"`,
          validate: element.validate,
        }
      }
    })
    resultFn = resultConvertDIM
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(fields, index + 1, procedure, [...result, resultFn], submit)
}

export async function adjuntarDex(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }
  if (element.initial && element.validate) {
    const resultConvertDIM = await putAction(
      `/files/convertPdfToCsv/${procedure.id}`,
      {
        name: element.name,
      },
    ).then(async (res) => {
      if (validateStatus(res.status)) {
        const resultDataDIM = await postAction(
          `/files/DataDim/${procedure.id}`,
          {
            name: element.name,
          },
        ).then(async (resData) => {
          return {
            readStatus: resData.status,
            readData: resData.data
              ? resData.data
              : `Error al leer "${element.name}"`,
            validate: element.validate,
          }
        })
        return resultDataDIM
      } else {
        return {
          readStatus: res.status,
          readData: res.data ? res.data : `Error al leer "${element.name}"`,
          validate: element.validate,
        }
      }
    })
    resultFn = resultConvertDIM
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(fields, index + 1, procedure, [...result, resultFn], submit)
}

export async function readDam(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }

  if (element.initial) {
    resultFn = await putAction(`/files/ReadDam/${procedure.id}`).then((res) => {
      return {
        readStatus: res.status,
        readData: res.data ? res.data : `Error al leer "${element.name}"`,
        validate: element.validate,
      }
    })
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(fields, index + 1, procedure, [...result, resultFn], submit)
}

export async function readParte(fields, index, procedure, result, submit) {
  const element = fields[index]
  let resultFn = { readStatus: 0, readData: '', validate: element.validate }

  if (element.initial) {
    const result = await putAction(`/files/readparte/${procedure.id}`).then(
      async (res) => {
        if (validateStatus(res.status)) {
          const resultNotify = await postAction(
            `/notification/parte/procedures/${procedure.id}`,
          ).then((result) => {
            return {
              readStatus: result.status,
              readData: result.data
                ? result.data
                : `Error al crear la notificación del "${element.name}"`,
              validate: element.validate,
            }
          })
          return resultNotify
        } else {
          return {
            readStatus: res.status,
            readData: res.data ? res.data : `Error al leer "${element.name}"`,
            validate: element.validate,
          }
        }
      },
    )
    resultFn = result
  } else if (element.validate) {
    resultFn = {
      readStatus: 500,
      readData: `El campo ${element.label} es requerida`,
      validate: element.validate,
    }
  }
  submit(fields, index + 1, procedure, [...result, resultFn], submit)
}

export default function Safeguard(user, validations) {
  let sw = { bool: true, route: '/' }
  for (let i = 0; i < validations.length; i++) {
    switch (validations[i]) {
      case 'isAuthenticated':
        sw = isAuthenticated(user)
        break
      default:
        sw = isAuthenticated(user)
        break
    }
    if (!sw) {
      i = validations.length
    }
  }
  return sw
}
function isAuthenticated(user) {
  return { bool: user ? user.authenticate : false, route: '/auth/login' }
}

import { validateArray } from "./Validate";
export function createOption(list, label, key, value = "id", init = true) {
  let aux = [];
  // let aux = init ? [{ key: label, value: "" }] : [];
  if (validateArray(list)) {
    list
      .sort((a, b) => (a[value] || a[key]) - (b[value] || b[key]))
      .forEach((item) => {
        aux.push({ label: item[key], key: item[key], value: item[value] + "" });
      });
  }
  return aux;
}
export function processDrag(list, order, id, content) {
  let aux = [];
  if (validateArray(list)) {
    list
      .sort((a, b) => a[order] - b[order])
      .forEach((element) => {
        aux.push({
          id: `${element[id]}`,
          content: element[content],
        });
      });
  }
  return aux;
}
export function capitalizeJson(JsonData) {
  let aux = {};
  if (JsonData) {
    for (let key in JsonData) {
      let data = JsonData[key];
      // if (isValidFormat(data)) {
      //   data = convertD(data, 'utc')
      // }
      aux = {
        ...aux,
        [key.charAt(0).toUpperCase() + key.slice(1)]: data,
      };
    }
  }
  return aux;
}

export function jsonToArray(list, key = "id") {
  let aux = [];
  list.forEach((item) => {
    aux.push(item[key] + "");
  });
  return aux;
}
export function arrayToJson(list) {
  let aux = [];
  list.forEach((item) => {
    aux.push({ Id: item + "" });
  });
  return aux;
}

export function stringToJson(str) {
  let init = { status: null, content: "" };
  let aux = null;
  try {
    aux = JSON.parse(str.replace(/'/g, '"'));
    init = aux;
  } catch (error) {
    if (str === "true") {
      init = { status: true, content: "" };
    } else {
      init = { status: false, content: str };
    }
  }
  if (typeof init === "boolean") {
    return { status: true, content: "" };
  } else {
    return init;
  }
}

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
